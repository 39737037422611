const menu = {
  cn_zh: {
    menuData: [
      {
        name: '主页',
        url: '/home'
      },
      {
        name: '主题',
        url: '/topics',
        notActiveList: ['MachineTools'],
        showSubmenu: false,
        children: [
          {
            name: '可持续发展',
            url: '/topics/Sustainability'
          },
          {
            name: '网络信息安全',
            url: '/topics/Cybersecurity'
          },
          {
            name: '效率',
            url: '/topics/Efficiency'
          },
          {
            name: '体验',
            url: '/topics/Experience'
          },
          {
            name: '灵活性',
            url: '/topics/Flexibility'
          },
          {
            name: '韧性',
            url: '/topics/Resilience'
          },
          {
            name: '供应商支持',
            url: '/topics/SupplierSupport'
          }
        ]
      },
      {
        name: '行业/市场',
        url: '/vertical-markets',
        activeList: ['MachineTools'],
        showSubmenu: false,
        children: [
          {
            name: '商业楼宇',
            url: '/vertical-markets/CommercialBuildings'
          },
          {
            name: '机床制造业',
            url: '/topics/MachineTools'
          },
          {
            name: '数据中心',
            url: '/vertical-markets/DataCenter'
          },
          {
            name: '高等教育',
            url: '/vertical-markets/HigherEducation'
          },
          {
            name: '食品与饮料',
            url: '/vertical-markets/FoodAndBeverage'
          },
          {
            name: '医疗',
            url: '/vertical-markets/Healthcare'
          },
          {
            name: '制药与生命科学',
            url: '/vertical-markets/PharmaceuticalLifeScienceIndustries'
          },
          {
            name: '电力',
            url: '/vertical-markets/PowerUtilities'
          },
          {
            name: '交通',
            url: '/vertical-markets/Transportation'
          },
          {
            name: '场内物流',
            url: '/vertical-markets/Intralogistics'
          }
        ]
      },
      {
        name: '社区',
        url: '/community'
      },
      {
        name: '合作伙伴',
        url: '/partners'
      },
      {
        name: '开发者',
        url: '/developers'
      },
      {
        name: '学习中心',
        url: '/study-center'
      },
      {
        name: '活动中心',
        url: '/event-center'
      },
      {
        name: '关于Xcelerator',
        url: '/business-platform'
      }
    ]
  },
  en_us: {
    menuData: [
      {
        name: 'Home',
        url: '/home'
      },
      {
        name: 'Topics',
        url: '/topics',
        showSubmenu: false,
        notActiveList: ['MachineTools'],
        children: [
          {
            name: 'Sustainability',
            url: '/topics/Sustainability'
          },
          {
            name: 'Cybersecurity',
            url: '/topics/Cybersecurity'
          },
          {
            name: 'Efficiency',
            url: '/topics/Efficiency'
          },
          {
            name: 'Experience',
            url: '/topics/Experience'
          },
          {
            name: 'Flexibility',
            url: '/topics/Flexibility'
          },
          {
            name: 'Resilience',
            url: '/topics/Resilience'
          },
          {
            name: 'Supplier Support',
            url: '/topics/SupplierSupport'
          }
        ]
      },

      {
        name: 'Vertical Markets',
        url: '/vertical-markets',
        activeList: ['MachineTools'],
        showSubmenu: false,
        children: [
          {
            name: 'Commercial Buildings',
            url: '/vertical-markets/CommercialBuildings'
          },
          {
            name: 'Machine Tools',
            url: '/topics/MachineTools'
          },
          {
            name: 'Data Center',
            url: '/vertical-markets/DataCenter'
          },
          {
            name: 'Higher Education',
            url: '/vertical-markets/HigherEducation'
          },
          {
            name: 'Food & Beverage',
            url: '/vertical-markets/FoodAndBeverage'
          },
          {
            name: 'Healthcare',
            url: '/vertical-markets/Healthcare'
          },
          {
            name: 'Pharmaceutical & Life Science Industries',
            url: '/vertical-markets/PharmaceuticalLifeScienceIndustries'
          },
          {
            name: 'Power Utilities',
            url: '/vertical-markets/PowerUtilities'
          },
          {
            name: 'Transportation',
            url: '/vertical-markets/Transportation'
          },
          {
            name: 'Intralogistics',
            url: '/vertical-markets/Intralogistics'
          }
        ]
      },
      {
        name: 'Community',
        url: '/community'
      },
      {
        name: 'Partners',
        url: '/partners'
      },
      {
        name: 'Developers',
        url: '/developers'
      },
      {
        name: 'Learning Center', // 目前没有翻译，需和客户确认
        url: '/study-center'
      },
      {
        name: 'Event Center',
        url: '/event-center'
      },
      {
        name: 'About Xcelerator',
        url: '/business-platform'
      }
    ]
  }
}

export default menu
