// index.ts
import { createI18n } from 'vue-i18n'
import zh from './lang/zh.js'
import en from './lang/en.js'
import { getLang } from '@/utils/lang.js'

const messages = {
  en,
  zh
}
// const language = (
//   (navigator.language ? navigator.language : navigator.userLanguage) || 'zh'
// ).toLowerCase()
const i18n = createI18n({
  globalInjection: true,
  warnHtmlMessage: false,
  legacy: false,
  // locale: localStorage.getItem('lang') || language.split('-')[0] || 'zh', // 首先从缓存里拿，没有的话就用浏览器语言，
  locale: getLang(), // 首先从缓存里拿，没有的话就用浏览器语言，
  fallbackLocale: 'zh', // 设置备用语言
  messages
})

export default i18n
