export default {
	title: "零碳先锋获奖企业案例",
	list: [
		{
			title: "赋能先锋卓越奖",
			data: [
				{
					id: 1,
					name: "让新材料生产智能化",
					desc: "硬件采用西门子S7系列PLC软件使用TIA及wincc及宝科自研的铜冶炼管理插件，提升工艺的同时，降低氧气、天然气的耗量",
					img: "/img/article/西安宝科.png",
					docUrl: "/article/西安宝科流体企业案例.pdf"
				},
				{

					id: 2,
					name: "全面引入西门子数字化双胞胎，打造全新研发体系",
					desc: "机、电、软、液等多学科一体化协同、数字孪生深化应用，供应商协同研发，数字化闭环制造等",
					img: "/img/article/能科科技.png",
					docUrl: "/article/能科科技企业案例.pdf"

				
				}
			]
		},
		{
			title: "运营先锋卓越奖",
			data: [
				{
					id: 3,
					name: "坚持“集团统筹、节约优先、双轮驱动、内外畅通、防范风险”，助力碳达峰碳中和",
					desc: "与西门子签署可持续发展方面合作备忘录，将基于SiGREEN进行产品碳排放相关数据的采集、建模、碳足迹的分析与计算。",
					img: "/img/article/欣旺达.png",
					docUrl: "/article/欣旺达企业案例.pdf"
				},
				{

						id: 4,
						name: "DHL集团是绿色物流的先行者，是全球首家提出至2050年实现物流相关零排放的物流企业",
						desc: "作为西门子中国物流供应链的长期合作伙伴，DHL快递中国区始终致力于加快推动绿色物流发展，并已取得多个里程碑成果，为助力西门子中国推动 “范围3”减排做出了贡献。",
						img: "/img/article/DHL.png",
						docUrl: "/article/运营先锋卓越奖-DHL.pdf"
				},
				{
					id: 5,
					name: "利用数字化技术提升能源应用效率和生产效率节能减排",
					desc: "数字化碳排放管理及产品碳足迹计算系统可以更快捷的提供产品碳足迹",
					img: "/img/article/深南电路.png",
					docUrl: "/article/深南电路企业案例.pdf"
				}
			]
		},
		
        {

			title: "赋能先锋优秀奖（入围卓越奖终选企业）",
			data: [
				{
					id: 6,
					name: "数字孪生虚拟调试，精准控制降低能耗",
					desc: "应用数字孪生技术，大力推进数字化，虚拟调试精准节能。",
					img: "/img/article/迪普自动化.png",
					docUrl: "/article/迪普自动化企业案例.pdf"
				},
				{
					id: 7,
					name: "西家产品、技术创新、促进节能减碳，促进行业的可持续性的发展",
					desc: "采用DCP双向直流变换器+磷酸铁锂电池系统储能修井机电控系统，提高资源利用率，减少碳排放；采用Sinamics S120驱动电机，直接驱动绞车机构，提高了传动效率",
					img: "/img/article/西威嘉业.jpg",
					docUrl: "/article/西威嘉业企业案例.pdf"

				},
				{
					id: 8,
					name: "专注能源服务、助力城市低碳",
					desc: "平台基于西门子WinCC OA开发的生产监控系统，优化网源调控参数，调整和预测供热负荷，通过能耗分析及智能诊断系统节能降耗。",
					img: "/img/article/北明天时.png",
					docUrl: "/article/北明天时企业案例.pdf"

				}, 
				{
					id: 9,
					name: "人才赋能，实现可持续发展",
					desc: "应用西门子虚拟仿真数字化产品和技术，构建数字化知识平台，赋能教育行业的师生和企业的工程师，实现数字化人才、低碳化知识能力的建设。",
					img: "/img/article/广州高谱.jpg",
					docUrl: "/article/广州高谱企业案例.pdf"

				},
				{
					id: 10,
					name: "北京某商务广场高效冷站及水蓄冷项目年综合能效5.13",
					desc: "运用达实创新研发中央空调节能控制系统EMC007（配合西门子1500系列PLC）与AIoT物联网智能管控平台，运用技术节能与管理节能，实现能效提升及能源管理。",
					img: "/img/article/深圳达实.png",
					docUrl: "/article/深圳达实智能企业案例.pdf"
				},
				{
					id: 11,
					name: "DHL集团是绿色物流的先行者，是全球首家提出至2050年实现物流相关零排放的物流企业",
					desc: "作为西门子中国物流供应链的长期合作伙伴，DHL快递中国区始终致力于加快推动绿色物流发展，并已取得多个里程碑成果，为助力西门子中国推动 “范围3”减排做出了贡献。",
					img: "/img/article/DHL.png",
					docUrl: "/article/运营先锋卓越奖-DHL.pdf"
				},
				{
					id: 12,
					name: "HRS的Green Stay倡议规范酒店行业可持续发展行为，打造行业标准体系",
					desc: "Green Stay 针对酒店可持续发展之旅的各个阶段，为其免费提供充分的企业政策评估。监控酒店基线的端到端表现，引导企业计划的可持续性影响与其他政策层面。",
					img: "/img/article/HRS.jpg",
					docUrl: "/article/HRS企业案例.pdf"

				},
			]
		},
		{
			title: "赋能先锋优秀奖",
			data: [
				{
					id:13,
					name: "多能源互补，冷热电储协同，全程能效管控的智慧低碳综合能源技术助力城市实现碳中和",
					desc: "采用西门子单机/冗余PLC控制系统保证机房安全稳定运行，采用西门子S7-1500，1200, 200Smart系列PLC搭建强大控制网络，一网到底快速控制楼层及房间内终端设备，使能源与环境完美协同控制",
					img: "/img/article/杭州源牌.png",
					docUrl: "/article/杭州源牌企业案例.pdf"

				},
				{
					id:14,
					name: "为暖通空调行业用户提供可测量的价值",
					desc: "提供基于SIMICAS智维宝平台+IoT2050边缘网关的HEYE工业空调综合监控及设备管理，实时监控设备/产线运行和故障情况，远程定位诊断设备问题，以提升设备/产线运营效率以及保障资产安全",
					img: "/img/article/紫邦智能科.jpg",
					docUrl: "/article/紫邦智能科技企业案例.pdf"

				},
				{
					id: 15,
					name: "低碳冶炼新工艺结合数字化技术，实现降碳减排双向目标，为钢铁行业提供切实可行的绿色解决方案",
					desc: "应用西门子S7-400H系列控制器组成自动化系统，并基于数字孪生技术和高炉专家系统构建高炉智慧平台。",
					img: "/img/article/佰能盈天.jpg",
					docUrl: "/article/北京佰能盈天企业案例.pdf"

				},
				{
					id: 16,
					name: "国际化的绿色智能制药工厂",
					desc: "通过采用西门子PCS7系统，IS，PH，SIMATIC Energy Manager PRO，S7 EE-Monitor实时监测工艺参数，通过调整生产参数来优化生产过称，减少资源浪费并提升生产效率；通过编写逻辑程序，确保生产设备按照最优方案运行，避免能源和物料浪费；通过创建统一的信息平台上实现生产过程中的质量控制、物料平衡、过程追踪、产品追溯、设备状态管理、生产绩效与报表分析等功能。",
					img: "/img/article/天俱时.jpg",
					docUrl: "/article/天俱时企业案例.pdf"

				},
				{

					id: 17,
					name: "基于能碳双控的碳账户管理系统，助力提升能源效率，减少碳排放。",
					desc: "园区能碳数字化管理解决方案，城市能碳数智化解决方案。",
					img: "/img/article/雪迪龙1.png",
					docUrl: "/article/雪迪龙企业案例1.pdf"

				},
				{
					id: 18,
					name: "基于数字可信认证的碳排放计量系统，助力碳排放精准监测与计量。",
					desc: "企业固定源碳排放计量系统，船舶智能碳排放计量系统。",
					img: "/img/article/雪迪龙2.png",
					docUrl: "/article/雪迪龙企业案例2.pdf"
				},
				{

					id: 19,
					name: "深时机电数字化展厅：数字化为企业赋能为社会节能",
					desc: "通过应用机械设备仿真NX MCD以及自动化仿真PLCSIM Advanced对工程系统整体联合调试，缩短现场调试时间，减少能源消耗。在某项目现场采用了虚拟仿真调试技术，现场调试工作量较比之前类似项目减少40%，客户现场水电气等能源消耗减少了30% ",
					img: "/img/article/深时机电.jpg",
					docUrl: "/article/深时机电企业案例.pdf"

				},
				{

					id: 20,
					name: "我司首次采用S7-1500+WINCC方式开发面粉项目",
					desc: "采用西门子S7-1500+ET200SP+Wincc方案，对产线各工艺段用电量进行监控、分析，客户可以结合实际产量和电量消耗进行比对和优化，在保持稳定生产的情况下，寻求最优效率的生产节奏。",
					img: "/img/article/重庆旭富.png",
					docUrl: "/article/重庆旭富企业案例.pdf"

				},
				{

					id: 21,
					name: "节能减排，助力企业低碳化",
					desc: "通过加装出水电动阀、电阻平衡阀、远程计量表等数据采集设备，使用西门子S7-1500、S7-1200、WinCC等，按需供应的自动调节运行功能，同时具备集中监控运行现场无人值守功能，减少了操作人员工作量，同时对用电消耗和燃煤消耗有所降低。",
					img: "/img/article/信恒工业自动化.png",
					docUrl: "/article/信恒工业企业案例.pdf"

				},
				{

					id: 22,
					name: "运用西门子1500/Smart帮助合作以及用户实现节能减排",
					desc: "采用西门子1500/Smart等工厂自动化产品以及数字化解决方案，积极推进产业绿色转型升级，通过提高设备智能化程度、能效改进、工业过程优化、工厂数字化等多项重要举措助力客户单位全面实现“碳达峰、碳中和”目标，不断提高企业韧性与竞争力的结构性机遇。",
					img: "/img/article/四川思达能.png",
					docUrl: "/article/四川思达能企业案例.pdf"

				},
				{

					id: 23,
					name: "生物制药行业空调及水系统综合优化节能",
					desc: "结合博图及WinCC实现工厂的水、电、气等数据的集中采集、数据分析，与相应的机组及相关系统联动实现最优节能运行。",
					img: "/img/article/成都英德.png",
					docUrl: "/article/成都英德企业案例.pdf"
				},
				{

					id: 24,
					name: "显著提升工厂及园区能源利用率、资源效率，降低碳排放",
					desc: "通过与西门子的深入合作，采用智能电网、能源存储、工业自动化等领域的先进技术及解决方案，推企业及周边的可持续发展",
					img: "/img/article/西兰花.jpg",
					docUrl: "/article/西兰花数字科技企业案例.pdf"
	            },
				{

					id: 25,
					name: "智慧低碳数字化赋能晶澳太阳能灯塔工厂建设",
					desc: "依托西门子S7-200 SMART等产品拓扑优化使自控系统设备稳定高效运行",
					img: "/img/article/大冲能源.jpg",
					docUrl: "/article/大冲能源企业案例.pdf"

				}
			]
		},
		{
			title: "运营先锋优秀奖（含入围卓越奖终选企业）",
			data: [
				{
					id:26,
					name: "以自身数字化技术与跨领域知识为合作伙伴树立低碳绿色运营示范工厂",
					desc: "公司已在自有园区内的停车场、办公楼及物流中心屋顶一共铺设了3000平方米的分布式光伏板；在自身运营、与上下游协作过程中，积极推行资源的循环利用。",
					img: "/img/article/南京朗驰.png",
					docUrl: "/article/南京朗驰企业案例.pdf"
				},
				{

					id: 27,
					name: "倡导绿色文化，不断提升清洁能源使用比例，积极开发绿色产品、绿色工艺",
					desc: "针对客户在绿电使用方面的要求，人本在有条件的厂房屋顶搭建光伏，并在后续规划绿电采购和绿证购买等相关事宜，以满足客户提出的绿电使用占比要求",
					img: "/img/article/人本集团.jpg",
					docUrl: "/article/人本企业案例1220.pdf"
				},
				{

					id: 28,
					name: "建立安全生产,节能减排的绿色工厂,提升企业竞争力;打造绿色永续供应链",
					desc: "与西门子展开低碳可持续领域及PLC产品应用合作",
					img: "/img/article/大同齿轮.png",
					docUrl: "/article/大同齿轮企业案例1220.pdf"
				},
				{

					id: 29,
					name: "太阳能光伏发电+智能储能的清洁能源综合利用",
					desc: "进一步加大光伏发电的投入，达到年发电46.4万千瓦时",
					img: "/img/article/温州贵派1.png",
					docUrl: "/article/温州贵派企业案例1.pdf"
				},
				{

					id: 30,
					name: "柔性自动线+视觉识别系统的低碳高效生产线",
					desc: "自动生产线采用：视觉识别+机械手自动抓取+共用标准件模具的形式，实现多系列，不同内部结构产品，通用一条全自动线。",
					img: "/img/article/温州贵派2.png",
					docUrl: "/article/温州贵派企业案例2.pdf"
				},
				{

					id: 31,
					name: "中央供料系统+自动注塑取料机械手",
					desc: "80余台注塑机，全部升级为海天最新一代低能耗机，并配备有自动机械手和MES系统，注塑硬件和软件设备得到全面提升。",
					img: "/img/article/温州贵派3.png",
					docUrl: "/article/温州贵派企业案例3.pdf"
				},
				{
					id: 32,
					name: "WMS扫码出入库系统+PDA移动端设备的仓储管理",
					desc: "增加WMS系统，按照西门子产品主数据更新，并与工厂ERP系统互联。实现出入库的扫码操作。",
					img: "/img/article/温州贵派4.png",
					docUrl: "/article/温州贵派企业案例4.pdf"
				},
				{

					id: 33,
					name: "打造可再生能源的精益供应链，成为行业领导者的卓越服务商",
					desc: "开发核心系统如MES，WMS，SCM，ERP，利用外部资源加速我司的数字化转型",
					img: "/img/article/苏州铜盟.png",
					docUrl: "/article/苏州铜盟企业案例1220.pdf"
				},
				{

					id: 34,
					name: "珍惜资源，爱护环境，建设生态企业",
					desc: "源网荷储一体化为对象构建绿色智慧能源管理云平台",
					img: "/img/article/陕西斯瑞.png",
					docUrl: "/article/陕西斯瑞企业案例1220.pdf"

				}
			]
		}
	]
}
