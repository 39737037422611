/**
 * 是否是一个链接
 *
 * @export
 * @param {string} path
 */
export function isUrl(path) {
  return /^https?:\/\//g.test(path)
}

/**
 * 提供对象存储图片链接
 * @param {string | undefined} path - 图片路径
 */
export function provideOssImgUrl(path) {
  if (!path) {
    return ''
  }
  if (isUrl(path)) {
    return path
  }
  return urlJoin([process.env.VUE_APP_OSS_BASE_URL, path])
}

/**
 * 拼接URL
 * @param {string[]} paths
 */
export function urlJoin(paths) {
  return paths.join('/').replace(/(?<=([^:]))\/\//g, '/')
}

/**
 * 是否CMS文件
 * @param {string} path
 */
export function isCmsFile(path = '') {
  return /cmsfiles\//g.test(path)
}
