<template>
    <div v-if="cookieDivOr">
        <div class="overlay"></div>
        <div class="modal-dialog">
            <div class="dialog_container">
                <div class="dialog-content">
                    <div class="dialog-content-wrapper">
                        <div class="content-title font_Bold">{{$t('cookie.title')}}</div>
                        <div class="content-message">{{$t('cookie.content')}}</div>
                        <div class="content-btn">
                            <div class="anchors">
                                <a class="anchors-link" :href="noticeUrl" target="_blank">{{$t('cookie.announcement')}}</a>
                            </div>
                            <!-- <div class="customize"><button class="customize-btn">偏好设置</button></div> -->
                        </div>
                    </div>
                </div>
                <div class="dialog-button">
                    <div class="buttons-container">
                        <button @click="closeDiv(false)">{{$t('cookie.reject')}}</button>
                        <button @click="closeDiv(true)">{{$t('cookie.accept')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, defineComponent } from 'vue'
import { getLang } from '@/utils/lang.js'
export default defineComponent({
  name: 'dialogBar',
  setup () {
    const noticeUrl = getLang() === 'zh' ? 'https://new.siemens.com/cn/zh/general/cookie-notice.html' : 'https://www.siemens.com/cn/en/general/cookie-notice.html'
    // 隐私证明显示
    const cookieDivOr = ref(true)
    // cookie
    const getCookie = (cname) => {
      let isShowCookie = true
      if (localStorage.getItem(cname)) {
        const ca = localStorage.getItem(cname).split(';')
        if (ca[1]) {
          const d = new Date().getTime()
          if (d < ca[1]) { isShowCookie = false }
        }
      }
      return isShowCookie
    }
    const setCookie = (cname, cvalue, exdays) => {
      const d = new Date()
      const expires = d.getTime() + (exdays * 24 * 60 * 60 * 1000)
      localStorage.setItem(cname, cvalue + '; ' + expires)
    }
    const clearCookie = () => {
      setCookie('ActPersonalInfo', '', -1000)// 设置天数 //
    }
    const checkCookie = () => {
      const isShow = getCookie('ActPersonalInfo')
      const isPreview = window.location.href.includes('isPreview')
      cookieDivOr.value = isPreview ? false : isShow
    }
    const closeDiv = (isAccept) => {
      setCookie('ActPersonalInfo', isAccept, 30)// 设置天数
      cookieDivOr.value = false
    }
    onMounted(() => {
      checkCookie()
    })
    return {
      cookieDivOr,
      getCookie,
      setCookie,
      clearCookie,
      checkCookie,
      closeDiv,
      noticeUrl
    }
  }
})
</script>

<style lang="scss" scoped>
.overlay {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    opacity: 0.7;
    height: 100%;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999997;
}

.modal-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    margin: auto;
    display: flex;
    z-index: 999998;

    .dialog_container {
        background-color: rgb(0, 16, 52);
        display: flex;
        flex-direction: column;
        z-index: 999999;
        align-self: center;
        border-radius: 0px;
        box-shadow: none;
        height: initial;
        margin-left: auto;
        margin-right: auto;
        max-height: 80vh;
        max-width: 625px;
        outline: none;
        overflow: hidden;
        width: 100%;
        pointer-events: auto;
        position: relative;
        .dialog-content {
            height: 100%;
            max-height: 65vh;
            overflow-y: auto;
            .dialog-content-wrapper {
                color: white;
                padding: 24px 24px 12px;

                .content-title {
                    font-size: 20.25px;
                    font-weight: 500;
                    margin: 0;
                    // margin-bottom: 8px;
                }

                .content-message {
                    margin-top: 12px;
                    // margin-bottom: 8px;
                    font-size: 18px;
                    font-weight: normal;
                }

                .content-btn {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;

                    .anchors {
                        .anchors-link {
                            -webkit-box-align: center;
                            align-items: center;
                            color: rgb(0, 204, 204);
                            cursor: pointer;
                            display: flex;
                            flex-direction: row;
                            padding-top: 4px;
                            font-size: 0.875em;
                            font-weight: normal;
                            margin-right: 12px;
                            outline: none;
                            text-decoration: none;
                            word-break: break-word;
                        }
                    }

                    .customize {
                        .customize-btn {
                            -webkit-box-align: center;
                            align-items: center;
                            background-color: transparent;
                            border: none;
                            display: flex;
                            flex-direction: row;
                            font-size: 0.875em;
                            font-weight: normal;
                            margin: 0px 12px 0px 0px;
                            outline: none;
                            padding: 4px 0px 0px;
                            text-decoration: none;
                            color: rgb(0, 204, 204);
                        }
                    }
                }
            }
        }

        .dialog-button {
            border-top: none;
            background-color: rgb(0, 16, 52);
            display: block;
            bottom: 0px;
            left: 0px;
            width: 100%;
            z-index: 100;
            padding: 0px 24px 12px;
        }

        .buttons-container {
            display: flex;
            flex-direction: row;
            flex: 2 1 auto;
            margin:0 -6px;

            button {
                margin: 0 6px;
                -webkit-box-align: center;
                align-items: center;
                content: none;
                background: rgb(0, 204, 204);
                border: none;
                border-radius: 0px;
                color: rgb(0, 0, 40);
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                flex: 1 1 auto;
                font-size: 1em;
                font-weight: 500;
                outline: none;
                padding: 12px 16px;
                width: 100%;
            }
        }
    }
}
</style>
