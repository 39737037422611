import { defineComponent } from 'vue'
import style from '../styles/navigation.module.scss'

/**
 * 侧边菜单
 */
export const NavigationRail = defineComponent(() => {
  return () => {
    return (
      <div class={`${style.wrapper} ${style.nvavigation_rail}`}>
        <div class={`${style.leading} ${style.scroll}`}>概览</div>
        <div class={`${style.children} ${style.scroll}`}>
          <div>
            产品详情
            <div class={style.children}>
              <div>
                <a href='#product'>产品介绍</a>
              </div>
              <div>
                <a href='#applicationScenarios'>应用场景</a>
              </div>
              <div>
                <a href='#productArchitecture'>产品架构</a>
              </div>
              <div>
                <a href='#productSuperiority'>产品优势</a>
              </div>
              <div>
                <a href='#productIncome'>获益</a>
              </div>
            </div>
          </div>
          <div>
            <a href='#buyAndTry'>购买与试用</a>
            <div class={style.children}>
              <div>
                <a href='#commodityPurchase'>商品购买</a>
              </div>
              <div>
                <a href='#tryOut'>试用</a>
              </div>
              <div>
                <a href='#trialProceeds'>获益</a>
              </div>
            </div>
          </div>
          <div>
            <a href='#case'>客户案例</a>
          </div>
          <div>
            <a href='#ecopark'>生态伙伴</a>
          </div>
          <div>
            <a href='#resource'>资源（白皮书/报告/视频）</a>
          </div>
          <div>
            <a href='#contactUs'>联系我们</a>
          </div>
        </div>
      </div>
    )
  }
})
