export default {
  country_list: {
    阿富汗: '阿富汗',
    阿尔巴尼亚: '阿尔巴尼亚',
    阿尔及利亚: '阿尔及利亚',
    安道尔: '安道尔',
    安哥拉: '安哥拉',
    安圭拉: '安圭拉',
    安提瓜和巴布达: '安提瓜和巴布达',
    阿根廷: '阿根廷',
    亚美尼亚: '亚美尼亚',
    阿森松: '阿森松',
    澳大利亚: '澳大利亚',
    奥地利: '奥地利',
    阿塞拜疆: '阿塞拜疆',
    巴哈马: '巴哈马',
    巴林: '巴林',
    孟加拉国: '孟加拉国',
    巴巴多斯: '巴巴多斯',
    白俄罗斯: '白俄罗斯',
    比利时: '比利时',
    伯利兹: '伯利兹',
    贝宁: '贝宁',
    百慕大是: '百慕大是',
    玻利维亚: '玻利维亚',
    博茨瓦纳: '博茨瓦纳',
    巴西: '巴西',
    文莱: '文莱',
    保加利亚: '保加利亚',
    布基纳法索: '布基纳法索',
    缅甸: '缅甸',
    蒲隆地: '蒲隆地',
    喀麦隆: '喀麦隆',
    加拿大: '加拿大',
    开曼群岛: '开曼群岛',
    中非共和国: '中非共和国',
    乍得: '乍得',
    智利: '智利',
    中国: '中国',
    哥伦比亚: '哥伦比亚',
    刚果: '刚果',
    库克是: '库克是',
    哥斯达黎加: '哥斯达黎加',
    克罗地亚: '克罗地亚',
    古巴: '古巴',
    塞浦路斯: '塞浦路斯',
    捷克共和国: '捷克共和国',
    丹麦: '丹麦',
    吉布提: '吉布提',
    多米尼克共和国: '多米尼克共和国',
    厄瓜多尔: '厄瓜多尔',
    埃及: '埃及',
    萨尔瓦多: '萨尔瓦多',
    爱沙尼亚: '爱沙尼亚',
    埃塞俄比亚: '埃塞俄比亚',
    斐济: '斐济',
    芬兰: '芬兰',
    法国: '法国',
    法属圭亚那: '法属圭亚那',
    法属波利尼西亚: '法属波利尼西亚',
    加蓬: '加蓬',
    冈比亚: '冈比亚',
    格鲁吉亚: '格鲁吉亚',
    德国: '德国',
    加纳: '加纳',
    直布罗陀: '直布罗陀',
    希腊: '希腊',
    格林纳达: '格林纳达',
    关岛: '关岛',
    危地马拉: '危地马拉',
    几内亚: '几内亚',
    圭亚那: '圭亚那',
    海地: '海地',
    洪都拉斯: '洪都拉斯',
    匈牙利: '匈牙利',
    冰岛: '冰岛',
    印度: '印度',
    印度尼西亚: '印度尼西亚',
    伊朗: '伊朗',
    伊拉克: '伊拉克',
    爱尔兰: '爱尔兰',
    以色列: '以色列',
    意大利: '意大利',
    科特迪瓦: '科特迪瓦',
    牙买加: '牙买加',
    日本: '日本',
    约旦: '约旦',
    柬埔寨: '柬埔寨',
    哈萨克斯坦: '哈萨克斯坦',
    肯尼亚: '肯尼亚',
    韩国: '韩国',
    科威特: '科威特',
    吉尔吉斯斯坦: '吉尔吉斯斯坦',
    老挝: '老挝',
    拉托维亚: '拉托维亚',
    黎巴嫩: '黎巴嫩',
    莱索托: '莱索托',
    利比里亚: '利比里亚',
    利比亚: '利比亚',
    列支敦士登: '列支敦士登',
    立陶宛: '立陶宛',
    卢森堡: '卢森堡',
    马达加斯加: '马达加斯加',
    马拉维: '马拉维',
    马来西亚: '马来西亚',
    马尔代夫: '马尔代夫',
    马里: '马里',
    马耳他: '马耳他',
    马里亚纳是: '马里亚纳是',
    马提尼克: '马提尼克',
    毛里求斯: '毛里求斯',
    墨西哥: '墨西哥',
    摩尔多瓦共和国: '摩尔多瓦共和国',
    摩纳哥: '摩纳哥',
    蒙古: '蒙古',
    蒙特塞拉特是: '蒙特塞拉特是',
    摩洛哥: '摩洛哥',
    莫桑比克: '莫桑比克',
    纳米比亚: '纳米比亚',
    瑙鲁: '瑙鲁',
    尼泊尔: '尼泊尔',
    下界安的列斯群岛: '下界安的列斯群岛',
    荷兰: '荷兰',
    新西兰: '新西兰',
    尼加拉瓜: '尼加拉瓜',
    尼日尔: '尼日尔',
    尼日利亚: '尼日利亚',
    朝鲜: '朝鲜',
    挪威: '挪威',
    阿曼: '阿曼',
    巴基斯坦: '巴基斯坦',
    巴拿马: '巴拿马',
    巴布亚新奎因: '巴布亚新奎因',
    巴拉圭: '巴拉圭',
    秘鲁: '秘鲁',
    菲律宾: '菲律宾',
    波兰: '波兰',
    葡萄牙: '葡萄牙',
    波多黎各: '波多黎各',
    卡塔尔: '卡塔尔',
    团圆: '团圆',
    罗马尼亚: '罗马尼亚',
    俄罗斯: '俄罗斯',
    圣基茨和尼维斯: '圣基茨和尼维斯',
    圣卢埃亚: '圣卢埃亚',
    圣文森特: '圣文森特',
    萨摩亚东部: '萨摩亚东部',
    萨摩亚西部: '萨摩亚西部',
    圣马力诺: '圣马力诺',
    圣多美和普林西比: '圣多美和普林西比',
    沙特阿拉伯: '沙特阿拉伯',
    塞内加尔: '塞内加尔',
    塞舌尔: '塞舌尔',
    塞拉利昂: '塞拉利昂',
    新加坡: '新加坡',
    斯洛伐克: '斯洛伐克',
    斯洛文尼亚: '斯洛文尼亚',
    所罗门是: '所罗门是',
    索马里语: '索马里语',
    南非: '南非',
    西班牙: '西班牙',
    斯里兰卡: '斯里兰卡',
    圣卢西亚: '圣卢西亚',
    叙利亚: '叙利亚',
    塔吉克斯坦: '塔吉克斯坦',
    坦桑尼亚: '坦桑尼亚',
    泰国: '泰国',
    多哥: '多哥',
    汤加: '汤加',
    特立尼达和多巴哥: '特立尼达和多巴哥',
    突尼斯: '突尼斯',
    土耳其: '土耳其',
    土库曼斯坦: '土库曼斯坦',
    乌干达: '乌干达',
    乌克兰: '乌克兰',
    阿拉伯联合酋长国: '阿拉伯联合酋长国',
    联合建国: '联合建国',
    美国: '美国',
    乌拉圭: '乌拉圭',
    乌兹别克斯坦: '乌兹别克斯坦',
    委内瑞拉: '委内瑞拉',
    越南: '越南',
    也门: '也门',
    南斯拉夫: '南斯拉夫',
    扎伊尔: '扎伊尔',
    赞比亚: '赞比亚',
    津巴布韦: '津巴布韦'

  }
}
