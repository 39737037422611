export default {
  province_list: {
    110000: 'Beijing',
    120000: 'Tianjin',
    130000: 'Hebei',
    140000: 'Shanxi',
    150000: 'Inner Mongolia',
    210000: 'Liaoning',
    220000: 'Jilin',
    230000: 'Heilongjiang',
    310000: 'Shanghai',
    320000: 'Jiangsu',
    330000: 'Zhejiang',
    340000: 'Anhui',
    350000: 'Fujian',
    360000: 'Jiangxi',
    370000: 'Shandong',
    410000: 'Henan',
    420000: 'Hubei',
    430000: 'Hunan',
    440000: 'Guangdong',
    450000: 'Guangxi',
    460000: 'Hainan',
    500000: 'Chongqing',
    510000: 'Sichuan',
    520000: 'Guizhou',
    530000: 'Yunnan',
    540000: 'Tibet',
    610000: 'Shaanxi',
    620000: 'Gansu',
    630000: 'Qinghai',
    640000: 'Ningxia',
    650000: 'Xinjiang',
    710000: 'Taiwan',
    810000: 'Hong Kong',
    820000: 'Macao',
    900000: 'Overseas'
  },
  city_list: {
    110100: 'Beijing',
    120100: 'Tianjin',
    130100: 'Shijiazhuang',
    130200: 'Tangshan',
    130300: 'Qinhuangdao',
    130400: 'Handan',
    130500: 'Xingtai',
    130600: 'Baoding',
    130700: 'Zhangjiakou',
    130800: 'Chengde',
    130900: 'Cangzhou',
    131000: 'Langfang',
    131100: 'Hengshui',
    140100: 'Taiyuan',
    140200: 'Datong',
    140300: 'Yangquan',
    140400: 'Changzhi',
    140500: 'Jincheng',
    140600: 'Shuozhou',
    140700: 'Jinzhong',
    140800: 'Yuncheng',
    140900: 'Xinzhou',
    141000: 'Linfen',
    141100: 'Lüliang',
    150100: 'Hohhot',
    150200: 'Baotou',
    150300: 'Wuhai',
    150400: 'Chifeng',
    150500: 'Tongliao',
    150600: 'Ordos',
    150700: 'Hulunbuir',
    150800: 'Bayannaur',
    150900: 'Ulanqab',
    152200: 'Hung An Meng',
    152500: 'Xilin Golmeng',
    152900: 'Alashan League',
    210100: 'Shenyang',
    210200: 'Dalian',
    210300: 'Anshan',
    210400: 'Fushun',
    210500: 'Benxi',
    210600: 'Dandong',
    210700: 'Jinzhou',
    210800: 'Yingkou',
    210900: 'Fuxin',
    211000: 'Liaoyang',
    211100: 'Panjin',
    211200: 'Tieling',
    211300: 'Chaoyang',
    211400: 'Huludao',
    220100: 'Changchun',
    220200: 'Jilin',
    220300: 'Siping',
    220400: 'Liaoyuan',
    220500: 'Tonghua',
    220600: 'Hakusan',
    220700: 'Songwon',
    220800: 'White',
    222400: 'Yanbian',
    230100: 'Harbin',
    230200: 'Qiqihar',
    230300: 'Jixi',
    230400: 'Hegang',
    230500: 'Shuangyashan',
    230600: 'Daqing',
    230700: 'Yichun',
    230800: 'Jiamusi',
    230900: 'Qitaihe',
    231000: 'Mudanjiang',
    231100: 'Heihe',
    231200: 'Suihua',
    232700: 'Greater Xing anling area',
    310100: 'Shanghai',
    320100: 'Nanjing',
    320200: 'Wuxi',
    320300: 'Xuzhou',
    320400: 'Changzhou',
    320500: 'Suzhou',
    320600: 'Nantong',
    320700: 'Lianyungang',
    320800: 'Huai an',
    320900: 'Yancheng',
    321000: 'Yangzhou',
    321100: 'Zhenjiang',
    321200: 'Taizhou',
    321300: 'Suqian',
    330100: 'Hangzhou',
    330200: 'Ningbo',
    330300: 'Wenzhou',
    330400: 'Jiaxing',
    330500: 'Huzhou',
    330600: 'Shaoxing',
    330700: 'Jinhua',
    330800: 'Quzhou',
    330900: 'Zhoushan',
    331000: 'Taizhou',
    331100: 'Yeosu',
    340100: 'Hefei',
    340200: 'Wuhu',
    340300: 'Bengbu',
    340400: 'Huainan',
    340500: 'Ma anshan',
    340600: 'Huaibei',
    340700: 'Tongling',
    340800: 'Anqing',
    341000: 'Huangshan',
    341100: 'Chuzhou',
    341200: 'Fuyang',
    341300: 'Suzhou',
    341500: 'Lu an',
    341600: 'Bozhou',
    341700: 'Chizhou',
    341800: 'Xuancheng',
    350100: 'Fuzhou',
    350200: 'Xiamen',
    350300: 'Putian',
    350400: 'Sanming',
    350500: 'Quanzhou',
    350600: 'Zhangzhou',
    350700: 'Nanping',
    350800: 'Longyan',
    350900: 'Ningde',
    360100: 'Nanchang',
    360200: 'Jingdezhen',
    360300: 'Pingxiang',
    360400: 'Jiujiang',
    360500: 'Xinyu',
    360600: 'Yingtan',
    360700: 'Ganzhou',
    360800: 'Ji an',
    360900: 'Yichun',
    361000: 'Fuzhou',
    361100: 'Shangrao',
    370100: 'Jinan',
    370200: 'Qingdao',
    370300: 'Zibo',
    370400: 'Zaozhuang',
    370500: 'Dongying',
    370600: 'Yantai',
    370700: 'Weifang',
    370800: 'Jining',
    370900: 'Tai an',
    371000: 'Weihai',
    371100: 'Rizhao',
    371300: 'Linyi',
    371400: 'Texas',
    371500: 'Liaocheng',
    371600: 'Binzhou',
    371700: 'Heze',
    410100: 'Zhengzhou',
    410200: 'Kaifeng',
    410300: 'Luoyang',
    410400: 'Pingdingshan',
    410500: 'Anyang',
    410600: 'Hebi',
    410700: 'Xinxiang',
    410800: 'Jiaozuo',
    410900: 'Puyang',
    411000: 'Xuchang',
    411100: 'Luohe',
    411200: 'Sanmenxia',
    411300: 'Nanyang',
    411400: 'Shangqiu',
    411500: 'Xinyang',
    411600: 'Zhoukou',
    411700: 'Zhumadian',
    419000: 'Counties directly under the central government',
    420100: 'Wuhan',
    420200: 'Yellowstone',
    420300: 'Shiyan',
    420500: 'Yichang',
    420600: 'Xiangyang',
    420700: 'Ezhou',
    420800: 'Jingmen',
    420900: 'Xiaogan',
    421000: 'Jingzhou',
    421100: 'Huanggang',
    421200: 'Xianning',
    421300: 'Suizhou',
    422800: 'Enshi',
    429000: 'Provincial County',
    430100: 'Changsha',
    430200: 'Zhuzhou',
    430300: 'Xiangtan',
    430400: 'Hengyang',
    430500: 'Shaoyang',
    430600: 'Yueyang',
    430700: 'Changde',
    430800: 'Zhangjiajie',
    430900: 'Yiyang',
    431000: 'Chenzhou',
    431100: 'Yongzhou',
    431200: 'Huaihua',
    431300: 'Loudi',
    433100: 'Xiangxi',
    440100: 'Guangzhou',
    440200: 'Shaoguan',
    440300: 'Shenzhen',
    440400: 'Zhuhai',
    440500: 'Shantou',
    440600: 'Foshan',
    440700: 'Jiangmen',
    440800: 'Zhanjiang',
    440900: 'Maoming',
    441200: 'Zhaoqing',
    441300: 'Huizhou',
    441400: 'Meizhou',
    441500: 'Shanwei',
    441600: 'Heyuan',
    441700: 'Yangjiang',
    441800: 'Qingyuan',
    441900: 'Dongguan',
    442000: 'Zhongshan',
    445100: 'Chaozhou',
    445200: 'Jieyang',
    445300: 'Yunfu',
    450100: 'Nanning',
    450200: 'Liuzhou',
    450300: 'Guilin',
    450400: 'Wuzhou',
    450500: 'Beihai',
    450600: 'Fangchenggang',
    450700: 'Qinzhou',
    450800: 'Guigang',
    450900: 'Yulin',
    451000: 'Baise',
    451100: 'Hezhou',
    451200: 'Hechi',
    451300: 'Laibin',
    451400: 'Chongzuo',
    460100: 'Haikou',
    460200: 'Sanya',
    460300: 'Sansha',
    460400: 'Danzhou',
    469000: 'Counties directly under the Central Government',
    500100: 'Chongqing',
    500200: 'County',
    510100: 'Chengdu',
    510300: 'Zigong',
    510400: 'Panzhihua',
    510500: 'Luzhou',
    510600: 'Deyang',
    510700: 'Mianyang',
    510800: 'Guangyuan',
    510900: 'Suining',
    511000: 'Neijiang',
    511100: 'Leshan',
    511300: 'Nanchong',
    511400: 'Meishan',
    511500: 'Yibin',
    511600: 'Gwang an',
    511700: 'Dazhou',
    511800: 'Ya an',
    511900: 'Bazhong',
    512000: 'Ziyang',
    513200: 'Aba',
    513300: 'Kardze',
    513400: 'Liangshan',
    520100: 'Guiyang',
    520200: 'Liupanshui',
    520300: 'Zunyi',
    520400: 'Anshun',
    520500: 'Bijie',
    520600: 'Tongren',
    522300: 'Qianxian',
    522600: 'Qiandongnan',
    522700: 'Qiannan',
    530100: 'Kunming',
    530300: 'Qujing',
    530400: 'Yuxi',
    530500: 'Baoshan',
    530600: 'Zhaotong',
    530700: 'Lijiang',
    530800: 'Pu er',
    530900: 'Lincang',
    532300: 'Chuxiong',
    532500: 'Honghe',
    532600: 'Wenshan',
    532800: 'Xishuangbanna',
    532900: 'Dali',
    533100: 'Dehong',
    533300: 'Nujiang',
    533400: 'Diqing',
    540100: 'Lhasa',
    540200: 'Shigatse',
    540300: 'Changdu',
    540400: 'Nyingchi',
    540500: 'Shannan',
    540600: 'Nagqu',
    542500: 'Ali District',
    610100: 'Xi an',
    610200: 'Tongchuan',
    610300: 'Baoji',
    610400: 'Xianyang',
    610500: 'Weinan',
    610600: 'Yan an',
    610700: 'Hanzhong',
    610800: 'Yulin',
    610900: 'Ankang',
    611000: 'Shangluo',
    620100: 'Lanzhou',
    620200: 'Jiayuguan',
    620300: 'Jinchang',
    620400: 'Silver',
    620500: 'Tianshui',
    620600: 'Wuwei',
    620700: 'Zhangye',
    620800: 'Pingliang',
    620900: 'Jiuquan',
    621000: 'Qingyang',
    621100: 'Dingxi',
    621200: 'Longnan',
    622900: 'Linxia',
    623000: 'Gannan',
    630100: 'Xining',
    630200: 'Haidong',
    632200: 'Haibei',
    632300: 'Huangnan',
    632500: 'Hainan',
    632600: 'Goluo',
    632700: 'Yushu ',
    632800: 'Haixi',
    640100: 'Yinchuan',
    640200: 'Shizuishan',
    640300: 'Wuzhong',
    640400: 'Guyuan',
    640500: 'Zhongwei',
    650100: 'Urumqi',
    650200: 'Karamay',
    650400: 'Turpan',
    650500: 'Hami',
    652300: 'Changji',
    652700: 'Bortala',
    652800: 'Bayingol',
    652900: 'Aksu region',
    653000: 'Kirgiz',
    653100: 'Kashgar District',
    653200: 'Hotan area',
    654000: 'Ili',
    654200: 'Tower Area',
    654300: 'Altay region',
    659000: 'county-level administrative divisions directly under the autonomous region',
    710100: 'Taipei',
    710200: 'Kaohsiung',
    710300: 'Tainan',
    710400: 'Taichung',
    710500: 'Kinmen County',
    710600: 'Nantou County',
    710700: 'Keelung',
    710800: 'Hsinchu',
    710900: 'Chiayi',
    711100: 'New Taipei',
    711200: 'Yilan County',
    711300: 'Hsinchu County',
    711400: 'Taoyuan County',
    711500: 'Miaoli County',
    711700: 'Changhua County',
    711900: 'Chiayi County',
    712100: 'Yunlin',
    712400: 'Pingtung County',
    712500: 'Taitung County',
    712600: 'Hualien County',
    712700: 'Penghu County',
    712800: 'Lianjiang County',
    810100: 'Hong Kong Island',
    810200: 'Kowloon',
    810300: 'New Territories',
    820100: 'Macau Peninsula',
    820200: 'Outlying Islands',
    900400: 'Afghanistan',
    900800: 'Albania',
    901000: 'Antarctica',
    901200: 'Algeria',
    901600: 'American Samoa',
    902000: 'Andorra',
    902400: 'Angola',
    902800: 'Antigua and Barbuda',
    903100: 'Azerbaijan',
    903200: 'Argentina',
    903600: 'Australia',
    904000: 'Austria',
    904400: 'Bahamas',
    904800: 'Bahrain',
    905000: 'Bangladesh',
    905100: 'Armenia',
    905200: 'Barbados',
    905600: 'Belgium',
    906000: 'Bermuda',
    906400: 'Bhutan',
    906800: 'Bolivia',
    907000: 'Bosnia and Herzegovina',
    907200: 'Botswana',
    907400: 'Bouvet Island',
    907600: 'Brazil',
    908400: 'Belize',
    908600: 'British Indian Ocean Territory',
    909000: 'Solomon Islands',
    909200: 'British Virgin Islands',
    909600: 'Brunei',
    910000: 'Bulgaria',
    910400: 'Myanmar',
    910800: 'Burundi',
    911200: 'Belarus',
    911600: 'Cambodia',
    912000: 'Cameroon',
    912400: 'Canada',
    913200: 'Cape Verde',
    913600: 'Cayman Islands',
    914000: 'Central Africa',
    914400: 'Sri Lanka',
    914800: 'Chad',
    915200: 'Chile',
    916200: 'Christmas Island',
    916600: 'Cocos Islands',
    917000: 'Columbia',
    917400: 'Comoros',
    917500: 'Mayotte',
    917800: 'Congo Brazzaville',
    918000: 'DRC',
    918400: 'Cook Islands',
    918800: 'Costa Rica',
    919100: 'Croatia',
    919200: 'Cuba',
    919600: 'Cyprus',
    920300: 'Czech Republic',
    920400: 'Benin',
    920800: 'Denmark',
    921200: 'Dominica',
    921400: 'Dominica',
    921800: 'Ecuador',
    922200: 'El Salvador',
    922600: 'Equatorial Guinea',
    923100: 'Ethiopia',
    923200: 'Eritrea',
    923300: 'Estonia',
    923400: 'Faroe Islands',
    923800: 'Malvinas Islands (Falkland)',
    923900: 'South Georgia and the South Sandwich Islands',
    924200: 'Fiji Islands',
    924600: 'Finland',
    924800: 'Åland Islands',
    925000: 'France',
    925400: 'French Guiana',
    925800: 'French Polynesia',
    926000: 'French Southern Territories',
    926200: 'Djibouti',
    926600: 'Gabon',
    926800: 'Georgia',
    927000: 'Gambia',
    927500: 'Palestine',
    927600: 'Germany',
    928800: 'Ghana',
    929200: 'Gibraltar',
    929600: 'Kiribati',
    930000: 'Greece',
    930400: 'Greenland',
    930800: 'Grenada',
    931200: 'Guadeloupe',
    931600: 'Guam',
    932000: 'Guatemala',
    932400: 'Guinea',
    932800: 'Guyana',
    933200: 'Haiti',
    933400: 'Heard Island and the McDonald Islands',
    933600: 'Vatican',
    934000: 'Honduras',
    934800: 'Hungary',
    935200: 'Iceland',
    935600: 'India',
    936000: 'Indonesia',
    936400: 'Iran',
    936800: 'Iraq',
    937200: 'Ireland',
    937600: 'Israel',
    938000: 'Italy',
    938400: 'Côte d‘Ivoire',
    938800: 'Jamaica',
    939200: 'Japan',
    939800: 'Kazakhstan',
    940000: 'Jordan',
    940400: 'Kenya',
    940800: 'North Korea North Korea',
    941000: 'Korea',
    941400: 'Kuwait',
    941700: 'Kyrgyzstan',
    941800: 'Laos',
    942200: 'Lebanon',
    942600: 'Lesotho',
    942800: 'Latvia',
    943000: 'Liberia',
    943400: 'Libya',
    943800: 'Liechtenstein',
    944000: 'Lithuania',
    944200: 'Luxembourg',
    945000: 'Madagascar',
    945400: 'Malawi',
    945800: 'Malaysia',
    946200: 'Maldives',
    946600: 'Mali',
    947000: 'Malta',
    947400: 'Martinique',
    947800: 'Mauritania',
    948000: 'Mauritius',
    948400: 'Mexico',
    949200: 'Monaco',
    949600: 'Mongolia',
    949800: 'Moldova',
    949900: 'Montenegro',
    950000: 'Montserrat',
    950400: 'Morocco',
    950800: 'Mozambique',
    951200: 'Oman',
    951600: 'Namibia',
    952000: 'Nauru',
    952400: 'Nepal',
    952800: 'Netherlands',
    953300: 'Aruba',
    953500: 'Dutch Caribbean',
    954000: 'New Caledonia',
    954800: 'Vanuatu',
    955400: 'New Zealand',
    955800: 'Nicaragua',
    956200: 'Niger',
    956600: 'Nigeria',
    957000: 'Niue',
    957400: 'Norfolk Island',
    957800: 'Norway',
    958000: 'Northern Mariana Islands',
    958100: 'U.S. Minor Outlying Islands',
    958300: 'Micronesia (Federated States of',
    958400: 'Marshall Islands',
    958500: 'Palau',
    958600: 'Pakistan',
    959100: 'Panama',
    959800: 'Papua New Guinea',
    960000: 'Paraguay',
    960400: 'Peru',
    960800: 'Philippines',
    961200: 'Pitcairn Islands',
    961600: 'Poland',
    962000: 'Portugal',
    962400: 'Guinea-Bissau',
    962600: 'East Timor',
    963000: 'Puerto Rico',
    963400: 'Qatar',
    963800: 'Reunion',
    964200: 'Romania',
    964300: 'Russia',
    964600: 'Rwanda',
    965200: 'Saint Barthélemy Island',
    965400: 'Saint Helena',
    965900: 'Saint Kitts and Nevis',
    966000: 'Anguilla',
    966200: 'Saint Lucia',
    966300: 'Saint Martin of France',
    966600: 'Saint Pierre and Miquelon',
    967000: 'Saint Vincent and the Grenadines',
    967400: 'San Marino',
    967800: 'Sao Tome and Principe',
    968200: 'Saudi Arabia',
    968600: 'Senegal',
    968800: 'Serbia',
    969000: 'Seychelles',
    969400: 'Sierra Leone',
    970200: 'Singapore',
    970300: 'Slovakia',
    970400: 'Vietnam',
    970500: 'Slovenia',
    970600: 'Somalia',
    971000: 'South Africa',
    971600: 'Zimbabwe',
    972400: 'Spain',
    972800: 'South Sudan',
    972900: 'Sudan',
    973200: 'Western Sahara',
    974000: 'Suriname',
    974400: 'Svalbard and Jan Mayen',
    974800: 'Eswatini',
    975200: 'Sweden',
    975600: 'Switzerland',
    976000: 'Syria',
    976200: 'Tajikistan',
    976400: 'Thailand',
    976800: 'Togo',
    977200: 'Tokelau',
    977600: 'Tonga',
    978000: 'Trinidad and Tobago',
    978400: 'UAE',
    978800: 'Tunisia',
    979200: 'Turkey',
    979500: 'Turkmenistan',
    979600: 'Turks and Caicos Islands',
    979800: 'Tuvalu',
    980000: 'Uganda',
    980400: 'Ukraine',
    980700: 'Macedonia',
    981800: 'Egypt',
    982600: 'United Kingdom',
    983100: 'Guernsey',
    983200: 'Jersey',
    983300: 'Isle of Man',
    983400: 'Tanzania',
    984000: 'USA',
    985000: 'U.S. Virgin Islands',
    985400: 'Burkina Faso',
    985800: 'Uruguay',
    986000: 'Uzbekistan',
    986200: 'Venezuela',
    987600: 'Wallis and Futuna',
    988200: 'Samoa',
    988700: 'Yemen',
    989400: 'Zambia'
  },
  county_list: {
    110101: 'Dongcheng District',
    110102: 'Xicheng District',
    110105: 'Chaoyang District',
    110106: 'Fengtai District',
    110107: 'Shijingshan District',
    110108: 'Haidian District',
    110109: 'Mentougou District',
    110111: 'Fangshan District',
    110112: 'Tongzhou District',
    110113: 'Shunyi District',
    110114: 'Changping District',
    110115: 'Daxing District',
    110116: 'Huairou District',
    110117: 'Pinggu District',
    110118: 'Miyun District',
    110119: 'Yanqing District',
    120101: 'Zone of Peace',
    120102: 'Hedong District',
    120103: 'Hexi District',
    120104: 'Nankai District',
    120105: 'Hebei District',
    120106: 'Hongqiao District',
    120110: 'Toray District',
    120111: 'Xiqing District',
    120112: 'Jinnan-gu',
    120113: 'Beichen District',
    120114: 'Wuqing District',
    120115: 'Baodi District',
    120116: 'Binhai New Area',
    120117: 'Ninghe District',
    120118: 'Quiet Sea District',
    120119: 'Jizhou District',
    130102: 'Chang an District',
    130104: 'Bridge West District',
    130105: 'Xinhua District',
    130107: 'Jingxian Mining Area',
    130108: 'Yuhua District',
    130109: 'Yucheng District',
    130110: 'Luquan District',
    130111: 'Luancheng District',
    130121: 'Jingxian County',
    130123: 'Zhengding County',
    130125: 'Xingtang County',
    130126: 'Lingshou County',
    130127: 'Gaoyi County',
    130128: 'Fukasawa Prefecture',
    130129: 'Zanhuang County',
    130130: 'Wuji County',
    130131: 'Pingshan County',
    130132: 'Yuanshi County',
    130133: 'Zhao County',
    130181: 'Sim Bazaar',
    130183: 'Jinju',
    130184: 'Xinle',
    130202: 'South of Road',
    130203: 'Road North District',
    130204: 'Guye District',
    130205: 'Kaiping District',
    130207: 'Funnam-gu',
    130208: 'Abundant District',
    130209: 'Caofeidian District',
    130224: 'Luannan County',
    130225: 'Leting County',
    130227: 'Qianxi County',
    130229: 'Yutian County',
    130281: 'Zunhua',
    130283: 'Qian an',
    130284: 'Luanzhou',
    130302: 'Harbour Area',
    130303: 'Shanhaiguan District',
    130304: 'Beidaihe District',
    130306: 'Funing District',
    130321: 'Qinglong Manchu Autonomous County',
    130322: 'Changli County',
    130324: 'Lulong County',
    130390: 'Economic and Technological Development Zone',
    130402: 'Handan Mountain',
    130403: 'Congtai District',
    130404: 'Fuxing District',
    130406: 'Fengfeng Mining Area',
    130407: 'Feixiang District',
    130408: 'Yongnian District',
    130423: 'Linzhang County',
    130424: 'Cheng an County',
    130425: 'Daimyo',
    130426: 'Shixian County',
    130427: 'Cixian',
    130430: 'Qiu County',
    130431: 'Jize County',
    130432: 'Guangping County',
    130433: 'Guantao County',
    130434: 'Wei County',
    130435: 'Quzhou County',
    130481: 'Wu an',
    130502: 'Bridge East District',
    130503: 'Bridge West District',
    130521: 'Xingtai County',
    130522: 'Lincheng County',
    130523: 'Neiqiu County',
    130524: 'Baixiang County',
    130525: 'Longyao County',
    130526: 'Ren County',
    130527: 'Nanhe County',
    130528: 'Ningjin County',
    130529: 'Julu County',
    130530: 'Xinhe County',
    130531: 'Guangzong County',
    130532: 'Pingxiang County',
    130533: 'Wei County',
    130534: 'Qinghe County',
    130535: 'Linxi County',
    130581: 'Nangong',
    130582: 'Shahe',
    130602: 'Jingxiu District',
    130606: 'Lotus Pond District',
    130607: 'Full Town',
    130608: 'Qingyuan District',
    130609: 'Xushui District',
    130623: 'Laishui County',
    130624: 'Fuping County',
    130626: 'Dingxing County',
    130627: 'Tang County',
    130628: 'Gaoyang County',
    130629: 'Rongcheng County',
    130630: 'Laiyuan County',
    130631: 'Wangdu County',
    130632: 'Anxin County',
    130633: 'Yi County',
    130634: 'Quyang County',
    130635: 'Li County',
    130636: 'Shunping County',
    130637: 'Hirono Prefecture',
    130638: 'Xiong County',
    130681: 'Zhuozhou',
    130682: 'Dingzhou',
    130683: 'Anguk',
    130684: 'Gaobeidian',
    130702: 'Bridge East District',
    130703: 'Bridge West District',
    130705: 'Xuanhua District',
    130706: 'Lower Garden Area',
    130708: 'Wanquan District',
    130709: 'Chongli District',
    130722: 'Zhangbei County',
    130723: 'Kangbao County',
    130724: 'Guyuan County',
    130725: 'Shangyi County',
    130726: 'Wei County',
    130727: 'Yangyuan County',
    130728: 'Huai an County',
    130730: 'Huailai County',
    130731: 'Zhuolu County',
    130732: 'Akagi Prefecture',
    130802: 'Shuangqiao District',
    130803: 'Shuangluan District',
    130804: 'Eagle Hand Camp Mine',
    130821: 'Chengde County',
    130822: 'Xinglong County',
    130824: 'Luanping County',
    130825: 'Longhua County',
    130826: 'Fengning Manchu Autonomous County',
    130827: 'Kuancheng Manchu Autonomous County',
    130828: 'Waichang Manchu Mongolian Autonomous County',
    130881: 'Hiraizumi',
    130902: 'Xinhua District',
    130903: 'Canal Zone',
    130921: 'Cang County',
    130922: 'Qingxian',
    130923: 'Dongguang County',
    130924: 'Haixing County',
    130925: 'Yanshan County',
    130926: 'Suning County',
    130927: 'Nanpi County',
    130928: 'Wuqiao County',
    130929: 'Xianxian',
    130930: 'Mengcun Hui Autonomous County',
    130981: 'Botou',
    130982: 'Renqiu',
    130983: 'Huanghua',
    130984: 'Hejian',
    131002: 'Anci-ku',
    131003: 'Quang Yang District',
    131022: 'Gu an County',
    131023: 'Yongqing County',
    131024: 'Xianghe County',
    131025: 'Dacheng County',
    131026: 'Wen an County',
    131028: 'Dachang Hui Autonomous County',
    131081: 'Bazhou',
    131082: 'Three Rivers',
    131090: 'Development Zone',
    131102: 'Taocheng District',
    131103: 'Jizhou District',
    131121: 'Zaoqiang County',
    131122: 'Wuyi County',
    131123: 'Wuqiang County',
    131124: 'Raoyang County',
    131125: 'Anping County',
    131126: 'Gucheng County',
    131127: 'Jing County',
    131128: 'Fucheng County',
    131182: 'Shenzhou',
    140105: 'Small shop area',
    140106: 'Yingze District',
    140107: 'Xinghualing District',
    140108: 'Sharp Lawn Area',
    140109: 'Wanbailin',
    140110: 'Jinyuan District',
    140121: 'Qingxu County',
    140122: 'Yangqu County',
    140123: 'Lou Fu County',
    140181: 'Gujiao',
    140212: 'Xinrong District',
    140213: 'Pingcheng District',
    140214: 'Yungang District',
    140215: 'Yunzhou District',
    140221: 'Yanggao County',
    140222: 'Tianzhen County',
    140223: 'Guangling County',
    140224: 'Lingqiu County',
    140225: 'Hunyuan County',
    140226: 'Zuoyun County',
    140302: 'District',
    140303: 'Mines',
    140311: 'Suburban',
    140321: 'Binh Dinh County',
    140322: 'Yu County',
    140403: 'Luzhou District',
    140404: 'Shangdang District',
    140405: 'Tunliu District',
    140406: 'Lucheng District',
    140423: 'Xiangyuan County',
    140425: 'Pingshun County',
    140426: 'Licheng County',
    140427: 'Huguan County',
    140428: 'Changzi County',
    140429: 'Wuxiang County',
    140430: 'Qin County',
    140431: 'Qinyuan County',
    140502: 'District',
    140521: 'Qinshui County',
    140522: 'Yangcheng County',
    140524: 'Lingchuan County',
    140525: 'Zezhou County',
    140581: 'Gaoping',
    140602: 'Shuocheng District',
    140603: 'Pinglu District',
    140621: 'San in',
    140622: 'Ying County',
    140623: 'Youyu County',
    140681: 'Huairen',
    140702: 'Yuci District',
    140721: 'Yushe County',
    140722: 'Zuoquan County',
    140723: 'Heshun County',
    140724: 'Xiyang County',
    140725: 'Shouyang County',
    140726: 'Taigu County',
    140727: 'Qixian',
    140728: 'Pingyao County',
    140729: 'Lingshi County',
    140781: 'Jiexiu',
    140802: 'Salt Lake District',
    140821: 'Linxian County',
    140822: 'Vang Vieng',
    140823: 'Wenxi County',
    140824: 'Jiyama County',
    140825: 'Xinjie County',
    140826: 'Xian County',
    140827: 'Yuanqu County',
    140828: 'Xia County',
    140829: 'Pinglu County',
    140830: 'Ruicheng County',
    140881: 'Yongji',
    140882: 'Kawazu',
    140902: 'Xinfu District',
    140921: 'Dingxiang County',
    140922: 'Wutai County',
    140923: 'Dai County',
    140924: 'Fanzhi County',
    140925: 'Ningwu County',
    140926: 'Jingle County',
    140927: 'Kamike Prefecture',
    140928: 'Wuzhai County',
    140929: 'Ganglan County',
    140930: 'Hequ County',
    140931: 'Baode County',
    140932: 'Biguan County',
    140981: 'Yuanping',
    141002: 'Yaodu District',
    141021: 'Quwo County',
    141022: 'Yicheng County',
    141023: 'Xiangfen County',
    141024: 'Hongdong County',
    141025: 'Guxian',
    141026: 'Anze County',
    141027: 'Fushan County',
    141028: 'Jixian',
    141029: 'Xiangning County',
    141030: 'Daning County',
    141031: 'Xi County',
    141032: 'Yonghe County',
    141033: 'Puxian',
    141034: 'Fenxi County',
    141081: 'Houma',
    141082: 'Huozhou',
    141102: 'Off-Stone District',
    141121: 'Wenshui County',
    141122: 'Jiaocheng County',
    141123: 'Xing County',
    141124: 'Linxian',
    141125: 'Liulin County',
    141126: 'Shilou County',
    141127: 'Arashi County',
    141128: 'Fangshan County',
    141129: 'Zhongyang County',
    141130: 'Jiaokou County',
    141181: 'Xiaoyi',
    141182: 'Fenyang',
    150102: 'New Town',
    150103: 'Muslim District',
    150104: 'Yuquan District',
    150105: 'Saihan District',
    150121: 'Tumut Left Banner',
    150122: 'Tocto County',
    150123: 'And Lingle County',
    150124: 'Qingshuihe County',
    150125: 'Wuchuan County',
    150202: 'Donghe District',
    150203: 'Kundulun District',
    150204: 'Qingshan District',
    150205: 'Shiqiao District',
    150206: 'Baiyun Obo Mining Area',
    150207: 'Jiuyuan District',
    150221: 'Right Banner of Tumut',
    150222: 'Guyang County',
    150223: 'Dalhan Maomingan Union Flag',
    150302: 'Haibo Bay Area',
    150303: 'Hainan District',
    150304: 'Wuda District',
    150402: 'Red Mountain',
    150403: 'Yuanbao Mountain',
    150404: 'Songshan District',
    150421: 'Arukorqin Banner',
    150422: 'Bahrain Left Flag',
    150423: 'Bahrain Right Flag',
    150424: 'Linsey County',
    150425: 'Keshketeng Banner',
    150426: 'Ongniute Banner',
    150428: 'Karaqin Banner',
    150429: 'Ningcheng County',
    150430: 'Ao Han Banner',
    150502: 'Horqin District',
    150521: 'Horqin Left Wing Central Banner',
    150522: 'Horqin Left Wing Rear Banner',
    150523: 'Kailu County',
    150524: 'Cullen Banner',
    150525: 'Naiman Flag',
    150526: 'Flag of Zarut',
    150581: 'Holinguole',
    150602: 'Dongsheng District',
    150603: 'Kangbashi District',
    150621: 'Dalat Flag',
    150622: 'Jungar Banner',
    150623: 'Otok Front Banner',
    150624: 'Otok Flag',
    150625: 'Hangjin Banner',
    150626: 'Wuxin Banner',
    150627: 'Ijin Holo Banner',
    150702: 'Hailar District',
    150703: 'Zazhanoor District',
    150721: 'Ah Rong Banner',
    150722: 'Moridawa Daur Autonomous Flag',
    150723: 'Olunchun Autonomous Flag',
    150724: 'Evenki Autonomous Flag',
    150725: 'Chen Bal Tiger Banner',
    150726: 'New Bal Tiger Left Banner',
    150727: 'New Bal Tiger Right Banner',
    150781: 'Manzhouli',
    150782: 'Yakeshi',
    150783: 'Zarantun',
    150784: 'Erguna',
    150785: 'Negawa',
    150802: 'Riverfront District',
    150821: 'Gohara County',
    150822: 'Dengkou County',
    150823: 'Urat Front Banner',
    150824: 'Urat Middle Flag',
    150825: 'Urat Back Banner',
    150826: 'Hangjin Houqi',
    150902: 'Jining District',
    150921: 'Zhuozi County',
    150922: 'Huade County',
    150923: 'Shangdu County',
    150924: 'Xinghe County',
    150925: 'Liangcheng County',
    150926: 'Chahar Right Wing Front Banner',
    150927: 'Chahar Right Wing Central Banner',
    150928: 'Chahar Right Wing Rear Banner',
    150929: 'Four Sons King Banner',
    150981: 'Fengzhen',
    152201: 'Ulanhot',
    152202: 'Arshan',
    152221: 'Horqin Right Wing Front Banner',
    152222: 'Horqin Right Wing Central Banner',
    152223: 'Zarao Special Flag',
    152224: 'Tuquan County',
    152501: 'Erenhot',
    152502: 'Xilinhot',
    152522: 'Abaga Banner',
    152523: 'Sunit Left Banner',
    152524: 'Sunit Right Banner',
    152525: 'East Wuzhu Muqin Banner',
    152526: 'Xiwuzhu Muqin Banner',
    152527: 'Banner of the Taibao Temple',
    152528: 'Yellow flag',
    152529: 'White flag',
    152530: 'Blue Flag',
    152531: 'Duolun County',
    152921: 'Alashan Left Banner',
    152922: 'Alashan Right Banner',
    152923: 'Ejina Banner',
    210102: 'Zone of Peace',
    210103: 'Shenhe District',
    210104: 'Grand East',
    210105: 'Huanggu District',
    210106: 'Tiexi District',
    210111: 'Sujiatun District',
    210112: 'Hunnan District',
    210113: 'Shenbei New District',
    210114: 'Yuhong District',
    210115: 'Liaozhong District',
    210123: 'Kangping County',
    210124: 'Faku County',
    210181: 'Xinmin',
    210190: 'Economic and Technological Development Zone',
    210202: 'Zhongshan District',
    210203: 'Xigang District',
    210204: 'Shahekou District',
    210211: 'Ganjingzi District',
    210212: 'Lushunkou District',
    210213: 'Jinzhou District',
    210214: 'Pulandian District',
    210224: 'Changhai County',
    210281: 'Wafangdian',
    210283: 'Zhuanghe',
    210302: 'Tiedong District',
    210303: 'Tiexi District',
    210304: 'Tateyama',
    210311: 'A Thousand Mountain Districts',
    210321: 'Tai an County',
    210323: 'Xiuyan Manchu Autonomous County',
    210381: 'Sea',
    210390: 'High-tech Zone',
    210402: 'Xinfu District',
    210403: 'Dongzhou District',
    210404: 'Wanghua District',
    210411: 'Shuncheng District',
    210421: 'Fushun County',
    210422: 'Xinbin Manchu Autonomous County',
    210423: 'Qingyuan Manchu Autonomous County',
    210502: 'Pingshan District',
    210503: 'Xihu District',
    210504: 'Mingshan District',
    210505: 'Southern Finland',
    210521: 'Benxi Manchu Autonomous County',
    210522: 'Huanren Manchu Autonomous County',
    210602: 'Yuanbao District',
    210603: 'Revitalization Zone',
    210604: 'Zhen an District',
    210624: 'Kuandian Manchu Autonomous County',
    210681: 'Donggang',
    210682: 'Fengcheng',
    210702: 'Ghouta District',
    210703: 'Linghe District',
    210711: 'Taihe District',
    210726: 'Montenegro',
    210727: 'Yixian',
    210781: 'Linghai',
    210782: 'Beizhen',
    210793: 'Economic and Technological Development Zone',
    210802: 'Station Front Area',
    210803: 'West Downtown',
    210804: 'Bayuquan Area',
    210811: 'Old Border District',
    210881: 'Gaizhou',
    210882: 'Oishibashi',
    210902: 'Haizhou District',
    210903: 'Shinkyu District',
    210904: 'Taiping District',
    210905: 'Qinghemen District',
    210911: 'Hoho District',
    210921: 'Fuxin Mongol Autonomous County',
    210922: 'Zhangwu County',
    211002: 'Baita District',
    211003: 'Wensheng District',
    211004: 'Majestic District',
    211005: 'Gongchangling District',
    211011: 'Prince River District',
    211021: 'Liaoyang County',
    211081: 'Lighthouse',
    211102: 'Shuangtaizi District',
    211103: 'Xinglongtai District',
    211104: 'Dawa District',
    211122: 'Panshan County',
    211202: 'Yinzhou District',
    211204: 'Qinghe District',
    211221: 'Tieling County',
    211223: 'Xifeng County',
    211224: 'Changtu County',
    211281: 'Mobilization Mountain',
    211282: 'Kaiwon',
    211302: 'Twin Towers',
    211303: 'Longcheng District',
    211321: 'Chaoyang County',
    211322: 'Jianping County',
    211324: 'Karaqin Left-wing Mongolian Autonomous County',
    211381: 'North Ticket',
    211382: 'Lingyuan',
    211402: 'Lian Shan District',
    211403: 'Longgang District',
    211404: 'South Ticket Area',
    211421: 'Suizhong County',
    211422: 'Jianchang County',
    211481: 'Xingcheng',
    220102: 'Nanguan District',
    220103: 'Wide Town',
    220104: 'Chaoyang District',
    220105: 'Erdao District',
    220106: 'Green Park',
    220112: 'Shuangyang District',
    220113: 'Jiutai District',
    220122: 'Nong an County',
    220182: 'Elm',
    220183: 'Dehui',
    220192: 'Economic and Technological Development Zone',
    220202: 'Changyi District',
    220203: 'Longtan District',
    220204: 'Boat Camp Area',
    220211: 'Plump Zone',
    220221: 'Yongji County',
    220281: 'Jiaohe',
    220282: 'Huadian',
    220283: 'Shulan',
    220284: 'Rock',
    220302: 'Tiexi District',
    220303: 'Tiedong District',
    220322: 'Lishu County',
    220323: 'Yitong Manchu Autonomous County',
    220381: 'Princess Ridge',
    220382: 'Shuangliao',
    220402: 'Longshan District',
    220403: 'Xi an District',
    220421: 'Dongfeng County',
    220422: 'Dongliao County',
    220502: 'Dongchang District',
    220503: 'Erdaojiang District',
    220521: 'Tonghua County',
    220523: 'Huinan County',
    220524: 'Willow River County',
    220581: 'Meihekou',
    220582: 'Ji an',
    220602: 'Hunjiang District',
    220605: 'Jiangyuan District',
    220621: 'Fusong County',
    220622: 'Jingyu County',
    220623: 'Changbai Korean Autonomous County',
    220681: 'Linjiang',
    220702: 'Ningjiang District',
    220721: 'Former Gol Rus Mongol Autonomous County',
    220722: 'Changling County',
    220723: 'Qian an County',
    220781: 'Buyeo',
    220802: 'Taobei District',
    220821: 'Zhenzhao County',
    220822: 'Tongyu County',
    220881: 'Taonan',
    220882: 'Daan',
    222401: 'Yanji',
    222402: 'Tumen',
    222403: 'Dunhua',
    222404: 'Hunchun',
    222405: 'Longjing',
    222406: 'Helong',
    222424: 'Wang Qing County',
    222426: 'Antu County',
    230102: 'Daoli District',
    230103: 'Nangang District',
    230104: 'Daowai District',
    230108: 'Bungalow Area',
    230109: 'Songbei District',
    230110: 'Xiangfang District',
    230111: 'Hulan District',
    230112: 'Acheng District',
    230113: 'Twin Cities',
    230123: 'Yilang County',
    230124: 'Fangzheng County',
    230125: 'Penn County',
    230126: 'Bayan County',
    230127: 'Magnolia County',
    230128: 'Tonghe County',
    230129: 'Yanshou County',
    230183: 'Shangzhi',
    230184: 'Wuchang',
    230202: 'Lonza District',
    230203: 'Jianhua District',
    230204: 'Iron Front Zone',
    230205: 'Aung Aung Creek District',
    230206: 'Fularji District',
    230207: 'Nianzi Mountain',
    230208: 'Meris Daur ethnic area',
    230221: 'Longjiang County',
    230223: 'Yi an County',
    230224: 'Tailai County',
    230225: 'Gannan County',
    230227: 'Fufu County',
    230229: 'Keshan County',
    230230: 'Kedong County',
    230231: 'Baiquan County',
    230281: 'City of the River Neuve',
    230302: 'Chicken Crown District',
    230303: 'Hengshan District',
    230304: 'Didao District',
    230305: 'Pear Tree District',
    230306: 'Chengzihe District',
    230307: 'Mashan District',
    230321: 'Jidong County',
    230381: 'Hulin',
    230382: 'Mishan',
    230402: 'Xiangyang District',
    230403: 'Industrial and agricultural areas',
    230404: 'Nanshan District',
    230405: 'Hung Yen District',
    230406: 'Dongshan District',
    230407: 'Xingshan District',
    230421: 'Luobei County',
    230422: 'Suibin County',
    230502: 'Jianshan District',
    230503: 'Lingdong District',
    230505: 'Sifangtai District',
    230506: 'Baoshan District',
    230521: 'Jixian County',
    230522: 'Friendship County',
    230523: 'Baoqing County',
    230524: 'Raohe County',
    230602: 'Saltu District',
    230603: 'Longfeng District',
    230604: 'Jean Hulu District',
    230605: 'Honggang District',
    230606: 'Datong District',
    230621: 'Zhaozhou County',
    230622: 'Zhaoyuan County',
    230623: 'Lindian County',
    230624: 'Durbot Mongol Autonomous County',
    230702: 'Yichun District',
    230703: 'Nancha District',
    230704: 'Friendly Zone',
    230705: 'Xilin District',
    230706: 'Cuifeng District',
    230707: 'Xinqing District',
    230708: 'My Creek District',
    230709: 'Jinshantun District',
    230710: 'Five Camps',
    230711: 'Uma River District',
    230712: 'Tangwanghe District',
    230713: 'Bingling District',
    230714: 'Uyiling District',
    230715: 'Red Star District',
    230716: 'Shangganling District',
    230722: 'Jiayin County',
    230781: 'Tieli',
    230803: 'Xiangyang District',
    230804: 'Forward Zone',
    230805: 'Dongfeng District',
    230811: 'Suburbs',
    230822: 'Huannan County',
    230826: 'Hua Chuan County',
    230828: 'Yubara',
    230881: 'Tongjiang',
    230882: 'Fujin',
    230883: 'Fuyuan',
    230902: 'Xinxing District',
    230903: 'Taoshan District',
    230904: 'Eggplant River District',
    230921: 'Burley County',
    231002: 'Dong an District',
    231003: 'Yangming District',
    231004: 'Aimin District',
    231005: 'Xi an District',
    231025: 'Linkou County',
    231081: 'Suifenhe',
    231083: 'Hailin',
    231084: 'Ning an',
    231085: 'Mureng',
    231086: 'Dongning',
    231102: 'Aihui District',
    231121: 'Nenjiang County',
    231123: 'Xunke County',
    231124: 'Sun Wu County',
    231181: 'Bei an',
    231182: 'Wudalianchi',
    231202: 'North Forest District',
    231221: 'Wangkui County',
    231222: 'Lanxi County',
    231223: 'Qinggang Prefecture',
    231224: 'Qing an County',
    231225: 'Mingshui County',
    231226: 'Suileng County',
    231281: 'Anda',
    231282: 'Zhaodong',
    231283: 'Helen',
    232701: 'Mohe',
    232721: 'Huma County',
    232722: 'Tahe County',
    232790: 'Pine Ridge District',
    232791: 'Huzhong District',
    232792: 'Gagedachi District',
    232793: 'New Forest Area',
    310101: 'Huangpu District',
    310104: 'Xuhui District',
    310105: 'Changning District',
    310106: 'Jing an District',
    310107: 'Putuo District',
    310109: 'Hongkou District',
    310110: 'Yangpu District',
    310112: 'Minhang District',
    310113: 'Baoshan District',
    310114: 'Jiading District',
    310115: 'Pudong New Area',
    310116: 'Jinshan District',
    310117: 'Songjiang District',
    310118: 'Qingpu District',
    310120: 'Fengxian District',
    310151: 'Chongming District',
    320102: 'Xuanwu District',
    320104: 'Qinhuai District',
    320105: 'Jianye District',
    320106: 'Gulou District',
    320111: 'Pukou District',
    320113: 'Qixia District',
    320114: 'Yuhuatai District',
    320115: 'Jiangning District',
    320116: 'Liuhe District',
    320117: 'Lishui District',
    320118: 'Gaochun District',
    320205: 'Xishan District',
    320206: 'Huishan District',
    320211: 'Lakefront',
    320213: 'Liangxi District',
    320214: 'Xinwu District',
    320281: 'Jiangyin',
    320282: 'Yixing',
    320302: 'Gulou District',
    320303: 'Yunlong District',
    320305: 'Jiawang District',
    320311: 'Quanshan District',
    320312: 'Tongshan Mountain',
    320321: 'Fengxian',
    320322: 'Peixian',
    320324: 'Suining County',
    320381: 'Xinyi',
    320382: 'Pizhou',
    320391: 'Industrial Park',
    320402: 'Tinian District',
    320404: 'Bell Tower District',
    320411: 'New North District',
    320412: 'Wujin District',
    320413: 'Jintan District',
    320481: 'Liyang',
    320505: 'Tiger Hill District',
    320506: 'Wuzhong District',
    320507: 'Xiangcheng District',
    320508: 'Gusu District',
    320509: 'Wujiang District',
    320581: 'Changshu',
    320582: 'Zhangjiagang',
    320583: 'Kunshan',
    320585: 'Taicang',
    320590: 'Industrial Park',
    320591: 'High-tech Zone',
    320602: 'Chongchuan District',
    320611: 'Port Gate Area',
    320612: 'Tongzhou District',
    320623: 'Rudong County',
    320681: 'Qidong',
    320682: 'Rugao',
    320684: 'Haimen',
    320685: 'Hoi An',
    320691: 'High-tech Zone',
    320703: 'Lianyun District',
    320706: 'Haizhou District',
    320707: 'Ganyu District',
    320722: 'Donghai County',
    320723: 'Guanyun County',
    320724: 'Guannan County',
    320803: 'Huai an District',
    320804: 'Huaiyin District',
    320812: 'Qingjiangpu District',
    320813: 'Hongze District',
    320826: 'Lianshui County',
    320830: 'Yuxian County',
    320831: 'Jinhu County',
    320890: 'Economic Development Zone',
    320902: 'Tinghu District',
    320903: 'Yandu District',
    320904: 'Dafeng District',
    320921: 'Xiangshui County',
    320922: 'Binhai County',
    320923: 'Funing County',
    320924: 'Sheyang County',
    320925: 'Jianhu County',
    320981: 'Dongtai',
    321002: 'Guangling District',
    321003: 'Hanjiang District',
    321012: 'Jiangdu District',
    321023: 'Baoying County',
    321081: 'Yizheng',
    321084: 'Gaoyou',
    321090: 'Economic Development Zone',
    321102: 'Jingkou District',
    321111: 'Runzhou District',
    321112: 'Dantu District',
    321181: 'Danyang',
    321182: 'Yangzhong',
    321183: 'Jurong',
    321202: 'Hailing District',
    321203: 'High Harbor District',
    321204: 'Jiangyan District',
    321281: 'Xinghua',
    321282: 'Jingjiang',
    321283: 'Taixing',
    321302: 'Sucheng District',
    321311: 'Suyu District',
    321322: 'Shuyang County',
    321323: 'Siyang County',
    321324: 'Sihong County',
    330102: 'Uptown',
    330103: 'Lower Town',
    330104: 'Jianggan District',
    330105: 'Gongshu District',
    330106: 'West Lake District',
    330108: 'Riverside District',
    330109: 'Xiaoshan District',
    330110: 'Yuhang District',
    330111: 'Fuyang District',
    330112: 'Lin an District',
    330122: 'Tonglu County',
    330127: 'Chun an County',
    330182: 'Jiande',
    330203: 'Haishu District',
    330205: 'Jiangbei District',
    330206: 'Beilun District',
    330211: 'Zhenhai District',
    330212: 'Yinzhou District',
    330213: 'Fenghua District',
    330225: 'Xiangshan County',
    330226: 'Ninghai County',
    330281: 'Yuyao',
    330282: 'Cixi',
    330302: 'Lucheng District',
    330303: 'Longwan District',
    330304: 'Ouhai District',
    330305: 'Dongtou District',
    330324: 'Yongjia County',
    330326: 'Binh Duong',
    330327: 'Cangnan County',
    330328: 'Wencheng County',
    330329: 'Taishun County',
    330381: 'Ryan',
    330382: 'Yueqing',
    330402: 'South Lake District',
    330411: 'Xiuzhou District',
    330421: 'Jiashan County',
    330424: 'Haiyan County',
    330481: 'Haining',
    330482: 'Pinghu',
    330483: 'Tongxiang',
    330502: 'Wuxing District',
    330503: 'Nanxun District',
    330521: 'Deqing County',
    330522: 'Changxing County',
    330523: 'Anji County',
    330602: 'Yuecheng District',
    330603: 'Keqiao District',
    330604: 'Shangyu District',
    330624: 'Xinchang County',
    330681: 'Zhuji',
    330683: 'Shengzhou',
    330702: 'Wucheng District',
    330703: 'Kim Dong District',
    330723: 'Wuyi County',
    330726: 'Pujiang County',
    330727: 'Pan an',
    330781: 'Lanxi',
    330782: 'Yiwu',
    330783: 'Dongyang',
    330784: 'Yongkang',
    330802: 'Kecheng District',
    330803: 'Qujiang District',
    330822: 'Changshan County',
    330824: 'Kaihua County',
    330825: 'Longyou County',
    330881: 'Jiangshan',
    330902: 'Dinghai District',
    330903: 'Putuo District',
    330921: 'Daishan County',
    330922: 'Shengsi County',
    331002: 'Jiaojiang District',
    331003: 'Huangyan District',
    331004: 'Luqiao District',
    331022: 'Sanmen County',
    331023: 'Tiantai County',
    331024: 'Xianju County',
    331081: 'Wenling',
    331082: 'Linhai',
    331083: 'Yuhuan',
    331102: 'Liandu District',
    331121: 'Qingtian County',
    331122: 'Jinyun County',
    331123: 'Suichang County',
    331124: 'Songyang County',
    331125: 'Yunhe County',
    331126: 'Qingyuan County',
    331127: 'Jingning She Autonomous County',
    331181: 'Longquan',
    340102: 'Yaohai District',
    340103: 'Luyang District',
    340104: 'Shushan District',
    340111: 'Baohe District',
    340121: 'Changfeng County',
    340122: 'Feidong County',
    340123: 'Feixi County',
    340124: 'Lujiang County',
    340181: 'Chaohu',
    340190: 'Hi-Tech Development Zone',
    340191: 'Economic and Technological Development Zone',
    340202: 'Mirror Lake District',
    340203: 'Yijiang District',
    340207: 'Jiujiang District',
    340208: 'Three Mountain Districts',
    340221: 'Wuhu County',
    340222: 'Fanchang County',
    340223: 'Nanling County',
    340225: 'Wuwei County',
    340302: 'Longzi Lake District',
    340303: 'Mussel Mountain',
    340304: 'Yuhui District',
    340311: 'Huai Shang District',
    340321: 'Huaiyuan County',
    340322: 'Wuhe County',
    340323: 'Guzhen County',
    340402: 'Odori District',
    340403: 'Tianjiaan District',
    340404: 'Xiejia District',
    340405: 'Hachigong Mountain',
    340406: 'Panji District',
    340421: 'Fengtai County',
    340422: 'Shou County',
    340503: 'Huashan District',
    340504: 'Rain Mountain',
    340506: 'Bowang District',
    340521: 'Dangtu County',
    340522: 'Hanshan County',
    340523: 'He County',
    340602: 'Duji District',
    340603: 'Xiangshan District',
    340604: 'Lieshan District',
    340621: 'Suixi County',
    340705: 'Tongguan District',
    340706: 'Nghe An District',
    340711: 'Suburban',
    340722: 'Fanyang County',
    340802: 'Yingjiang District',
    340803: 'Daguan District',
    340811: 'Yixiu District',
    340822: 'Huaining County',
    340824: 'Qianshan County',
    340825: 'Taihu County',
    340826: 'Susong County',
    340827: 'Wangjiang County',
    340828: 'Yuexi County',
    340881: 'Tongcheng',
    341002: 'Tunxi District',
    341003: 'Huangshan District',
    341004: 'Huizhou District',
    341021: 'Xi County',
    341022: 'Xiuning County',
    341023: 'Yixian',
    341024: 'Qimen County',
    341102: 'Langya District',
    341103: 'Nanzhao District',
    341122: 'Laian County',
    341124: 'Quanjiao County',
    341125: 'Dingyuan County',
    341126: 'Fengyang County',
    341181: 'Tianchang',
    341182: 'Mingguang',
    341202: 'Yingzhou District',
    341203: 'Yingdong District',
    341204: 'Yingquan District',
    341221: 'Linquan County',
    341222: 'Taihe County',
    341225: 'Funan County',
    341226: 'Yingshang County',
    341282: 'Jieshou',
    341302: 'Waiqiao District',
    341321: 'Dangshan County',
    341322: 'Xiao County',
    341323: 'Lingbi County',
    341324: 'Si County',
    341390: 'Economic Development Zone',
    341502: 'Jin an District',
    341503: 'Yu an District',
    341504: 'Leaf Gathering Area',
    341522: 'Huoqiu County',
    341523: 'Shucheng County',
    341524: 'Jinzhai County',
    341525: 'Huoshan County',
    341602: 'Chengcheng District',
    341621: 'Wuoyang County',
    341622: 'Mengcheng County',
    341623: 'Lixin County',
    341702: 'Guichi District',
    341721: 'Dongzhi County',
    341722: 'Shitai County',
    341723: 'Qingyang County',
    341802: 'Xuanzhou District',
    341821: 'Langxi County',
    341822: 'Guangde County',
    341823: 'Jing County',
    341824: 'Jixi County',
    341825: 'Jingde County',
    341881: 'Ningguo',
    350102: 'Gulou District',
    350103: 'Taijiang District',
    350104: 'Cangshan District',
    350105: 'Mawei District',
    350111: 'Jin an District',
    350112: 'Changle District',
    350121: 'Minhou County',
    350122: 'Lianjiang County',
    350123: 'Luoyuan County',
    350124: 'Minqing County',
    350125: 'Yongtai County',
    350128: 'Pingtan County',
    350181: 'Fuqing',
    350203: 'Siming District',
    350205: 'Haicang District',
    350206: 'Huli District',
    350211: 'Jimei District',
    350212: 'Tong An District',
    350213: 'Xiang an District',
    350302: 'Chengxiang District',
    350303: 'Hanjiang District',
    350304: 'Licheng District',
    350305: 'Xiuyu District',
    350322: 'Xianyou County',
    350402: 'Mellie District',
    350403: 'Sanyuan Zone',
    350421: 'Mingxi County',
    350423: 'Qingliu County',
    350424: 'Ninghua County',
    350425: 'Daejeon',
    350426: 'Youxi County',
    350427: 'Sha County',
    350428: 'Jiangle County',
    350429: 'Taining County',
    350430: 'Jianning County',
    350481: 'Yong an',
    350502: 'Licheng District',
    350503: 'Fengze District',
    350504: 'Luojiang District',
    350505: 'Quangang District',
    350521: 'Hoi An County',
    350524: 'Anxi County',
    350525: 'Yongchun County',
    350526: 'Dehua County',
    350527: 'Kinmen County',
    350581: 'Shishi',
    350582: 'Jinjiang',
    350583: 'Nan an',
    350602: 'Wucheng District',
    350603: 'Longwen District',
    350622: 'Yunxiao County',
    350623: 'Zhangpu County',
    350624: 'Zhao an County',
    350625: 'Changtai County',
    350626: 'Dongshan County',
    350627: 'Nanjing County',
    350628: 'Heiwa County',
    350629: 'Hua an County',
    350681: 'Longhai',
    350702: 'Yanping District',
    350703: 'Jianyang District',
    350721: 'Shunchang County',
    350722: 'Pucheng County',
    350723: 'Glossy County',
    350724: 'Songxi County',
    350725: 'Zhenghe County',
    350781: 'Shaowu',
    350782: 'Wuyishan',
    350783: 'Jian ou',
    350802: 'Silla District',
    350803: 'Yongding District',
    350821: 'Changting County',
    350823: 'Shanghang County',
    350824: 'Wuping County',
    350825: 'Liancheng County',
    350881: 'Zhangping',
    350902: 'Jiaocheng District',
    350921: 'Xiapu County',
    350922: 'Gutian County',
    350923: 'Pingnan County',
    350924: 'Shouning County',
    350925: 'Zhouning County',
    350926: 'Zherong County',
    350981: 'Fu An',
    350982: 'Fuding',
    360102: 'East Lake District',
    360103: 'West Lake District',
    360104: 'Qingyun Spectrum Area',
    360105: 'Bay Mile District',
    360111: 'Qingshan Lake District',
    360112: 'New District',
    360121: 'Nanchang County',
    360123: 'Anyi County',
    360124: 'Jinxian County',
    360190: 'Economic and Technological Development Zone',
    360192: 'High-tech Zone',
    360202: 'Changjiang District',
    360203: 'Zhushan District',
    360222: 'Floating Liang County',
    360281: 'Leping',
    360302: 'Anyuan District',
    360313: 'Xiangdong District',
    360321: 'Lotus County',
    360322: 'Shangli Prefecture',
    360323: 'Luxi County',
    360402: 'Lian Xi District',
    360403: 'Xunyang District',
    360404: 'Chaisang District',
    360423: 'Wuning County',
    360424: 'Xiushui County',
    360425: 'Yongxiu County',
    360426: 'De an County',
    360428: 'Duchang County',
    360429: 'Hukou County',
    360430: 'Pengze County',
    360481: 'Ruichang',
    360482: 'Komsomolsk',
    360483: 'Lushan',
    360490: 'Economic and Technological Development Zone',
    360502: 'Yushui District',
    360521: 'Fanyi County',
    360602: 'Moon Lake District',
    360603: 'Yujiang District',
    360681: 'Guixi',
    360702: 'Zhanggong District',
    360703: 'Nankang District',
    360704: 'Ganxian District',
    360722: 'Xinfeng County',
    360723: 'Dayu County',
    360724: 'Upper Jude County',
    360725: 'Chongyi County',
    360726: 'Anyuan County',
    360727: 'Longnan County',
    360728: 'Dingnan County',
    360729: 'Jeonnam',
    360730: 'Ningdu County',
    360731: 'Yudu County',
    360732: 'Xingguo County',
    360733: 'Huichang County',
    360734: 'Xunwu County',
    360735: 'Shicheng County',
    360781: 'Ruijin',
    360802: 'Jizhou District',
    360803: 'Qingyuan District',
    360821: 'Ji an County',
    360822: 'Jishui County',
    360823: 'Xiajiang County',
    360824: 'Shinkan',
    360825: 'Yongfeng County',
    360826: 'Taihe County',
    360827: 'Suichuan County',
    360828: 'Wan an County',
    360829: 'Anfu County',
    360830: 'Yongxin County',
    360881: 'Jinggangshan',
    360902: 'Yuanzhou District',
    360921: 'Fengxin County',
    360922: 'Wanzai County',
    360923: 'Shanggao County',
    360924: 'Yifeng County',
    360925: 'Jing an County',
    360926: 'Tonggu County',
    360981: 'Fengcheng',
    360982: 'Zhangshu',
    360983: 'Gao an',
    361002: 'Linchuan District',
    361003: 'Dongxiang District',
    361021: 'Nancheng County',
    361022: 'Lichuan County',
    361023: 'Nanfeng County',
    361024: 'Chongren County',
    361025: 'Le an County',
    361026: 'Yihuang County',
    361027: 'Jinxi County',
    361028: 'Zixi County',
    361030: 'Guangchang County',
    361102: 'Shinshu-ku',
    361103: 'Guangfeng District',
    361121: 'Shangrao County',
    361123: 'Yushan County',
    361124: 'Lead Mountain County',
    361125: 'Hengfeng County',
    361126: 'Yiyang County',
    361127: 'Yugan County',
    361128: 'Poyang County',
    361129: 'Wannian County',
    361130: 'Wuyuan County',
    361181: 'Dexing',
    370102: 'Lixia District',
    370103: 'Shizhong District',
    370104: 'Huaiyin District',
    370105: 'Flyover District',
    370112: 'Licheng District',
    370113: 'Changqing District',
    370114: 'Zhangqiu District',
    370115: 'Jiyang District',
    370116: 'Laiwu District',
    370117: 'Steel District',
    370124: 'Pingyin County',
    370126: 'Shanghe County',
    370190: 'High-tech Zone',
    370202: 'Shinan District',
    370203: 'Shibei District',
    370211: 'Huangdao District',
    370212: 'Laoshan District',
    370213: 'Licang District',
    370214: 'Chengyang District',
    370215: 'Jimo District',
    370281: 'Jiaozhou',
    370283: 'Pingdu',
    370285: 'Lacey',
    370290: 'Development Zone',
    370302: 'Zichuan District',
    370303: 'Zhangdian District',
    370304: 'Boshan District',
    370305: 'Linzi District',
    370306: 'Zhoucun District',
    370321: 'Huantai County',
    370322: 'Gaoqing County',
    370323: 'Yiyuan County',
    370402: 'Shizhong District',
    370403: 'Xuecheng District',
    370404: 'Yicheng District',
    370405: 'Taierzhuang District',
    370406: 'Shanting District',
    370481: 'Tengzhou',
    370502: 'Dongying District',
    370503: 'Bayou District',
    370505: 'Kenli District',
    370522: 'Lijin County',
    370523: 'Guangrao County',
    370602: 'Zhifu District',
    370611: 'Fukuyama',
    370612: 'Muping District',
    370613: 'Laishan District',
    370634: 'Long Island County',
    370681: 'Longkou',
    370682: 'Laiyang',
    370683: 'Laizhou',
    370684: 'Penglai',
    370685: 'Zhaoyuan',
    370686: 'Qixia',
    370687: 'Hai Duong',
    370690: 'Development Zone',
    370702: 'Weicheng District',
    370703: 'Hanting District',
    370704: 'Fangzi District',
    370705: 'Kuiwen District',
    370724: 'Linqu County',
    370725: 'Changle County',
    370781: 'Qingzhou',
    370782: 'Zhucheng',
    370783: 'Shouguang',
    370784: 'Anchu',
    370785: 'Gaomi',
    370786: 'Changyi',
    370790: 'Development Zone',
    370791: 'High-tech Zone',
    370811: 'Rencheng District',
    370812: 'Yanzhou District',
    370826: 'Weishan County',
    370827: 'Yutai County',
    370828: 'Jinxiang County',
    370829: 'Jiaxiang County',
    370830: 'Wenshang County',
    370831: 'Surabaya',
    370832: 'Liangshan County',
    370881: 'Qufu',
    370883: 'Zoucheng',
    370890: 'High-tech Zone',
    370902: 'Taishan District',
    370911: 'Daiyue District',
    370921: 'Ningyang County',
    370923: 'Dongping County',
    370982: 'Xintai',
    370983: 'Fat',
    371002: 'Huancui District',
    371003: 'Wendeng District',
    371082: 'Rongcheng',
    371083: 'Rushan',
    371091: 'Economic and Technological Development Zone',
    371102: 'Donggang District',
    371103: 'Arashiyama District',
    371121: 'Wulian County',
    371122: 'Juxian',
    371302: 'Lanshan District',
    371311: 'Luozhuang District',
    371312: 'Hedong District',
    371321: 'Yinan County',
    371322: 'Tancheng County',
    371323: 'Yishui County',
    371324: 'Lanling County',
    371325: 'Fei County',
    371326: 'Pingyi County',
    371327: 'Junan County',
    371328: 'Mengyin County',
    371329: 'Linshu County',
    371402: 'Decheng District',
    371403: 'Lingcheng District',
    371422: 'Ningjin County',
    371423: 'Qingyun County',
    371424: 'Linyi County',
    371425: 'Qihe County',
    371426: 'Plains County',
    371427: 'Xiatsu County',
    371428: 'Wucheng County',
    371481: 'Leling',
    371482: 'Yucheng',
    371502: 'Dongchang District',
    371521: 'Yanggu County',
    371522: 'Xin County',
    371523: 'Qiping County',
    371524: 'Donga County',
    371525: 'Guan County',
    371526: 'Gaotang County',
    371581: 'Linqing',
    371602: 'Ben Thanh District',
    371603: 'Dipping zone',
    371621: 'Huimin County',
    371622: 'Yangxin County',
    371623: 'Wudi County',
    371625: 'Boxing County',
    371681: 'Zouping',
    371702: 'Peony District',
    371703: 'Dingtao District',
    371721: 'Cao County',
    371722: 'Single County',
    371723: 'Chengwu County',
    371724: 'Juye County',
    371725: 'Yuncheng County',
    371726: 'Yancheng County',
    371728: 'Dongming County',
    410102: 'Central Plains',
    410103: 'Erqi District',
    410104: 'Guancheng Hui District',
    410105: 'Jinshui District',
    410106: 'Upper Block',
    410108: 'Huiji District',
    410122: 'Zhongmu County',
    410181: 'Gongyi',
    410182: 'Xingyang',
    410183: 'Xinmi',
    410184: 'Xinzheng',
    410185: 'Dengfeng',
    410190: 'Hi-Tech Development Zone',
    410191: 'Economic and Technological Development Zone',
    410202: 'Longting District',
    410203: 'Shunhe Hui District',
    410204: 'Gulou District',
    410205: 'Yuwangtai District',
    410212: 'Xiangfu District',
    410221: 'Qixian',
    410222: 'Tongxu County',
    410223: 'Weishi County',
    410225: 'Lankao County',
    410302: 'Old Town',
    410303: 'Xigong District',
    410304: 'Shenhe Hui Ethnic Area',
    410305: 'Jianxi District',
    410306: 'Gili District',
    410311: 'Luolong District',
    410322: 'Mengjin County',
    410323: 'Xin an County',
    410324: 'Luanchuan County',
    410325: 'Song County',
    410326: 'Ruyang County',
    410327: 'Yiyang County',
    410328: 'Luoning County',
    410329: 'Ikawa County',
    410381: 'Yanshi',
    410402: 'Xinhua District',
    410403: 'Weidong District',
    410404: 'Shilong District',
    410411: 'Zhanhe District',
    410421: 'Baofeng County',
    410422: 'Ye County',
    410423: 'Lushan County',
    410425: 'Yu County',
    410481: 'Wugang',
    410482: 'Ruzhou',
    410502: 'Wenfeng District',
    410503: 'Beiguan District',
    410505: 'Yindu District',
    410506: 'Long An District',
    410522: 'Anyang County',
    410523: 'Tangyin County',
    410526: 'Slippery County',
    410527: 'Neihuang County',
    410581: 'Linzhou',
    410590: 'Development Zone',
    410602: 'Heshan District',
    410603: 'Shancheng District',
    410611: 'Kibin District',
    410621: 'Junxian',
    410622: 'Qi County',
    410702: 'Red Flag District',
    410703: 'Weibin District',
    410704: 'Fengquan District',
    410711: 'Makino Ward',
    410721: 'Xinxiang County',
    410724: 'Yujia County',
    410725: 'Yuanyang County',
    410726: 'Yanjin County',
    410727: 'Fengqiu County',
    410728: 'Changyuan County',
    410781: 'Weihui',
    410782: 'Huixian',
    410802: 'Liberated Areas',
    410803: 'Middle Station District',
    410804: 'Macun District',
    410811: 'Sanyo-gu',
    410821: 'Xiuwu County',
    410822: 'Boai County',
    410823: 'Wuchen County',
    410825: 'Wenxian',
    410882: 'Qinyang',
    410883: 'Mengzhou',
    410902: 'Hualong District',
    410922: 'Qingfeng County',
    410923: 'Nanle County',
    410926: 'Fan County',
    410927: 'Taiqian County',
    410928: 'Puyang County',
    411002: 'Weidu District',
    411003: 'Jian an District',
    411024: 'Yanling County',
    411025: 'Xiangcheng County',
    411081: 'Yuzhou',
    411082: 'Nagae',
    411102: 'Yuanhui District',
    411103: 'Yuncheng District',
    411104: 'Zhaoling District',
    411121: 'Wuyang County',
    411122: 'Linying County',
    411202: 'Lakeside',
    411203: 'Shaanzhou District',
    411221: 'Yuchi County',
    411224: 'Lushi County',
    411281: 'Yima',
    411282: 'Lingbao',
    411302: 'Wancheng District',
    411303: 'Wolong District',
    411321: 'Nanzhao County',
    411322: 'Fangcheng County',
    411323: 'Xixia County',
    411324: 'Zhenping County',
    411325: 'Neixiang County',
    411326: 'Shuchuan County',
    411327: 'Sheqi County',
    411328: 'Tanghe County',
    411329: 'Niino',
    411330: 'Tongbai County',
    411381: 'Dengzhou',
    411402: 'Liang Park',
    411403: 'Suiyang District',
    411421: 'Minquan County',
    411422: 'Suixian',
    411423: 'Ningling County',
    411424: 'Zhecheng County',
    411425: 'Yucheng County',
    411426: 'Xiayi County',
    411481: 'Eternal',
    411502: 'Yunhe District',
    411503: 'Pingqiao District',
    411521: 'Luoshan County',
    411522: 'Guangshan County',
    411523: 'New County',
    411524: 'Shangcheng County',
    411525: 'Gushi County',
    411526: 'Yichuan County',
    411527: 'Huaibin County',
    411528: 'Xi County',
    411602: 'Chuanhui District',
    411621: 'Fugou County',
    411622: 'Xihua County',
    411623: 'Shangshui County',
    411624: 'Shenqiu County',
    411625: 'Yuncheng County',
    411626: 'Huaiyang County',
    411627: 'Taikang County',
    411628: 'Luyi County',
    411681: 'Item',
    411690: 'Economic Development Zone',
    411702: 'Yicheng District',
    411721: 'Xiping County',
    411722: 'Shangcai County',
    411723: 'Pingyu County',
    411724: 'Zhengyang County',
    411725: 'Zhuoshan County',
    411726: 'Biyang County',
    411727: 'Runan',
    411728: 'Suiping County',
    411729: 'Xincai County',
    419001: 'Jiyuan',
    420102: 'Jiang an District',
    420103: 'Jianghan District',
    420104: 'Qiaokou District',
    420105: 'Hanyang-gu',
    420106: 'Wuchang District',
    420107: 'Qingshan District',
    420111: 'Hongshan District',
    420112: 'Dongxihu District',
    420113: 'Hannan District',
    420114: 'Caidian District',
    420115: 'Jiangxia District',
    420116: 'Huangpi District',
    420117: 'New Island District',
    420202: 'Yellowstone Harbor Area',
    420203: 'Cisse Mountains',
    420204: 'Shimoriku',
    420205: 'Iron Mountain',
    420222: 'Yangxin County',
    420281: 'Daye',
    420302: 'Maojian District',
    420303: 'Zhangwan District',
    420304: 'Yunyang District',
    420322: 'Yunxi County',
    420323: 'Zhushan County',
    420324: 'Zhuxi County',
    420325: 'Fang County',
    420381: 'Danjiangkou',
    420502: 'Xiling District',
    420503: 'Wujiagang District',
    420504: 'Point Military District',
    420505: 'Xiaoting District',
    420506: 'Yiling District',
    420525: 'Yuan an County',
    420526: 'Xingshan County',
    420527: 'Guigui County',
    420528: 'Changyang Tujia Autonomous County',
    420529: 'Wufeng Tujia Autonomous County',
    420581: 'Yidu',
    420582: 'Dangyang',
    420583: 'Zhijiang',
    420590: 'Economic Development Zone',
    420602: 'Xiangcheng District',
    420606: 'Fancheng District',
    420607: 'Xiangzhou District',
    420624: 'Nanzhang County',
    420625: 'Gucheng County',
    420626: 'Baokang County',
    420682: 'Old Bayou',
    420683: 'Zaoyang',
    420684: 'Yicheng',
    420702: 'Liangzihu District',
    420703: 'Huarong District',
    420704: 'Echeng District',
    420802: 'Toho-gu',
    420804: 'Knife Zone',
    420822: 'Shayang County',
    420881: 'Zhongxiang',
    420882: 'Jingshan',
    420902: 'Xiaonan District',
    420921: 'Xiaochang County',
    420922: 'Dawu County',
    420923: 'Yunmeng County',
    420981: 'Ying',
    420982: 'Anlu',
    420984: 'Hanchuan',
    421002: 'Shacheng District',
    421003: 'Jingzhou District',
    421022: 'Gong an County',
    421023: 'Jianli County',
    421024: 'Gangneung',
    421081: 'Shishou',
    421083: 'Honghu',
    421087: 'Songzi',
    421102: 'Huangzhou District',
    421121: 'Tuanfeng County',
    421122: 'Hong an County',
    421123: 'Luotian County',
    421124: 'Yingshan County',
    421125: 'Xishui County',
    421126: 'Qichun County',
    421127: 'Huangmei County',
    421181: 'Hemp',
    421182: 'Wuxue',
    421202: 'Ham an District',
    421221: 'Jiayu County',
    421222: 'Tongcheng County',
    421223: 'Chongyang County',
    421224: 'Tongshan County',
    421281: 'Chibi',
    421303: 'Zengdu District',
    421321: 'Suixian',
    421381: 'Guangshui',
    422801: 'Enshi',
    422802: 'Icheon',
    422822: 'Jianshi County',
    422823: 'Badong County',
    422825: 'Xuan en County',
    422826: 'Xianfeng County',
    422827: 'Laifeng',
    422828: 'Hefeng County',
    429004: 'Xiantao',
    429005: 'Qianjiang',
    429006: 'Tianmen',
    429021: 'Shennongjia Forest Area',
    430102: 'Seremban District',
    430103: 'Tianxin District',
    430104: 'Yuelu District',
    430105: 'Kaifu District',
    430111: 'Yuhua District',
    430112: 'Wangcheng District',
    430121: 'Changsha County',
    430181: 'Liuyang',
    430182: 'Ningxiang',
    430202: 'Hetang District',
    430203: 'Lusong District',
    430204: 'Shifeng District',
    430211: 'Tian Yuan District',
    430212: 'Shukou District',
    430223: 'Youxian',
    430224: 'Chaling County',
    430225: 'Yanling County',
    430281: 'Liling',
    430302: 'Rain Lake District',
    430304: 'Yuetang District',
    430321: 'Xiangtan County',
    430381: 'Xiangxiang',
    430382: 'Shaoshan',
    430405: 'Zhuhui District',
    430406: 'Yanfeng District',
    430407: 'Shigu District',
    430408: 'Zhengxiang District',
    430412: 'Nanyue District',
    430421: 'Hengyang County',
    430422: 'Hengnan County',
    430423: 'Hengshan County',
    430424: 'Hengdong County',
    430426: 'Qidong County',
    430481: 'Yunyang',
    430482: 'Changning',
    430502: 'Shuangqing District',
    430503: 'Daxiang District',
    430511: 'North Tower Area',
    430521: 'Shaodong County',
    430522: 'Xinshao County',
    430523: 'Shaoyang County',
    430524: 'Longhui County',
    430525: 'Dongkou County',
    430527: 'Suining County',
    430528: 'Xinning County',
    430529: 'Chengbu Miao Autonomous County',
    430581: 'Wugang',
    430602: 'Yueyanglou District',
    430603: 'Yunxi District',
    430611: 'Junshan District',
    430621: 'Yueyang County',
    430623: 'Huarong County',
    430624: 'Xiangyin County',
    430626: 'Pingjiang County',
    430681: 'Miluo',
    430682: 'Linxiang',
    430702: 'Wuling District',
    430703: 'Dingcheng District',
    430721: 'Anxiang County',
    430722: 'Hanshou County',
    430723: 'Li County',
    430724: 'Linqu County',
    430725: 'Taoyuan County',
    430726: 'Shimen County',
    430781: 'Tsushishi',
    430802: 'Yongding District',
    430811: 'Wulingyuan District',
    430821: 'Cili County',
    430822: 'Sangzhi County',
    430902: 'Ziyang District',
    430903: 'Heshan District',
    430921: 'South County',
    430922: 'Taojiang County',
    430923: 'Anhua County',
    430981: 'Yuanjiang',
    431002: 'North Lake District',
    431003: 'Suxian District',
    431021: 'Guiyang County',
    431022: 'Yizhang County',
    431023: 'Yongxing County',
    431024: 'Jiahe County',
    431025: 'Linwu County',
    431026: 'Rucheng County',
    431027: 'Guidong County',
    431028: 'Anren County',
    431081: 'Zixing',
    431102: 'Lingling District',
    431103: 'Cold Water Beach Area',
    431121: 'Qiyang County',
    431122: 'Dong an County',
    431123: 'Shuangpai County',
    431124: 'Daoxian',
    431125: 'Jiangyong County',
    431126: 'Ningyuan County',
    431127: 'Blue Mountain County',
    431128: 'Shinta',
    431129: 'Jianghua Yao Autonomous County',
    431202: 'Hecheng District',
    431221: 'Zhongfang County',
    431222: 'Yuanling County',
    431223: 'Chenxi County',
    431224: 'Hupu County',
    431225: 'Huitong County',
    431226: 'Mayang Miao Autonomous County',
    431227: 'Xinhuang Dong Autonomous County',
    431228: 'Zhijiang Dong Autonomous County',
    431229: 'Jingzhou Miao Dong Autonomous County',
    431230: 'Daodong Dong Autonomous County',
    431281: 'Hongjiang',
    431302: 'Louxing District',
    431321: 'Twin Peaks',
    431322: 'Xinhua County',
    431381: 'Cold Water River',
    431382: 'Lianyuan',
    433101: 'Jishou',
    433122: 'Luxi County',
    433123: 'Phoenix County',
    433124: 'Hanagaki County',
    433125: 'Baojing County',
    433126: 'Guzhang County',
    433127: 'Yongshun County',
    433130: 'Longshan County',
    440103: 'Liwan District',
    440104: 'Yuexiu District',
    440105: 'Haizhu District',
    440106: 'Tianhe District',
    440111: 'Baiyun District',
    440112: 'Huangpu District',
    440113: 'Panyu District',
    440114: 'Huadu District',
    440115: 'Nansha District',
    440117: 'Conghua District',
    440118: 'Zengcheng District',
    440203: 'Wujiang District',
    440204: 'Zhenjiang District',
    440205: 'Qujiang District',
    440222: 'Shixing County',
    440224: 'Renhua County',
    440229: 'Wengyuan County',
    440232: 'Ruyuan Yao Autonomous County',
    440233: 'Xinfeng County',
    440281: 'Lechang',
    440282: 'Nam Hung',
    440303: 'Luohu District',
    440304: 'Futian District',
    440305: 'Nanshan District',
    440306: 'Bao an District',
    440307: 'Longgang District',
    440308: 'Yantian District',
    440309: 'Longhua District',
    440310: 'Pingshan District',
    440311: 'Bright Zone',
    440402: 'Xiangzhou District',
    440403: 'Doumen District',
    440404: 'Golden Bay District',
    440507: 'Longhu District',
    440511: 'Jinping District',
    440512: 'Haojiang District',
    440513: 'Chaoyang District',
    440514: 'Chaonan District',
    440515: 'Chenghai District',
    440523: 'Nanao County',
    440604: 'Chancheng District',
    440605: 'Nanhai District',
    440606: 'Shunde District',
    440607: 'Sanshui District',
    440608: 'Gaoming District',
    440703: 'Pengjiang District',
    440704: 'Jianghai District',
    440705: 'New Club District',
    440781: 'Taishan',
    440783: 'Kaiping',
    440784: 'Heshan',
    440785: 'Eunpyeong',
    440802: 'Chikan District',
    440803: 'Xiashan District',
    440804: 'Potou District',
    440811: 'Mazhang District',
    440823: 'Suixi County',
    440825: 'Xuwen County',
    440881: 'Lianjiang',
    440882: 'Leizhou',
    440883: 'Wuchuan',
    440890: 'Economic and Technological Development Zone',
    440902: 'Maonan District',
    440904: 'Electric White Zone',
    440981: 'Gaozhou',
    440982: 'Huazhou',
    440983: 'Shingi',
    441202: 'Duanzhou District',
    441203: 'Dinghu District',
    441204: 'High District',
    441223: 'Quang Ninh County',
    441224: 'Huaiji County',
    441225: 'Fengkai County',
    441226: 'Deqing County',
    441284: 'Sihui',
    441302: 'Huicheng District',
    441303: 'Huiyang District',
    441322: 'Boluo County',
    441323: 'Huidong County',
    441324: 'Longmen County',
    441402: 'Meijiang District',
    441403: 'Meixian District',
    441422: 'Tai Po County',
    441423: 'Fengshun County',
    441424: 'Wuhua County',
    441426: 'Pingyuan County',
    441427: 'Jiaoling County',
    441481: 'Xingning',
    441502: 'District',
    441521: 'Haifeng County',
    441523: 'Luhe County',
    441581: 'Lufeng',
    441602: 'Source',
    441621: 'Zijin County',
    441622: 'Longchuan County',
    441623: 'Lianping County',
    441624: 'Heping County',
    441625: 'Dongyuan County',
    441702: 'Jiangcheng District',
    441704: 'Yangdong District',
    441721: 'Yangxi County',
    441781: 'Yangchun',
    441802: 'Qingcheng District',
    441803: 'Fresh Zone',
    441821: 'Fogang County',
    441823: 'Yangshan County',
    441825: 'Lianshan Zhuang Yao Autonomous County',
    441826: 'Liannan Yao Autonomous County',
    441881: 'Yingde',
    441882: 'Lianzhou',
    441901: 'Zhongtang Town',
    441903: 'Nancheng Subdistrict Office',
    441904: 'Chang an Town',
    441905: 'Dongkeng Town',
    441906: 'Zhangmutou Town',
    441907: 'Wancheng Subdistrict Office',
    441908: 'Shilong Town',
    441909: 'Qiaotou Town',
    441910: 'Wanjiang Subdistrict Office',
    441911: 'Ma Chung Town',
    441912: 'Humen Town',
    441913: 'Xiegang Town',
    441914: 'Shijie Town',
    441915: 'Chashan Town',
    441916: 'Dongcheng Subdistrict Office',
    441917: 'Hongmei Town',
    441918: 'Daojiao Town',
    441919: 'Gaocheng Town',
    441920: 'Qishi Town',
    441921: 'Fenggang Town',
    441922: 'Dalingshan Town',
    441923: 'Songshan Lake Management Committee',
    441924: 'Qingxi Town',
    441925: 'Wangniudun Town',
    441926: 'Houjie Town',
    441927: 'Changping Town',
    441928: 'Liaobu Town',
    441929: 'Shipai Town',
    441930: 'Hengli Town',
    441931: 'Tangxia Town',
    441932: 'Huangjiang Town',
    441933: 'Dalang Town',
    441934: 'Dongguan Port',
    441935: 'Dongguan Ecological Park',
    441990: 'Sha Tin Town',
    442001: 'Nantou Town',
    442002: 'Shenwan Town',
    442003: 'Dongfeng Town',
    442004: 'Wuguishan Subdistrict Office',
    442005: 'Huangpu Town',
    442006: 'Siu Lam Town',
    442007: 'Shiqi District Subdistrict Office',
    442008: 'Henglan Town',
    442009: 'Triangle Town',
    442010: 'Three Townships',
    442011: 'Port Town',
    442012: 'Shaxi Town',
    442013: 'Banfu Town',
    442015: 'Dongsheng Town',
    442016: 'Fusha Town',
    442017: 'Ren Min Town',
    442018: 'East District Subdistrict Office',
    442019: 'Torch Development Zone Street Office',
    442020: 'West Street Office',
    442021: 'South District Subdistrict Office',
    442022: 'Guzhen Town',
    442023: 'Tanzhou Town',
    442024: 'Tai Chung Town',
    442025: 'Nanlang Town',
    445102: 'Xiangqiao District',
    445103: 'Chao an District',
    445122: 'Raoping County',
    445202: 'Rongcheng District',
    445203: 'Jiedong District',
    445222: 'Jiexi County',
    445224: 'Huilai County',
    445281: 'Puning',
    445302: 'Cloud District',
    445303: 'Yun an District',
    445321: 'Xinxing County',
    445322: 'Yunan County',
    445381: 'Luoding',
    450102: 'Xingning District',
    450103: 'Qingxiu District',
    450105: 'Gangnam-gu',
    450107: 'Xixiangtang District',
    450108: 'Liangqing District',
    450109: 'Yongning District',
    450110: 'Wuming District',
    450123: 'Long An County',
    450124: 'Masan County',
    450125: 'Shanglin County',
    450126: 'Binyang County',
    450127: 'Hengxian',
    450202: 'City Central District',
    450203: 'Yufeng District',
    450204: 'Liunan District',
    450205: 'Liubei District',
    450206: 'Liujiang District',
    450222: 'Liucheng County',
    450223: 'Luzhai County',
    450224: 'Rong an County',
    450225: 'Rongshui Miao Autonomous County',
    450226: 'Sanjiang Dong Autonomous County',
    450302: 'Xiufeng District',
    450303: 'Stacking Zone',
    450304: 'Elephant Mountain District',
    450305: 'Seven Star District',
    450311: 'Yanshan District',
    450312: 'Lingui District',
    450321: 'Yangshuo County',
    450323: 'Lingchuan County',
    450324: 'Jeonju County',
    450325: 'Hung Yen County',
    450326: 'Yongfu County',
    450327: 'Guanyang County',
    450328: 'Longsheng Autonomous County',
    450329: 'Resource County',
    450330: 'Pingle County',
    450332: 'Gongcheng Yao Autonomous County',
    450381: 'Lipu',
    450403: 'Wanxiu District',
    450405: 'Cheung Chau District',
    450406: 'Longxu District',
    450421: 'Cangwu County',
    450422: 'Vine County',
    450423: 'Mengshan County',
    450481: 'Cenxi',
    450502: 'Haicheng District',
    450503: 'Yinhai District',
    450512: 'Iron Mountain Port Area',
    450521: 'Hepu County',
    450602: 'Port Area',
    450603: 'Fangcheng District',
    450621: 'Shangsi County',
    450681: 'Dongxing',
    450702: 'Chin Nam District',
    450703: 'Qin North District',
    450721: 'Lingshan County',
    450722: 'Pubei County',
    450802: 'Kangbei District',
    450803: 'Konan District',
    450804: 'Qintang District',
    450821: 'Pingnan County',
    450881: 'Guiping',
    450902: 'Yuzhou District',
    450903: 'Fumian District',
    450921: 'Rong County',
    450922: 'Luchuan County',
    450923: 'Bobai County',
    450924: 'Xingye County',
    450981: 'Beiliu',
    451002: 'Youjiang District',
    451021: 'Tianyang County',
    451022: 'Tiandong County',
    451023: 'Pingguo County',
    451024: 'Debao County',
    451026: 'Napo County',
    451027: 'Lingyun County',
    451028: 'Leye County',
    451029: 'Tianlin County',
    451030: 'Xilin County',
    451031: 'Longlin Autonomous County',
    451081: 'Jingxi',
    451102: 'Eight Steps Area',
    451103: 'Pinggui District',
    451121: 'Zhaoping County',
    451122: 'Zhongshan County',
    451123: 'Buchuan Yao Autonomous County',
    451202: 'Jinchengjiang District',
    451203: 'Yizhou District',
    451221: 'Nandan County',
    451222: 'Tian e County',
    451223: 'Fengshan County',
    451224: 'Donglan County',
    451225: 'Luocheng Lao Autonomous County',
    451226: 'Huanjiang Maonan Autonomous County',
    451227: 'Bama Yao Autonomous County',
    451228: 'Du an Yao Autonomous County',
    451229: 'Dahua Yao Autonomous County',
    451302: 'Xingbin District',
    451321: 'Xincheng County',
    451322: 'Xiangzhou County',
    451323: 'Wuxuan County',
    451324: 'Jinxiu Yao Autonomous County',
    451381: 'Heshan',
    451402: 'Jiangzhou District',
    451421: 'Fusui County',
    451422: 'Ningming County',
    451423: 'Longzhou County',
    451424: 'Daxin County',
    451425: 'Tianliang County',
    451481: 'Pingxiang',
    460105: 'Xiuying District',
    460106: 'Longhua District',
    460107: 'Qiongshan District',
    460108: 'Meilan District',
    460202: 'Haitang District',
    460203: 'Jiyang District',
    460204: 'Tianya District',
    460205: 'Yazhou District',
    460321: 'Paracels',
    460322: 'Spratly Islands',
    460323: 'The islands and reefs of Zhongsha Qundao and their waters',
    460401: 'That big town',
    460402: 'Heqing Town',
    460403: 'Nanfeng Town',
    460404: 'Dacheng Town',
    460405: 'Yaxing Town',
    460406: 'Lanyang Town',
    460407: 'Guangcun Town',
    460408: 'Mutang Town',
    460409: 'Haitou Town',
    460410: 'Eman Town',
    460411: 'Wang Wuzhen',
    460412: 'Baimajing Town',
    460413: 'Zhonghe Town',
    460414: 'Paipu Town',
    460415: 'Dongcheng Town',
    460416: 'New State Town',
    460417: 'Yangpu Economic Development Zone',
    460418: 'South China Hot Work College',
    469001: 'Wuzhishan',
    469002: 'Qionghai',
    469005: 'Wenchang',
    469006: 'Wanning',
    469007: 'Oriental',
    469021: 'Ding an County',
    469022: 'Tunchang County',
    469023: 'Chengmai County',
    469024: 'Lingao County',
    469025: 'Baisha Li Autonomous County',
    469026: 'Changjiang Li Autonomous County',
    469027: 'Ledong Li Autonomous County',
    469028: 'Lingshui Li Autonomous County',
    469029: 'Baoting Li and Miao Autonomous County',
    469030: 'Qiongzhong Li and Miao Autonomous County',
    500101: 'Wanzhou District',
    500102: 'Fuling District',
    500103: 'Yuzhong District',
    500104: 'Dadukou District',
    500105: 'Gangbuk District',
    500106: 'Shapingba District',
    500107: 'Kowloon Po District',
    500108: 'South Bank District',
    500109: 'Beibei District',
    500110: 'Qijiang District',
    500111: 'Bigfoot Area',
    500112: 'Yubei District',
    500113: 'Banan District',
    500114: 'Qianjiang District',
    500115: 'Longevity District',
    500116: 'Gangjin District',
    500117: 'Hechuan District',
    500118: 'Yongchuan District',
    500119: 'Nanchuan District',
    500120: 'Bishan District',
    500151: 'Copper Beam District',
    500152: 'Tongnan District',
    500153: 'Rongchang District',
    500154: 'Kaizhou District',
    500155: 'Liangping District',
    500156: 'Wulong District',
    500229: 'Chengkou County',
    500230: 'Fengdu',
    500231: 'Matjiang County',
    500233: 'Zhongxian',
    500235: 'Yunyang County',
    500236: 'Fengjie County',
    500237: 'Wushan County',
    500238: 'Wuxi County',
    500240: 'Shizhu Tujia Autonomous County',
    500241: 'Xiushan Tujia and Miao Autonomous County',
    500242: 'Youyang Tujia and Miao Autonomous County',
    500243: 'Pengshui Miao Tujia Autonomous County',
    510104: 'Jinjiang District',
    510105: 'Qingyang District',
    510106: 'Taurus District',
    510107: 'Wuhou District',
    510108: 'Chenghua District',
    510112: 'Longquanyi District',
    510113: 'Qingbaijiang District',
    510114: 'Xindu District',
    510115: 'Wenjiang District',
    510116: 'Shuangliu Zone',
    510117: 'Pidu District',
    510121: 'Jintang County',
    510129: 'Dayi County',
    510131: 'Pujiang County',
    510132: 'Xinjin County',
    510181: 'Dujiangyan',
    510182: 'Pengzhou',
    510183: 'Qionglai',
    510184: 'Chongzhou',
    510185: 'Jianyang',
    510191: 'High-tech Zone',
    510302: 'Artesian Well Area',
    510303: 'Gongjing District',
    510304: 'Daan District',
    510311: 'Beach Area',
    510321: 'Rong County',
    510322: 'Fushun County',
    510402: 'East End',
    510403: 'West End',
    510411: 'Renhe District',
    510421: 'Miyi County',
    510422: 'Yanbian County',
    510502: 'Jiangyang District',
    510503: 'Naxi District',
    510504: 'Longmatan District',
    510521: 'Luxian',
    510522: 'Hejiang County',
    510524: 'Xuyong County',
    510525: 'Gulin County',
    510603: 'Jingyang District',
    510604: 'Luojiang District',
    510623: 'Zhongjiang County',
    510681: 'Guanghan',
    510682: 'Shifang',
    510683: 'Mianzhu',
    510703: 'Fucheng District',
    510704: 'Youxian District',
    510705: 'Anzhou District',
    510722: 'Santai County',
    510723: 'Yanting County',
    510725: 'Zitong County',
    510726: 'Beichuan Qiang Autonomous County',
    510727: 'Pingwu County',
    510781: 'Jiangyou',
    510791: 'High-tech Zone',
    510802: 'Lizhou District',
    510811: 'Zhaohua District',
    510812: 'Chaotian District',
    510821: 'Wangcang County',
    510822: 'Qingchuan County',
    510823: 'Jiange County',
    510824: 'Cangxi County',
    510903: 'Chuanshan District',
    510904: 'Settlement Area',
    510921: 'Pengxi County',
    510922: 'Shehong County',
    510923: 'Daying County',
    511002: 'Shizhong District',
    511011: 'Dongxing District',
    511024: 'Weiyuan County',
    511025: 'Zizhong County',
    511083: 'Longchang',
    511102: 'Shizhong District',
    511111: 'Shawan District',
    511112: 'Wutongqiao District',
    511113: 'Jinkou River District',
    511123: 'Qianwei County',
    511124: 'Jingyan County',
    511126: 'Jiajiang County',
    511129: 'Muchuan County',
    511132: 'Ebian Yi Autonomous County',
    511133: 'Mabian Yi Autonomous County',
    511181: 'Emeishan',
    511302: 'Shunqing District',
    511303: 'Gaoping District',
    511304: 'Jialing District',
    511321: 'Southern County',
    511322: 'Yingshan County',
    511323: 'Pengan County',
    511324: 'Yilong County',
    511325: 'Xichong County',
    511381: 'Langzhong',
    511402: 'Dongpo District',
    511403: 'Pengshan District',
    511421: 'Renshou County',
    511423: 'Hongya County',
    511424: 'Danling County',
    511425: 'Qingshen County',
    511502: 'Cuiping District',
    511503: 'Nanxi District',
    511504: 'Xuzhou District',
    511523: 'Jiang an County',
    511524: 'Changning County',
    511525: 'High County',
    511526: 'Xue County',
    511527: 'Junlian County',
    511528: 'Xingwen County',
    511529: 'Pingshan County',
    511602: 'Gwangan District',
    511603: 'Striker Zone',
    511621: 'Yuechi County',
    511622: 'Wusheng County',
    511623: 'Linshui County',
    511681: 'Huaying',
    511702: 'Tongchuan District',
    511703: 'Dachuan District',
    511722: 'Xuanhan County',
    511723: 'Kaijiang County',
    511724: 'Dazhu County',
    511725: 'Qu County',
    511781: 'Wanyuan',
    511802: 'Rain District',
    511803: 'Mingshan District',
    511822: 'Yingjing County',
    511823: 'Hanyuan County',
    511824: 'Asbestos County',
    511825: 'Tianquan County',
    511826: 'Lushan County',
    511827: 'Baoxing County',
    511902: 'Bazhou District',
    511903: 'Enyang District',
    511921: 'Tongjiang County',
    511922: 'Nanjiang County',
    511923: 'Pyeongchang',
    512002: 'Yanjiang District',
    512021: 'Anyue County',
    512022: 'Lezhi County',
    513201: 'Malcom',
    513221: 'Wenchuan County',
    513222: 'Li County',
    513223: 'Mao County',
    513224: 'Songpan County',
    513225: 'Jiuzhaigou County',
    513226: 'Jinchuan County',
    513227: 'Xiaojin County',
    513228: 'Blackwater County',
    513230: 'Rangtang County',
    513231: 'Aba County',
    513232: 'Zoige County',
    513233: 'Hongyuan County',
    513301: 'Kangding',
    513322: 'Luding County',
    513323: 'Danba County',
    513324: 'Kowloon County',
    513325: 'Yajiang County',
    513326: 'Daofu County',
    513327: 'Luhuo County',
    513328: 'Kardze County',
    513329: 'Xinlong County',
    513330: 'Derg County',
    513331: 'Baiyu County',
    513332: 'Shiqu County',
    513333: 'Serta',
    513334: 'Litang County',
    513335: 'Batang County',
    513336: 'Township County',
    513337: 'Daocheng County',
    513338: 'Derong County',
    513401: 'Xichang',
    513422: 'Muli Tibetan Autonomous County',
    513423: 'Yanyuan County',
    513424: 'Dechang County',
    513425: 'Huili County',
    513426: 'Huidong County',
    513427: 'Ningnan County',
    513428: 'Puge County',
    513429: 'Butuo County',
    513430: 'Jinyang County',
    513431: 'Zhaojue County',
    513432: 'Xide County',
    513433: 'Mianning County',
    513434: 'Yuexi County',
    513435: 'Ganluo County',
    513436: 'Meigu County',
    513437: 'Leibo County',
    520102: 'Nanming District',
    520103: 'Yunyan District',
    520111: 'Huaxi District',
    520112: 'Wudang District',
    520113: 'Baiyun District',
    520115: 'Guanshan Lake District',
    520121: 'Kaiyang County',
    520122: 'Xifeng County',
    520123: 'Xiuwen County',
    520181: 'Qingzhen',
    520201: 'Zhongshan District',
    520203: 'Liuzhi Special Zone',
    520221: 'Shuicheng County',
    520281: 'Panzhou',
    520302: 'Honghuagang District',
    520303: 'Huichuan District',
    520304: 'Banju District',
    520322: 'Tongzi County',
    520323: 'Suiyang County',
    520324: 'Zheng an County',
    520325: 'Daozhen Gelao and Miao Autonomous County',
    520326: 'Wuchuan Gelao and Miao Autonomous County',
    520327: 'Fenggang County',
    520328: 'Meitan County',
    520329: 'Yuqing County',
    520330: 'Xishui County',
    520381: 'Chishui',
    520382: 'Renhuai',
    520402: 'Xixiu District',
    520403: 'Pingba District',
    520422: 'Puding County',
    520423: 'Zhenning Buyi and Miao Autonomous County',
    520424: 'Guanling Buyi and Miao Autonomous County',
    520425: 'Ziyun Miao Buyi Autonomous County',
    520502: 'Qixingguan District',
    520521: 'Dafang County',
    520522: 'Qianxi County',
    520523: 'Jinsha County',
    520524: 'Zhijin County',
    520525: 'Nayong County',
    520526: 'Weining Yi Hui and Miao Autonomous County',
    520527: 'Hezhang County',
    520602: 'Bijiang District',
    520603: 'Wanshan District',
    520621: 'Eguchi',
    520622: 'Yuping Dong Autonomous County',
    520623: 'Shi Yan County',
    520624: 'Sinan County',
    520625: 'Yinjiang Tujia and Miao Autonomous County',
    520626: 'Dejiang County',
    520627: 'Tujia Autonomous County along the river',
    520628: 'Songtao and Miao Autonomous County',
    522301: 'Xingyi',
    522302: 'Xingren',
    522323: 'Pu an County',
    522324: 'Qinglong County',
    522325: 'Zhenfeng County',
    522326: 'Wangmu County',
    522327: 'Cengheng County',
    522328: 'Anlong County',
    522601: 'City of Carey',
    522622: 'Huangping County',
    522623: 'Shibing County',
    522624: 'Sansui County',
    522625: 'Zhenyuan County',
    522626: 'Cen Gong County',
    522627: 'Tianzhu County',
    522628: 'Jinping County',
    522629: 'Jianhe County',
    522630: 'Taijiang County',
    522631: 'Liping County',
    522632: 'Rongjiang County',
    522633: 'Congjiang County',
    522634: 'Leishan County',
    522635: 'Majiang County',
    522636: 'Danzhai County',
    522701: 'Duyun',
    522702: 'Fukusen',
    522722: 'Libo County',
    522723: 'Guiding County',
    522725: 'Weng an County',
    522726: 'Dushan County',
    522727: 'Pingtang County',
    522728: 'Luodian County',
    522729: 'Changshun County',
    522730: 'Longli County',
    522731: 'Huishui County',
    522732: 'Sandu Shui Autonomous County',
    530102: 'Wuhua District',
    530103: 'Panlong District',
    530111: 'Guandu District',
    530112: 'Xishan District',
    530113: 'Dongchuan District',
    530114: 'Chenggong District',
    530115: 'Jinning District',
    530124: 'Fumin County',
    530125: 'Yiliang County',
    530126: 'Shilin Yi Autonomous County',
    530127: 'Songming County',
    530128: 'Luquan Yi and Miao Autonomous County',
    530129: 'Xundian Hui Yi Autonomous County',
    530181: 'Anning',
    530302: 'Kirin District',
    530303: 'Chunyi Zone',
    530304: 'Marlon District',
    530322: 'Luliang County',
    530323: 'Shizong County',
    530324: 'Luoping County',
    530325: 'Fuyuan County',
    530326: 'Aizawa',
    530381: 'Xuanwei',
    530402: 'Hongta District',
    530403: 'Gangcheon District',
    530422: 'Chengjiang County',
    530423: 'Tonghai County',
    530424: 'Huaning County',
    530425: 'Yimen County',
    530426: 'Asan Yi Autonomous County',
    530427: 'Xinping Yi Dai Autonomous County',
    530428: 'Yuanjiang Hani Yi Dai Autonomous County',
    530502: 'Longyang District',
    530521: 'Shidian County',
    530523: 'Longling County',
    530524: 'Changning County',
    530581: 'Tengchong',
    530602: 'Zhaoyang District',
    530621: 'Ludian County',
    530622: 'Qiaojia County',
    530623: 'Shiojin',
    530624: 'Daguan County',
    530625: 'Yongshan County',
    530626: 'Suijiang County',
    530627: 'Zhenxiong County',
    530628: 'Yiliang County',
    530629: 'Weixin County',
    530681: 'Mizufu',
    530702: 'Old Town',
    530721: 'Yulong Naxi Autonomous County',
    530722: 'Yongsheng County',
    530723: 'Huaping County',
    530724: 'Ninglang Yi Autonomous County',
    530802: 'Simao District',
    530821: 'Ning er Hani Yi Autonomous County',
    530822: 'Mojiang Hani Autonomous County',
    530823: 'Jingdong Yi Autonomous County',
    530824: 'Jinggu Dai Yi Autonomous County',
    530825: 'Zhenyuan Yi Hani Lahu Autonomous County',
    530826: 'Jiangcheng Hani Yi Autonomous County',
    530827: 'Menglian Dai Lahu Wa Autonomous County',
    530828: 'Lancang Lahu Autonomous County',
    530829: 'Ximeng Wa Autonomous County',
    530902: 'Linxiang District',
    530921: 'Fengqing County',
    530922: 'Cloud County',
    530923: 'Yongde County',
    530924: 'Zhenkang County',
    530925: 'Shuangjiang Lahu Wa Brown Dai Autonomous County',
    530926: 'Gengma Dai Wa Autonomous County',
    530927: 'Cangyuan Wa Autonomous County',
    532301: 'Chuxiong',
    532322: 'Shuangbai County',
    532323: 'Mouding County',
    532324: 'Nanhua County',
    532325: 'Yao an County',
    532326: 'Dayao County',
    532327: 'Yongren County',
    532328: 'Yuanmou County',
    532329: 'Wuding County',
    532331: 'Lufeng County',
    532501: 'Old',
    532502: 'Kaiyuan',
    532503: 'Mengzi',
    532504: 'Maitreya',
    532523: 'Pingbian Miao Autonomous County',
    532524: 'Jianshui County',
    532525: 'Shiping County',
    532527: 'Luxi County',
    532528: 'Yuanyang County',
    532529: 'Honghe County',
    532530: 'Jinping Miao Yao Dai Autonomous County',
    532531: 'Luchun County',
    532532: 'Hekou Yao Autonomous County',
    532601: 'Wenshan',
    532622: 'Yanshan County',
    532623: 'Xiquan County',
    532624: 'Malipo County',
    532625: 'Maguan County',
    532626: 'Qiubei County',
    532627: 'Quang Nam County',
    532628: 'Funing County',
    532801: 'Jinghong',
    532822: 'Menghai County',
    532823: 'Mengla County',
    532901: 'Dali',
    532922: 'Yangbi Yi Autonomous County',
    532923: 'Xiangyun County',
    532924: 'Binchuan County',
    532925: 'Midu County',
    532926: 'Nanjian Yi Autonomous County',
    532927: 'Weishan Yi Hui Autonomous County',
    532928: 'Yongping County',
    532929: 'Yunlong County',
    532930: 'Eryuan County',
    532931: 'Jianchuan County',
    532932: 'Heqing County',
    533102: 'Ruili',
    533103: 'Mangshi',
    533122: 'Lianghe County',
    533123: 'Yingjiang County',
    533124: 'Longchuan County',
    533301: 'Lushui',
    533323: 'Fugong County',
    533324: 'Gongshan Dulong Nu Autonomous County',
    533325: 'Lanping Bai Pumi Autonomous County',
    533401: 'Shangri-La',
    533422: 'Deqin County',
    533423: 'Weixi Lisu Autonomous County',
    540102: 'Chengguan District',
    540103: 'Duilong Deqing District',
    540104: 'Dazi District',
    540121: 'Linzhou County',
    540122: 'Dangxiong County',
    540123: 'Nimu County',
    540124: 'Qushui County',
    540127: 'Mozhu Gongka County',
    540202: 'Sangzhuzi District',
    540221: 'Nanmulin County',
    540222: 'Gyantse County',
    540223: 'Dingri County',
    540224: 'Sakya County',
    540225: 'Latse County',
    540226: 'Aung Ren County',
    540227: 'Xie Tongmen County',
    540228: 'Bailang County',
    540229: 'Renbu County',
    540230: 'Kangma County',
    540231: 'Dingjie County',
    540232: 'Chungba District',
    540233: 'Yadong County',
    540234: 'Geelong County',
    540235: 'Nyalam County',
    540236: 'Saga County',
    540237: 'Gangba County',
    540302: 'Caro District',
    540321: 'Jiangda County',
    540322: 'Gongjue County',
    540323: 'Wuqi County',
    540324: 'Dingqing County',
    540325: 'Chaya District',
    540326: 'Hachijuku',
    540327: 'Zuogon County',
    540328: 'Mangkang County',
    540329: 'Luolong County',
    540330: 'Bianba County',
    540402: 'Ba Yi District',
    540421: 'Gongbu Jiangda County',
    540422: 'Millin County',
    540423: 'Meduo County',
    540424: 'Bomi County',
    540425: 'Chasumi County',
    540426: 'Lang County',
    540502: 'Nai Dong District',
    540521: 'Zhanang County',
    540522: 'Gonggar County',
    540523: 'Sanri County',
    540524: 'Qiongjie County',
    540525: 'Qusong County',
    540526: 'Tsomei County',
    540527: 'Loza County',
    540528: 'Gacha County',
    540529: 'Longzi County',
    540530: 'Wrong County',
    540531: 'Langkazi County',
    540602: 'Xeni District',
    540621: 'Jiali County',
    540622: 'Such as counties',
    540623: 'Nie Rong County',
    540624: 'Amdo County',
    540625: 'Shenzha County',
    540626: 'Suo County',
    540627: 'Bangor County',
    540628: 'Ba Qing County',
    540629: 'Nima County',
    540630: 'Shuanghu County',
    542521: 'Plain County',
    542522: 'Zada County',
    542523: 'Gar County',
    542524: 'Ritu County',
    542525: 'Geji County',
    542526: 'Changed County',
    542527: 'Tsoqin County',
    610102: 'New Town',
    610103: 'Beilin District',
    610104: 'Lianhu District',
    610111: 'Baqiao District',
    610112: 'Weiyang District',
    610113: 'Yanta District',
    610114: 'Yanliang District',
    610115: 'Lintong District',
    610116: 'Chang an District',
    610117: 'Gaoling District',
    610118: 'Eupi District',
    610122: 'Lantian County',
    610124: 'Zhouzhi County',
    610202: 'Wang Yi District',
    610203: 'Indo-Taiwan District',
    610204: 'Yaozhou District',
    610222: 'Yijun County',
    610302: 'Weibin District',
    610303: 'Jintai District',
    610304: 'Chencang District',
    610322: 'Fengxiang County',
    610323: 'Qishan County',
    610324: 'Fufeng County',
    610326: 'Meixian',
    610327: 'Longxian',
    610328: 'Qianyang County',
    610329: 'Linyou County',
    610330: 'Fengxian',
    610331: 'Taibai County',
    610402: 'Qindu District',
    610403: 'Yangling District',
    610404: 'Weicheng District',
    610422: 'Mihara Prefecture',
    610423: 'Jingyang County',
    610424: 'Qian County',
    610425: 'Liquan County',
    610426: 'Yongshou County',
    610428: 'Changwu County',
    610429: 'Shunyi County',
    610430: 'Chunhua County',
    610431: 'Wugong County',
    610481: 'Xingping',
    610482: 'Binzhou',
    610502: 'Linwei District',
    610503: 'Huazhou District',
    610522: 'Tongguan County',
    610523: 'Dali County',
    610524: 'Heyang County',
    610525: 'Chengcheng County',
    610526: 'Pucheng County',
    610527: 'Whitewater County',
    610528: 'Bupyeong',
    610581: 'Hancheng',
    610582: 'Huayin',
    610602: 'Pagoda District',
    610603: 'Anse District',
    610621: 'Yanchang County',
    610622: 'Yanchuan County',
    610623: 'Zichang County',
    610625: 'Zhidan County',
    610626: 'Wuqi County',
    610627: 'Ganquan County',
    610628: 'Fu County',
    610629: 'Luochuan County',
    610630: 'Yichuan County',
    610631: 'Huanglong County',
    610632: 'Huangling County',
    610702: 'Hantai District',
    610703: 'Nanzheng District',
    610722: 'Chenggu County',
    610723: 'Yang County',
    610724: 'Xixiang County',
    610725: 'Mianxian',
    610726: 'Ningqiang County',
    610727: 'Lioyang County',
    610728: 'Zhenba County',
    610729: 'Liuba County',
    610730: 'Foping County',
    610802: 'Yuyang District',
    610803: 'Hengshan District',
    610822: 'Fugu County',
    610824: 'Jingbian County',
    610825: 'Dingbian County',
    610826: 'Suide County',
    610827: 'Mizhi County',
    610828: 'Jia County',
    610829: 'Wubao County',
    610830: 'Qingjian County',
    610831: 'Zizhou County',
    610881: 'Shenmu',
    610902: 'Hanbin District',
    610921: 'Hanyin County',
    610922: 'Shiquan County',
    610923: 'Ningxian County',
    610924: 'Ziyang County',
    610925: 'Langao County',
    610926: 'Pingli County',
    610927: 'Zhenping County',
    610928: 'Shunyang County',
    610929: 'White River County',
    611002: 'Shangzhou District',
    611021: 'Luonan County',
    611022: 'Danfeng County',
    611023: 'Shangnan County',
    611024: 'Shanyang',
    611025: 'Zhen an County',
    611026: 'Queshui County',
    620102: 'Chengguan District',
    620103: 'Qilihe District',
    620104: 'Xigu District',
    620105: 'Tranquility District',
    620111: 'Honggu District',
    620121: 'Yongdeng County',
    620122: 'Gaolan County',
    620123: 'Yuzhong County',
    620201: 'Municipal district',
    620290: 'Xiongguan District',
    620291: 'Great Wall District',
    620292: 'Mirror Iron Zone',
    620293: 'New Town',
    620294: 'Yuquan Town',
    620295: 'Wenshu Town',
    620302: 'Jinchuan District',
    620321: 'Yongchang County',
    620402: 'Silver District',
    620403: 'Pingchuan District',
    620421: 'Jingyuan County',
    620422: 'Huining County',
    620423: 'Jingtai County',
    620502: 'Qinzhou District',
    620503: 'Maiji District',
    620521: 'Shimizu County',
    620522: 'Qin an County',
    620523: 'Gangu County',
    620524: 'Wushan County',
    620525: 'Zhangjiachuan Hui Autonomous County',
    620602: 'Liangzhou District',
    620621: 'Minqin County',
    620622: 'Gulang County',
    620623: 'Tianzhu Tibetan Autonomous County',
    620702: 'Ganzhou District',
    620721: 'Sunan Yugur Autonomous County',
    620722: 'Minle County',
    620723: 'Linze County',
    620724: 'Gaotai County',
    620725: 'Shandan County',
    620802: 'Gong District',
    620821: 'Jingchuan County',
    620822: 'Lingtai County',
    620823: 'Chongxin County',
    620825: 'Zhuanglang County',
    620826: 'Jingning County',
    620881: 'Huating',
    620902: 'Suzhou District',
    620921: 'Jinta County',
    620922: 'Guazhou County',
    620923: 'Subei Mongolian Autonomous County',
    620924: 'Aksai Kazakh Autonomous County',
    620981: 'Yumen',
    620982: 'Dunhuang',
    621002: 'West Peak District',
    621021: 'Gyeongseong',
    621022: 'Huanxian',
    621023: 'Huachi County',
    621024: 'Heshui County',
    621025: 'Zhengning County',
    621026: 'Ning County',
    621027: 'Zhenyuan County',
    621102: 'Anding Zone',
    621121: 'Tongwei County',
    621122: 'Longxi County',
    621123: 'Weiyuan County',
    621124: 'Lintao County',
    621125: 'Zhangxian',
    621126: 'Minxian',
    621202: 'Wudu District',
    621221: 'Chengxian',
    621222: 'Wenxian',
    621223: 'Tangchang County',
    621224: 'Kang County',
    621225: 'Xihe County',
    621226: 'Li County',
    621227: 'Huixian',
    621228: 'Liangdang County',
    622901: 'Linxia',
    622921: 'Linxia County',
    622922: 'Connaught County',
    622923: 'Yongjing County',
    622924: 'Guanghe County',
    622925: 'Hezheng County',
    622926: 'Dongxiang Autonomous County',
    622927: 'Jishishan Baoan Dongxiang Salar Autonomous County',
    623001: 'Cooperative',
    623021: 'Lintan County',
    623022: 'Joni County',
    623023: 'Zhouqu County',
    623024: 'Diebu County',
    623025: 'Maqu County',
    623026: 'Luqu County',
    623027: 'Xiahe County',
    630102: 'Seongdong',
    630103: 'City Central District',
    630104: 'Chengxi District',
    630105: 'Seongbei District',
    630121: 'Datong Hui Tu Autonomous County',
    630122: 'Huangzhong County',
    630123: 'Huangyuan County',
    630202: 'Ledu District',
    630203: 'Safe Zone',
    630222: 'Minhe Hui Tu Autonomous County',
    630223: 'Mutual Aid Turk Autonomous County',
    630224: 'Hualong Hui Autonomous County',
    630225: 'Xunhua Salar Autonomous County',
    632221: 'Menyuan Hui Autonomous County',
    632222: 'Qilian County',
    632223: 'Haiyan County',
    632224: 'Gangcha County',
    632321: 'Tongren County',
    632322: 'Jianzha County',
    632323: 'Zeku County',
    632324: 'Henan Mongolian Autonomous County',
    632521: 'Republic County',
    632522: 'Tongde County',
    632523: 'Guide County',
    632524: 'Xinghai County',
    632525: 'Guinan',
    632621: 'Maqin County',
    632622: 'Banma County',
    632623: 'Gander County',
    632624: 'Dari County',
    632625: 'Kuji Prefecture',
    632626: 'Mado County',
    632701: 'Yushu',
    632722: 'Zaduo County',
    632723: 'County',
    632724: 'Zhiduo County',
    632725: 'Nangqian County',
    632726: 'Qumalai County',
    632801: 'Golmud',
    632802: 'Delingha',
    632803: 'Cliff',
    632821: 'Ulan County',
    632822: 'Dulan County',
    632823: 'Tianjun County',
    640104: 'Xingqing District',
    640105: 'Xixia District',
    640106: 'Golden Phoenix District',
    640121: 'Yongning County',
    640122: 'Helan County',
    640181: 'Lingwu',
    640202: 'Dawukou District',
    640205: 'Huinong District',
    640221: 'Pingluo County',
    640302: 'Litong District',
    640303: 'Hongsibao District',
    640323: 'Yanchi County',
    640324: 'Tongxin County',
    640381: 'Qingtongxia',
    640402: 'Wonju-gu',
    640422: 'Xiji County',
    640423: 'Lund County',
    640424: 'Jingyuan County',
    640425: 'Pengyang County',
    640502: 'Shapotou District',
    640521: 'Zhongning County',
    640522: 'Haiyuan County',
    650102: 'Tianshan District',
    650103: 'Shayibak District',
    650104: 'New Downtown',
    650105: 'Water Mill Gou District',
    650106: 'Toutunhe District',
    650107: 'Dabancheng District',
    650109: 'Midong-District',
    650121: 'Urumqi County',
    650202: 'Dushanzi District',
    650203: 'Karamay District',
    650204: 'Baijia Beach Area',
    650205: 'Urhe District',
    650402: 'Gaochang District',
    650421: 'Shanshan County',
    650422: 'Toxon County',
    650502: 'Ishu District',
    650521: 'Balikun Kazakh Autonomous County',
    650522: 'Yiwu County',
    652301: 'Changji',
    652302: 'Fukang',
    652323: 'Hutubi County',
    652324: 'Manas County',
    652325: 'Qitai County',
    652327: 'Jimsar County',
    652328: 'Mulei Kazakh Autonomous County',
    652701: 'Bole',
    652702: 'Alashankou',
    652722: 'Jinghe County',
    652723: 'Hot Spring County',
    652801: 'City of Korla',
    652822: 'Luntai County',
    652823: 'Weili County',
    652824: 'Ruoqiang County',
    652825: 'And the last county',
    652826: 'Yanqi Hui Autonomous County',
    652827: 'Hejing County',
    652828: 'Heshuo County',
    652829: 'Bohu County',
    652901: 'Aksu',
    652922: 'Wensu County',
    652923: 'Kuqa County',
    652924: 'Shaya County',
    652925: 'Xinhe County',
    652926: 'Baicheng County',
    652927: 'Ush County',
    652928: 'Avati County',
    652929: 'Keping County',
    653001: 'City of Artus',
    653022: 'Aktau County',
    653023: 'Ahchi County',
    653024: 'Ucha County',
    653101: 'Kashgar',
    653121: 'Shufu County',
    653122: 'Shule County',
    653123: 'Ingisa County',
    653124: 'Zepp County',
    653125: 'Shache County',
    653126: 'Yecheng County',
    653127: 'Makati County',
    653128: 'Yuepu Lake County',
    653129: 'Jiashi County',
    653130: 'Bachu County',
    653131: 'Tashkurgan Tajik Autonomous County',
    653201: 'Wada',
    653221: 'Hetian County',
    653222: 'Moyu County',
    653223: 'Pishan County',
    653224: 'Luopu County',
    653225: 'Celle County',
    653226: 'Yutian County',
    653227: 'Minfeng County',
    654002: 'Yining',
    654003: 'Kuitun',
    654004: 'Khorgos',
    654021: 'Yining County',
    654022: 'Chabchar Sibe Autonomous County',
    654023: 'Huocheng County',
    654024: 'Gongliu County',
    654025: 'Xinyuan County',
    654026: 'Zhaosu County',
    654027: 'Turks County',
    654028: 'Nilek County',
    654201: 'Tower',
    654202: 'Wusu',
    654221: 'Emin County',
    654223: 'Shawan County',
    654224: 'Tory County',
    654225: 'Yumin County',
    654226: 'Hebuxel Mongol Autonomous County',
    654301: 'Altay',
    654321: 'Burzin County',
    654322: 'Fuyun County',
    654323: 'Fuhai County',
    654324: 'Haba River County',
    654325: 'Qinghe County',
    654326: 'Yoshimuno Prefecture',
    659001: 'Shihezi',
    659002: 'City of Aral',
    659003: 'Tumshuk',
    659004: 'Wujiaqu',
    659005: 'Beitun',
    659006: 'Tiemen Guanshi',
    659007: 'Shuanghe',
    659008: 'Coquedara',
    659009: 'Kunyu',
    710101: 'Zhongzheng District',
    710102: 'Datong District',
    710103: 'Zhongshan District',
    710104: 'Songshan District',
    710105: 'Daan District',
    710106: 'Wanhua District',
    710107: 'Xinyi District',
    710108: 'Shilin District',
    710109: 'Beitou District',
    710110: 'Neihu District',
    710111: 'Nangang District',
    710112: 'Wenshan District',
    710199: 'Other Districts',
    710201: 'Xinxing District',
    710202: 'Former Golden District',
    710203: 'Qianya District',
    710204: 'Yancheng District',
    710205: 'Gushan District',
    710206: 'Qijin District',
    710207: 'Former Town District',
    710208: 'Sanmin District',
    710209: 'Zuoying District',
    710210: 'Nanzi District',
    710211: 'Little Port District',
    710241: 'Lingya District',
    710242: 'Renwu District',
    710243: 'Greater Community',
    710244: 'Okayama',
    710245: 'Luzhu District',
    710246: 'Alian District',
    710247: 'Tianliao District',
    710248: 'Bird Nest Area',
    710249: 'Qiaotou District',
    710250: 'Ziguan District',
    710251: 'Mida District',
    710252: 'Yong an District',
    710253: 'Hunei District',
    710254: 'Fengshan District',
    710255: 'Daliao District',
    710256: 'Forest Park',
    710257: 'Torimatsu District',
    710258: 'Big Tree District',
    710259: 'Qishan District',
    710260: 'Mino District',
    710261: 'Six Turtles Zone',
    710262: 'Inner Gate Area',
    710263: 'Cedar Forest District',
    710264: 'Jiaxian District',
    710265: 'Taoyuan District',
    710266: 'Namasha District',
    710267: 'Maolin District',
    710268: 'Solanum District',
    710299: 'Other District',
    710301: 'Central and Western District',
    710302: 'East End',
    710303: 'Southern District',
    710304: 'North District',
    710305: 'Anping District',
    710306: 'Annam-gu',
    710339: 'Yongkang District',
    710340: 'Quy Nhon District',
    710341: 'Xinhua District',
    710342: 'Zuozhen District',
    710343: 'Yujing District',
    710344: 'Nanxi District',
    710345: 'Nanhua District',
    710346: 'Rende District',
    710347: 'Guanmiao District',
    710348: 'Ryuzaki Ward',
    710349: 'Guantian District',
    710350: 'Madou District',
    710351: 'Jiali District',
    710352: 'Westport District',
    710353: 'Qigu District',
    710354: 'General District',
    710355: 'School A Area',
    710356: 'North Gate District',
    710357: 'New Camp',
    710358: 'Rear wall area',
    710359: 'White River District',
    710360: 'Dongshan District',
    710361: 'Rokko District',
    710362: 'Lower Camp District',
    710363: 'Liuying District',
    710364: 'Salt water area',
    710365: 'Shanhua District',
    710366: 'Great Inner District',
    710367: 'Yagami District',
    710368: 'New Downtown',
    710369: 'Stable Zone',
    710399: 'Other District',
    710401: 'Central District',
    710402: 'Eastside',
    710403: 'Southern District',
    710404: 'West End',
    710405: 'North District',
    710406: 'Beitun District',
    710407: 'Xitun District',
    710408: 'Nantun District',
    710431: 'Taiping District',
    710432: 'Dali District',
    710433: 'Foggy Peak District',
    710434: 'Uri District',
    710435: 'Fengyuan District',
    710436: 'Hori District',
    710437: 'Ishioka-ku',
    710438: 'Dongshi District',
    710439: 'Zone of Peace',
    710440: 'New Community',
    710441: 'Tan Zi District',
    710442: 'Daya District',
    710443: 'Kamioka-ku',
    710444: 'Big Belly Area',
    710445: 'Shalu District',
    710446: 'Longjing District',
    710447: 'Wuqi District',
    710448: 'Shimizu District',
    710449: 'Dajia Area',
    710450: 'Waipu District',
    710451: 'Daan District',
    710499: 'Other District',
    710507: 'Jinsha Town',
    710508: 'Jinhu Town',
    710509: 'Jinning Township',
    710510: 'Golden Town',
    710511: 'Lieyu Township',
    710512: 'Wucun Township',
    710614: 'Nantou',
    710615: 'Zhongliao Township',
    710616: 'Caotun Town',
    710617: 'Country surname township',
    710618: 'Puli Town',
    710619: 'Renai Township',
    710620: 'Naima',
    710621: 'Market Town',
    710622: 'Shuili Township',
    710623: 'Yuchi Township',
    710624: 'Xinyi Township',
    710625: 'Zhushan Town',
    710626: 'Lugu Township',
    710701: 'Renai District',
    710702: 'Xinyi District',
    710703: 'Zhongzheng District',
    710704: 'Zhongshan District',
    710705: 'Comfort Zone',
    710706: 'Warm zone',
    710707: 'Seven Blocks',
    710799: 'Other Districts',
    710801: 'Eastside',
    710802: 'North District',
    710803: 'Xiangshan District',
    710899: 'Other Districts',
    710901: 'Eastside',
    710902: 'West End',
    710999: 'Other District',
    711130: 'Wanli District',
    711132: 'Banqiao District',
    711133: 'Xizhi Zone',
    711134: 'Deep Pit Area',
    711135: 'Shijie District',
    711136: 'Ruifang District',
    711137: 'Pingxi District',
    711138: 'Sungai District',
    711139: 'Gongliao District',
    711140: 'Xindian District',
    711141: 'Pinglin District',
    711142: 'Wulai District',
    711143: 'Yonghe District',
    711144: 'Zhonghe District',
    711145: 'Tucheng District',
    711146: 'Three Gorges Area',
    711147: 'Woods Zone',
    711148: 'Yingge District',
    711149: 'Triple District',
    711150: 'Xinzhuang District',
    711151: 'Taishan District',
    711152: 'Linkou District',
    711153: 'Luzhou District',
    711154: 'Wugu District',
    711155: 'Bali District',
    711156: 'Freshwater Zone',
    711157: 'Sanzhi District',
    711158: 'Shimen District',
    711287: 'Yilan',
    711288: 'Head Town',
    711289: 'Jiaoxi Township',
    711290: 'Zhuangwei Township',
    711291: 'Yuanshan Township',
    711292: 'Luodong Town',
    711293: 'Sanxing Township',
    711294: 'Datong Township',
    711295: 'Wujie Township',
    711296: 'Dongshan Township',
    711297: 'Suao Town',
    711298: 'Nanao Township',
    711299: 'Diaoyutai',
    711387: 'Zhubei',
    711388: 'Hukou Township',
    711389: 'Xinfeng Township',
    711390: 'Xinpu Town',
    711391: 'Kansai Town',
    711392: 'Xionglin Township',
    711393: 'Baoshan Township',
    711394: 'Zhudong Town',
    711395: 'Wufeng Township',
    711396: 'Hengshan Township',
    711397: 'Jianshi Township',
    711398: 'Beipu Township',
    711399: 'Emei Township',
    711414: 'Zhongli District',
    711415: 'Pingzhen District',
    711417: 'Bayberry District',
    711418: 'New Housing District',
    711419: 'Guanyin District',
    711420: 'Taoyuan',
    711421: 'Turtle Mountain District',
    711422: 'Bade District',
    711423: 'Daxi District',
    711425: 'Big Park',
    711426: 'Luzhu District',
    711487: 'Zhongli',
    711488: 'Pingzhen',
    711489: 'Longtan Township',
    711490: 'Yangmei',
    711491: 'New Housing Township',
    711492: 'Guanyin Township',
    711493: 'Taoyuan',
    711494: 'Guishan Township',
    711495: 'Batoku',
    711496: 'Daxi Town',
    711497: 'Fuxing Township',
    711498: 'Dayuan Township',
    711499: 'Luzhu Township',
    711520: 'Toufen',
    711582: 'Zhunan Town',
    711583: 'Toufen Town',
    711584: 'Sanwan Township',
    711585: 'Nanzhuang Township',
    711586: 'Shitan Township',
    711587: 'Houlong Town',
    711588: 'Tongxiao Town',
    711589: 'Yuanli Town',
    711590: 'Miaoli',
    711591: 'Building Bridge Township',
    711592: 'Touwu Township',
    711593: 'Gongguan Township',
    711594: 'Great Lakes Township',
    711595: 'Tai an Township',
    711596: 'Tongluo Township',
    711597: 'Sanyi Township',
    711598: 'West Lake Township',
    711599: 'Zhuolan Town',
    711736: 'Yuanlin',
    711774: 'Changhua',
    711775: 'Fenyuan Township',
    711776: 'Flower Bed Township',
    711777: 'Xiushui Town',
    711778: 'Lugang Town',
    711779: 'Fuxing Township',
    711780: 'Line Xixiang',
    711781: 'Hemei Town',
    711782: 'Shengang Township',
    711783: 'Yuanlin Town',
    711784: 'Shetou Township',
    711785: 'Yongjing Township',
    711786: 'Puxin Township',
    711787: 'Xihu Town',
    711788: 'Ōmura',
    711789: 'Puyan Township',
    711790: 'Tanaka Town',
    711791: 'Beidou Town',
    711792: 'Tianwei',
    711793: 'Pitou Township',
    711794: 'Xizhou Township',
    711795: 'Zhutang Township',
    711796: 'Erlin Town',
    711797: 'Dachengxiang',
    711798: 'Fangyuan Township',
    711799: 'Ershui Town',
    711982: 'Fanlu Township',
    711983: 'Meishan Township',
    711984: 'Takezaki',
    711985: 'Alishan Township',
    711986: 'Zhongpu Township',
    711987: 'Tai Po Township',
    711988: 'Water Town',
    711989: 'Lucao Township',
    711990: 'Taibao',
    711991: 'Puzi',
    711992: 'Dongshi Township',
    711993: 'Six Legs Township',
    711994: 'Xingang Township',
    711995: 'Minxiong Township',
    711996: 'Dalin Town',
    711997: 'Xikou Township',
    711998: 'Yizhu Township',
    711999: 'Budai Town',
    712180: 'Dounan Town',
    712181: 'Dapi Township',
    712182: 'Huwei Town',
    712183: 'Tuku Town',
    712184: 'Zhu Zhongxiang',
    712185: 'Dongshi Township',
    712186: 'Taixi Township',
    712187: 'Lunbei Township',
    712188: 'Mailiao Township',
    712189: 'Douliu',
    712190: 'Linnei Township',
    712191: 'Gukeng Township',
    712192: 'Yingtong Township',
    712193: 'Xiluo Town',
    712194: 'Erlun Township',
    712195: 'North Harbor Town',
    712196: 'Shuilin Township',
    712197: 'Kouhu Township',
    712198: 'Four Lakes Township',
    712199: 'Yuanchang Township',
    712451: 'Choding Township',
    712467: 'Pingtung',
    712468: 'Sandimen Township',
    712469: 'Wutai Township',
    712470: 'Ma Hometown',
    712471: 'Jiuru Township',
    712472: 'Ligang Township',
    712473: 'Gaoshu Township',
    712474: 'Yanpu Township',
    712475: 'Changzhi Township',
    712476: 'Linluo Township',
    712477: 'Zhutian Township',
    712478: 'Neipu Township',
    712479: 'Banten Township',
    712480: 'Chaozhou Town',
    712481: 'Taiwu Township',
    712482: 'Lai Yi Township',
    712483: 'Wanluan Township',
    712484: 'Chengding Township',
    712485: 'Xinpi Township',
    712486: 'Nanzhou Township',
    712487: 'Forest Edge Township',
    712488: 'Donggang Town',
    712489: 'Ryukyu-go',
    712490: 'Jiadong Township',
    712491: 'Xinyuan Township',
    712492: 'Fangliao Township',
    712493: 'Fangshan Township',
    712494: 'Kasugago',
    712495: 'Lion Township',
    712496: 'Chechengxiang',
    712497: 'Peony Township',
    712498: 'Hengchun Town',
    712499: 'Manchuria Township',
    712584: 'Taitung',
    712585: 'Green Island Township',
    712586: 'Orchid Island',
    712587: 'Yanping Township',
    712588: 'Beinan Township',
    712589: 'Luye Township',
    712590: 'Guanshan Town',
    712591: 'Haiduan Township',
    712592: 'Chishang Town',
    712593: 'Donghe Township',
    712594: 'Berjaya Town',
    712595: 'Changbin Township',
    712596: 'Jinfeng Township',
    712597: 'Dawu Township',
    712598: 'Daren Township',
    712599: 'Taimali Township',
    712686: 'Hualien',
    712687: 'New and Countryside',
    712688: 'Taroko',
    712689: 'Xiulin Township',
    712690: 'Ji an Township',
    712691: 'Shoufeng Township',
    712692: 'Fenglin Town',
    712693: 'Guangfu Township',
    712694: 'Toyohama',
    712695: 'Ruisui Township',
    712696: 'Vang Vieng Township',
    712697: 'Yuli Town',
    712698: 'Zhuoxi Township',
    712699: 'Fuli Township',
    712794: 'Magong',
    712795: 'Xiyu Township',
    712796: 'Wang an Township',
    712797: 'Seven Beauty Town',
    712798: 'Baisha Township',
    712799: 'Huxi Township',
    712896: 'Nangan Township',
    712897: 'Beigan Township',
    712898: 'Dongyin Township',
    712899: 'Juguang Township',
    810101: 'Central and Western District',
    810102: 'Wan Chai District',
    810103: 'East End',
    810104: 'Southern District',
    810201: 'Kowloon District',
    810202: 'Yau Tsim Mong District',
    810203: 'Sham Shui Po District',
    810204: 'Wong Tai Sin District',
    810205: 'Kwun Tong District',
    810301: 'North District',
    810302: 'Tai Po District',
    810303: 'Sha Tin District',
    810304: 'Sai Kung District',
    810305: 'Yuen Long District',
    810306: 'Tuen Mun District',
    810307: 'Tsuen Wan District',
    810308: 'Kwai Tsing District',
    810309: 'Islands District',
    820102: 'Fatima Parish',
    820103: 'Kao Parish',
    820104: 'Wangde Parish',
    820105: 'Lobby Area',
    820106: 'Fengshun Parish',
    820202: 'Jiamo Parish',
    820203: 'Cotai Reclamation',
    820204: 'St. Franciscan Parish'
  }
}
