import schoolsArr from './universe'
export default {
  position_list: {
    110000: 'C-Level Executive',
    120000: 'Business Manager',
    130000: 'IT Leader',
    140000: 'IT Personel',
    150000: 'Enterprise Architect',
    160000: 'Manufacturing Manager',
    170000: 'R&D Manager',
    180000: 'R&D Personel',
    190000: 'Engineer （Commissioning，Service etc.)',
    200000: 'Sales Personel',
    210000: 'Quality Personel',
    220000: 'Business Analyst and Consultant',
    230000: 'Faculty',
    240000: 'Student',
    250000: 'Development role',
    260000: 'Internal employees of Siemens',
    280000: 'Planner (Design Institute)',
    270000: 'Others'
  },
  grade_list: {
    110000: 'Associate',
    120000: 'Bachelor',
    130000: 'Master',
    140000: 'Doctor'
  },
  school_list: schoolsArr
}
