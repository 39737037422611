import { SetupContext } from 'vue'
import { LoadingOutlined, TwoToneColor } from '@ant-design/icons-vue'

export function Loading(
  props: {
    color?: string
    size?: number
  },
  _ctx: SetupContext
) {
  return (
    <LoadingOutlined
      style={{
        width: `${props.size ?? 16}px`,
        height: `${props.size ?? 16}px`,
        color: props.color ?? '#fff',
      }}
    />
  )
}
