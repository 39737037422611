import HttpRequest from '@/plugins/http'

const request = new HttpRequest({
  baseURL: '/marketplace-common'
})

const axios = request.request()

/**
 * 点击事件埋点
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function pointEvent (params) {
  return axios.post('/point/event/v1', params)
}

/**
 * 网页访问埋点
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function pointPage (params) {
  return axios.post('/point/page/v1', params)
}

/**
 * 网页访问埋点
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function pointWebEvent (params) {
  return axios.post('/point/web/event/v1', params)
}

/**
 * 刷新token
 * @export
 * @returns
 */
 export async function refreshToken (rToken, params) {
  return axios.get(`/auth/refresh/tokens/v2?refreshToken=${rToken}`, params)
}