import { urlJoin, isUrl, isCmsFile } from './url'
import { complementaryURL } from './utils'

/**
 * 文件信息
 */
export class FileInfo {
  /**
   * 文件信息
   * @param {string} path 文件地址
   * @param {'private' | 'public'} permission 权限表示，默认公共
   */
  constructor(path = '', permission = 'public') {
    this.path = path
    this.permission = permission
  }

  /**
   * 是否CMS上传的文件
   *
   * @readonly
   * @memberof FileInfo
   */
  get isCmsFile() {
    return isCmsFile(this.path)
  }

  /**
   * 是否公共的文件
   *
   * @readonly
   * @memberof FileInfo
   */
  get isPublic() {
    return /\/public\//g.test(this.path) || !this.isPrivate
  }

  /**
   * 是否私有的文件
   *
   * @readonly
   * @memberof FileInfo
   */
  get isPrivate() {
    return /\/private\//g.test(this.path)
  }

  /**
   * 文件地址
   *
   * @readonly
   * @memberof FileInfo
   */
  get url() {
    if (isUrl(this.path)) {
      return this.path
    }
    if (this.permission == 'private') {
      return this.privateUrl
    } else if (this.isCmsFile) {
      if (this.isPrivate) {
        return this.privateUrl
      }
    }
    return this.publicUrl
  }

  /**
   * 私有OSS地址
   *
   * @readonly
   * @memberof FileInfo
   */
  get privateUrl() {
    return complementaryURL(this.path, 'private')
  }

  /**
   * 公有OSS地址
   *
   * @readonly
   * @memberof FileInfo
   */
  get publicUrl() {
    return urlJoin([process.env.VUE_APP_OSS_BASE_URL, this.path])
  }
}
