// 1.注册svg-Icon组件
// import Vue from 'vue'
import svgIcon from '@/components/svgIcon.vue'

const registerSvgIcon = (app) => {
  app.component('svg-icon', svgIcon)

  const req = require.context('./svg', false, /\.svg$/)

  req.keys().map(req)
}
export default registerSvgIcon
