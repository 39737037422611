export default {
	title: "零碳先锋奖-获奖企业风采",
	list: [
		{
			title: "赋能先锋卓越奖",
			subList: [
				{
					name: "西安宝科流体技术有限公司",
					description:"西安宝科流体技术有限公司多年来深耕流程工业领域，致力于柔性制造工艺的研发、高端装备和产线的设计、规划、生产和交付。用先进工艺装备推动创新升级，让新材料生产智能化，不断为全行业全社会创造价值。服务是宝科的核心竞争力：智能装备成就客户，追求安全低碳、数字化实现流程控制的透明度，为客户提供增值。宝科流体是西门子授权系统集成商。",
					img: "/img/article/赋能先锋卓越获奖企业-西安宝科流体技术有限公司.png",
				}, 
				{
					name: "上海朗绿建筑科技股份有限公司",
					description:"上海朗绿建筑科技股份有限公司是绿色低碳建筑技术服务领域的高新技术企业，产品覆盖住宅、办公、园区、商业、医院、教育、酒店、银行、工业建筑及各类园区项目。经历10年探索，已成长为绿色低碳建筑科技领域的引领者。致力于绿色科技赋能建筑行业的低碳转型与高质量发展,推动行业实现碳中和。在零碳建筑/园区等领域与西门子深度合作。在产业园区的低碳、零碳建设与运营起到显著减碳和示范效应，同时为未来零碳园区及零碳建筑相关标准体系的建立提供参考。",
					img: "/img/article/郎绿科技.png",
				}, 
				{
					name: "能科科技股份有限公司",
					description:"能科科技是服务制造业企业数字化转型的高新技术企业，产品和服务覆盖高科技电子与5G、汽车、装备制造、能源等行业领域。在双碳领域，能科科技为多个行业客户提供了应对市场变革的数字化解决方案。能科科技是西门子长期战略合作伙伴，跟踪西门子的新战略，新产品，新技术，与西门子原厂深度融合，持续为客户创造价值。",
					img: "/img/article/赋能先锋卓越奖-能科科技.png",
				}
			]
		},

{
				title: "运营先锋卓越奖",
			subList: [
				{
					name: "欣旺达电子股份有限公司",
					description:"欣旺达电子股份有限公司成立于1997年。有消费类电池、电动汽车电池、能源科技、智能硬件、智能制造与工业互联网、第三方检测服务六大业务板块。拥有近三十年电池研发与制造经验，是全球锂离子电池领域的领军企业，手机电池市场份额连续多年位居全球领先，同时也是日产、大众、沃尔沃等车企供应商；储能累计装机量居于全国前列。与西门子在PLM系统及可持续领域等展开合作。",
					img: "/img/article/运营先锋卓越奖-欣旺达电子股份有限公司.png",
				}, 
				{
					name: "深南电路股份有限公司",
					description:"深南电路股份有限公司深耕电子互联领域近40年，是中国印制电路板行业领先企业。公司连续6年碳排放量低于政府设定值，并获评绿色工厂，近年年均减排40,000 TCO2e，致力实现2050年全面碳中和。2022年，公司获西门子中国特别资助，取得ISO14067产品碳足迹认证，提供企业减碳新思路。公司将继续专注可持续发展，携手各方打造零碳未来！",
					img: "/img/article/运营先锋卓越奖-深南电路股份有限公司.png",
				},
				{
					name: "中外运-敦豪国际航空快件有限公司(DHL)",
					description:"中外运-敦豪国际航空快件有限公司是首家进入中国市场的国际快递企业，专注发展国际限时快递服务、全球范围的文件和包裹快递。DHL集团是绿色物流的先行者，早在2007年就提出了“GoGreen”计划。2021年集团发布了可持续发展路线图，并至2030年投资70亿欧元，加快去碳化进程，旨在至2050年实现物流相关净零排放。西门子mobility业务元已经通过DHL购买了GG+产品，通过SAF可持续燃油实现低碳减排。",
					img: "/img/article/赋能先锋优秀入围and运营先锋卓越_DHL.png",

				},
			]
		},
			{
			title: "赋能先锋优秀奖（入围卓越奖终选企业）",
			subList: [
				{
					name: "上海迪普自动化技术有限公司",
					description:"迪普是自动化技术服务领域的高新技术企业，服务覆盖数据中心节能，大型建筑暖通，电子工厂，水务行业等领域，协助多家头部企业制定技术标准。致力于在2030年前实现碳达峰，2025年实现供应链90%碳减排，连续5年赋能客户获得获得了国家绿色数据中心认证。在能效管控、碳资产管理等领域与西门子深度合作，共研虚拟调试技术。在数据中心行业实现减碳30000吨。",
					img: "/img/article/赋能先锋优秀奖（入围卓越奖终选企业）-上海迪普自动化技术有限公司.png",
				}, 
				{
					name: "上海怀信智能科技有限公司",
					description:"上海怀信科技是数字化工厂落地大师，为制药、快消品、新能源和精细化工领域提供全周期服务。在双碳领域，我们致力于可持续发展，每月帮助企业节约30%废水处理成本和1.2万kw电量。赋能企业获得2018年度长城食品安全科学技术奖和中国质量协会技术奖。怀信科技在制药、快消品、新能源和精细化工领域与西门子开展广泛合作。在精细化工行业实现减碳，提升生产效率15%。怀信科技荣获赋能先锋优秀奖。",
					img: "/img/article/赋能先锋优秀奖（入围卓越奖终选企业）-上海怀信智能科技有限公司.png",
				},
				{
					name: "北京西威嘉业科技有限公司",
					description:"北京西威嘉业是北京市工业技术服务领域的高新技术企业，产品覆盖石油钻采、矿山、数字化工厂等领域。致力于在2030年前实现碳达峰，2025年实现供应链30%碳减排，赋能石油钻采领域的客户获得获得了绿色工厂/产品认证。在能效管控、碳资产管理等领域与西门子深度合作。在石油钻采机械行业实现减碳30-40%，提升资源生成效率20%。",
					img: "/img/article/赋能先锋优秀奖（入围卓越奖终选企业）-北京西威嘉业科技有限公司.png",
				},
				{
					name: "北明天时能源科技（北京）有限公司",
					description:"北明天时以智慧能源服务为核心，聚焦政府能源监管系统、能源企业管控系统、园区能源管控系统的建设和服务。公司秉承多年供热行业产品研发、系统集成和工程服务的资深经验，通过全生命周期服务模式，助力企业和政府提升服务、节能降碳和优化管理。在能效管控、碳资产管理等领域与西门子深度合作，为我国北方主要城市多家大中型供热企业量身定制智慧供热生产经营全集成解决方案，实现多个大型项目减碳降耗。",
					img: "/img/article/赋能先锋优秀奖（入围卓越奖终选企业）-北明天时能源科技（北京）有限公司.png",
				},
				{
					name: "广州高谱技术有限公司",
					description:"广州高谱公司坚持推动教育的数字化，开发了华南区第一台数字化样机，样机产品包括西门子PLC、变频器、伺服和MCD仿真软件。服务了三届广东省职业院校学生技能大赛数字化双胞胎技术赛项，共300多位选手100多个学校参赛，促进数字化人才建设和低碳化知识能力的建设。使用西门子数字化产品，赋能更多的教育和工业客户，实现人才的数字化转型，实现企业的可持续发展。",
					img: "/img/article/赋能先锋优秀奖（入围卓越奖终选企业）-广州高谱技术有限公司.png",
				},
				{
					name: "深圳达实智能股份有限公司",
					description:"深圳达实智能股份有限公司作为建筑节能领域的领军企业，致力于为客户营造更智慧、更低碳、更温暖的工作和生活环境。公司拥有国家博士后科研工作站及节能研究中心，持续推动技术创新和产品升级，以满足客户不断增长的节能需求。在能效提升、碳资产管理等领域与西门子深度合作，在高效冷站及水蓄冷领域实现综合能效提升15%~30%。",
					img: "/img/article/赋能先锋优秀奖（入围卓越奖终选企业）-深圳达实智能股份有限公司.png",
				},
				{
					name: "驿舒达酒店预订服务(上海)有限公司",
					description:"驿舒达商旅为全球超过三分之一的财富500强企业提供给差旅管理服务，并已获得多家国内头部科技企业的信赖和使用。致力于为全球的企业、酒店和商旅人士打造端到端的低碳可持续全球差旅管理服务。在商旅酒店、绿色出行等领域与西门子深度合作。2023财年在商旅酒店方面帮助西门子中国实现单间夜减碳5%，提升绿色酒店占比至95%。",
					img: "/img/article/赋能先锋优秀奖（入围卓越奖终选企业）-HRS驿舒达酒店预订服务(上海)有限公司.png",
				},
				{
					name: "中外运-敦豪国际航空快件有限公司(DHL)",
					description:"中外运-敦豪国际航空快件有限公司是首家进入中国市场的国际快递企业，专注发展国际限时快递服务、全球范围的文件和包裹快递。 DHL集团是绿色物流的先行者，早在2007年就提出了“GoGreen”计划。2021年集团发布了可持续发展路线图，并至2030年投资70亿欧元，加快去碳化进程，旨在至2050年实现物流相关净零排放。西门子mobility业务元已经通过DHL购买了GG+产品，通过SAF可持续燃油实现低碳减排。",
					img: "/img/article/赋能先锋优秀入围and运营先锋卓越_DHL.png",
				},
			]
		},
			{
			title: "赋能先锋优秀奖",
			subList: [
				{
					name: "西兰花数字科技（苏州）有限公司",
					description:"西兰花数字科技是智改数转及智能基础设施领域的新兴科技企业，产品覆盖长三角并向全国辐射。致力于在2030年前实现碳达峰，2060年前实现碳中和。赋能常熟高新区获得2023“零碳中国”零碳设计解决方案在能效管控、碳资产管理以及智能基础设施等领域与西门子深度合作。",
					img: "/img/article/赋能先锋优秀奖-西兰花数字科技（苏州）有限公司.png",
				}, 
				{
					name: "杭州源牌科技股份有限公司",
					description:"杭州源牌科技股份有限公司，致力于城市碳中和事业，专注于：以可再生能源为主的多能源互补，以储能为核心的冷热电协同，以云平台为中心全程能效管控的区域能源技术，是综合能源、健康环境与智慧水务（供热）领域的国家重点高新技术企业，拥有综合能源设备、智能仪表、变风量末端装置等核心产品、一体化解决方案和中央空调专业服务平台。与西门子PLC软硬件结合的源牌能源管控系统多年来获得诸多成就。",
					img: "/img/article/赋能先锋优秀奖-杭州源牌科技股份有限公司.png",
				}, 
				{
					name: "浙江大冲能源科技股份有限公司",
					description:"浙江大冲能源科技股份有限公司是国内领先的智能物联网运维生态产品及解决方案服务商，公司产品及解决方案覆盖智慧医疗，智慧建筑，智慧交通，智慧工厂等众多领域。致力于在2030年前实现碳达峰，2028年实现供应链20%碳减排。在能效管控、碳资产管理等领域与西门子数字化工业集团深度合作。在电子洁净、光伏电池、生物制药等行业实现减碳增效，提升项目中央空调系统20%以上节能效果，年节能效益超亿元。",
					img: "/img/article/赋能先锋优秀奖-浙江大冲能源科技股份有限公司.png",
				},
				{
					name: "紫邦智能科技（上海）股份有限公司",
					description:"紫邦智能科技（上海）股份有限公司创建于 2015年，是一家中国工业自动化领域新锐企业，主要从事装备制造业的自动化、数字化、智能化产品研发及系统集成。专注于工业领域的自动化、数字化、网络化、智能化发展与实践，为广大工业企业提供自动化系统、数字化工厂、工业互联网及工业大数据智能等一体的产品、解决方案及服务。希望在企业能效管控、碳资产管理等领域与西门子深度合作。",
					img: "/img/article/赋能先锋优秀奖-紫邦智能科技（上海）股份有限公司.png",
				},
				{
					name: "北京佰能盈天科技股份有限公司",
					description:"北京佰能盈天科技股份有限公司，系国家高新技术企业，主要从事工业智能制造技术的研发和应用，致力于构建智能产线、智慧物流、智能装备、智能决策与支持为一体的智能制造体系。公司致力于通过先进数字化、网络化、智能化解决方案，助力用户实现以降本增效、安全生产、绿色可持续发展为核心目标的数字化转型升级，践行“中国制造”强国梦。公司在能源管理、能效优化等数字化工业领域与西门子开展深度合作。",
					img: "/img/article/赋能先锋优秀奖-北京佰能盈天科技股份有限公司.png",
				},
				{
					name: "天俱时工程科技集团有限公司",
					description:"天俱时集团是国内领先的工程技术服务商、先进智能装备制造商，专注于医药、新能源、新材料、工业环保等行业领域。致力于帮助企业实现绿色智慧生产，专利产品智能瞬时连消机可实现节约冷却水90%以上，蒸汽70%以上；天俱时能源管理系统可降低12%以上的能源成本。在工业自动化、能源管理等领域与西门子开展长期合作，实现制药企业节省10%以上的资源成本。",
					img: "/img/article/赋能先锋优秀奖-天俱时工程科技集团有限公司.png",
				},
				{
					name: "北京雪迪龙科技股份有限公司",
					description:"雪迪龙是集研发、设计、生产、销售、服务于一体的高新技术企业。主营业务包括环境监测、环境大数据、工业过程分析、污染治理与节能、环境综合服务等五个板块，助力环境质量持续改善及“双碳目标”的实现。致力先进碳计量技术，服务国家双碳战略；探索直测计量支撑碳交易在环境监测及工业过程分析等领域，与西门子深度合作，在石化、冶金、建材等行业实现减污降碳、安全节能。",
					img: "/img/article/赋能先锋优秀奖-北京雪迪龙科技股份有限公司.png",
				},
				{
					name: "深圳市深时机电有限公司",
					description:"深圳市深时机电有限公司成立于1995年1月，深时作为德国西门子公司战略级系统集成商、国家高新技术企业、和深圳市专精特新企业，凭借雄厚的技术实力和多年来在业内建立良好的口碑，致力于工业自动化工程的成套设计、开发与调试及自动化产品的营销，常年与新能源、物流、电子装备等行业头部企业合作，为各行业客户提供自动化完整解决方案。致力于在2030年前实现碳达峰，2025年实现供应链30%碳减排，赋能多家客户获得了绿色工厂/产品认证。在能效管控、碳资产管理等领域与西门子深度合作。在物流行业实现减碳15%，提升物流立体仓库生产效率20%。",
					img: "/img/article/赋能先锋优秀奖-深圳市深时机电有限公司.png",
				},
				{
					name: "重庆旭富自动化科技有限公司",
					description:"重庆旭富自动化科技有限公司是马来西亚SWIFT在中国的全资子公司，在食品粮油板块是各大工程总承包方的首选自控服务商。致力于在2030年前实现碳达峰，实现供应链0碳排放，应用西门子平台，致力服务客户建设低碳工厂/绿色工厂。在能效管理、碳资产管理领域与西门子深度合作，在粮油加工企业实现减碳10%左右，预计可以带来3%-5%的电量节约，可以有效提高能源效率，减少碳排放。",
					img: "/img/article/赋能先锋优秀奖-重庆旭富自动化科技有限公司.png",
				},
				{
					name: "信恒工业自动化（银川）有限公司",
					description:"信恒工业自动化（银川）有限公司成立于2021年，位于宁夏高新技术创业服务中心A区，是一家致力于提供能源矿产行业自动化技术应用与配套信息化系统软硬件开发解决方案的高新科技企业。致力于帮助煤炭企业减少碳排放，2023年助力企业实现部分碳减排。在井下局部通风机、煤矿机房无人值守、井下主排水、清洁热源锅炉系统等领域和西门子开展深入合作。",
					img: "/img/article/赋能先锋优秀奖-信恒工业自动化（银川）有限公司.png",
				},
				{
					name: "四川思达能环保科技有限公司",
					description:"四川思达能环保科技有限公司是一家专业从事膜及膜分离技术的环保高新技术集团企业，主要服务于新能源材料全产业链。将西门子1500/Smart应用到公司典型机型浓缩机/收尘器/水处理/尘硝一体化设备上，帮助用户实现节能减排。每年累计为客户单位减少碳排放超270万吨，平均排放减少20%。年回收有价物料超1800吨，回收物料价值超2.9亿元，平均每吨价值16万元。高度集成的一体化设计，可直接节约用地超3000亩，平均节约用地30%。",
					img: "/img/article/赋能先锋优秀奖-四川思达能环保科技有限公司.png",
				},
				{
					name: "成都英德生物医药装备技术有限公司",
					description:"成都英德生物医药装备技术有限公司是国内一流的制药工程整体方案解决服务商，服务领域涵盖：医药工程设计及咨询服务；高标准卫生级容器及模块化工艺装备的研发、设计、制造及验证；制药工程建设及验证；制药工程自动化及数字化实施。致力于在2030年前实现碳达峰，实现生物制药行业5%碳减排。在能效管控、碳资产管理等领域与西门子深度合作。在生物制药行业结合西门子产品实现节能减碳，为某生物制药公司空调水系统节能7.82%。",
					img: "/img/article/赋能先锋优秀奖-成都英德生物医药装备技术有限公司.png",
				}
				
			]
		},
			{


			title: "运营先锋优秀奖（含入围卓越奖终选企业）",
			subList: [
				{
					name: "南京朗驰智能控制系统有限公司",
					description:"南京朗驰智能控制系统有限公司致力于成为受客户信赖的、行业领先的智能制造服务提供商，主营业务包括智能制造解决方案、自动化工程、控制柜设计与生产，旗下的控制电柜工厂是西门子ICP集成控制电柜赋能及展示工厂。公司以“让工业更智能”为使命，为企业客户向工业数字化、智能化转型提供完整解决方案和服务。",
					img: "/img/article/运营先锋优秀奖（入围卓越奖终选企业）-南京朗驰智能控制系统有限公司.png",
				}, 
				{
					name: "人本股份有限公司",
					description:"人本股份有限公司是一家以轴承制造为主导产业的民营企业，产业覆盖9大生产基地以及全国与全球主要产品消费市场的直销网络，可生产各类轴承30,000余种。致力于在2030年前实现碳达峰，旗下温州人本轴承有限公司和温州静音轴承有限公司获得了绿色工厂认证。在工业电机、齿轮箱、医疗及轨道交通领域与西门子深度合作。在轴承生产制造环节实行减碳措施，以支持客户共同达可持续发展目标。",
					img: "/img/article/运营先锋优秀奖（入围卓越奖终选企业）-C&U 人本集团.png",
				},
				{
					name: "大同齿轮传动（昆山）股份有限公司",
					description:"大同齿轮传动（昆山）股份有限公司是一家专注于齿轮传动高端制造的高新技术企业。致力于在2025年前实现10%碳減排，旗下一款用于自动化机械手臂的齿轮轴取得TÜV南德意志集团认证的产品碳足迹核查声明。大同齿轮与西门子合作的领域是齿轮精加工上的低碳可持续发展; 运用西门子PLC产品实现碳排放数据的实时统计和精准管控，并协助大同齿轮在齿轮精加工领域减排5%，提升生成效率10%。",
					img: "/img/article/运营先锋优秀奖（入围卓越奖终选企业）-大同齿轮传动（昆山）股份有限公司.png",
				},
                {
					name: "温州贵派电器有限公司",
					description:"温州贵派电器有限公司是电工配电领域的高新技术企业，产品覆盖电工、智能家居、模具等领域。致力于在2030年前实现碳达峰，2025年实现供应链16%碳减排，旗下V10-86B获得了产品认证。在电工领域与西门子深度合作。在电工行业实现减碳490吨，提升注塑生成效率4%、成品装配率3%。",
					img: "/img/article/运营先锋优秀奖（入围卓越奖终选企业）-温州贵派电器有限公司-.png",
				},
				{
					name: "苏州铜盟电气有限公司",
					description:"苏州铜盟电气有限公司是新能源技术服务领域的高新技术企业，产品覆盖电力、风电和电动汽车领域。致力于在2030年前实现碳达峰，2027年实现供应链7%碳减排。 获得ISO14001, ISO45001,ISO9000, IS050001体系认证。在电力电网&风力发电领域与西门子深度合作。在电力电网行业实现减碳节能目标，提升能源生成效率35%。",
					img: "/img/article/运营先锋优秀奖（入围卓越奖终选企业）-苏州铜盟电气有限公司.png",
				},
				{
					name: "陕西斯瑞新材料股份有限公司",
					description:"斯瑞新材专注于高性能铜基合金的研发与制造，中压真空铜铬电触头市场占有率第一，轨道交通、医疗影像等产品处于国内领跑、国际领先地位。公司致力于2030年实现碳达峰，2050年实现碳中和；于2020年获得国家级绿色工厂称号。在电力、轨道交通、医疗、能源领域与西门子深度合作，在电力、轨交、医疗领域实现减碳35%，用能效率提升4.0%。",
					img: "/img/article/运营先锋优秀奖（入围卓越奖终选企业）-陕西斯瑞新材料股份有限公司.png",

					
				}]
		}

	]
}