import axios from 'axios'
import sha1 from 'sha1'
import { getLang } from '@/utils/lang.js'

export default class HttpRequest {
  constructor (options) {
    this.defaults = {
      baseURL: '',
      timeout: 30 * 1000
      // headers: {'X-Custom-Header': 'foobar'}
    }
    this.defaults = Object.assign(this.defaults, options)
  }

  setConfig () { }

  request (options) {
    options = Object.assign(this.defaults, options)
    const instance = axios.create(options)
    // this.interceptors(instance)

    // Add a request interceptor
    instance.interceptors.request.use(
      (config) => {
        // 添加多语言 header
        // let lang = localStorage.getItem('lang')
        // if (lang) {
        //   config.headers.language = lang === 'zh' ? 'zh-cn' : 'en-us'
        // } else {
        //   const language = ((navigator.language ? navigator.language : navigator.userLanguage) || 'zh').toLowerCase()
        //   lang = language.split('-')[0]
        //   config.headers.language = lang === 'zh' ? 'zh-cn' : 'en-us'
        // }
        // lang = getLang()
        if (config.params?.lang) {
          config.headers.language = config.params?.lang
        } else if (config.data?.lang) {
          config.headers.language = config.data.lang
          delete config.data.lang
        } else {
          config.headers.language = getLang() === 'zh' ? 'zh-cn' : 'en-us'
        }
        const eventId = localStorage.getItem('eventId')
        if (eventId) {
          config.headers.event = eventId
        }
        const token = localStorage.getItem('token')
        // const refrishTime = localStorage.getItem('refrishTime')
        // 判断是否存在token，如果存在的话，则每个http header都加上token
        if (token) {
          const reFreshConfig = new Promise((resolve, reject) => {
            document.cookie = 'Token' + '=' + token + '; '
            config.headers.token = `${token}`
            resolve(config)
            // if (refrishTime && parseInt(refrishTime) < new Date().getTime()) {
            //   const rToken = localStorage.getItem('rToken')
            //   const obj = { appId: '221027', checkStr: '852d297b0eefbfa8ce05b9befbb71b2d' }
            //   axios.get(`/marketplace-common/auth/refresh/tokens/v2?refreshToken=${rToken}`, {
            //     params: obj,
            //     headers: {
            //       sign: sha1(JSON.stringify(sortASCII({ ...obj, 'refreshToken': rToken }))).toLocaleUpperCase()
            //     }
            //   }).then(function (response) {
            //     const { data } = response
            //     if (data?.data?.token && data?.data?.refreshToken) {
            //       const newRefrishTime = new Date().getTime() + (55 * 60 * 1000) // 设定定时刷新时间，一小时减5分钟后
            //       localStorage.setItem('token', data.data.token)
            //       localStorage.setItem('rToken', data.data.refreshToken)
            //       localStorage.setItem('refrishTime', newRefrishTime)
            //       config.headers.token = data.data.token
            //       resolve(config)
            //     } else {
            //       config.headers.token = `${token}`
            //       resolve(config)
            //     }
            //   })
            // } else {
            //   config.headers.token = `${token}`
            //   resolve(config)
            // }
          })
          return reFreshConfig
        } else {
          const retry = new Promise((resolve, reject) => {
            // 密钥签名
            const obj = { deviceId: window.gdp('getDeviceId'), appId: process.env.VUE_APP_APPID, checkStr: '852d297b0eefbfa8ce05b9befbb71b2d', timestamp: `${new Date().getTime()}` }
            const strASCII = sortASCII(obj)
            const signParam = sha1(JSON.stringify(strASCII)).toLocaleUpperCase()

            axios.get('/marketplace-common/auth/tokens/v2', {
              params: obj,
              headers: {
                sign: signParam
              }
            }).then(function (response) {
              const { data } = response
              if (data.code === 200) {
                // const refrishTime = new Date().getTime() + (55 * 60 * 1000) // 设定定时刷新时间，一小时减5分钟后
                // localStorage.setItem('refrishTime', refrishTime)
                localStorage.setItem('token', data.data)
                // localStorage.setItem('rToken', data.data.refreshToken)
                // Cookie中存token，并设置24小时有效
                const arrCookie = document.cookie.split('; ')
                let isExpires = false
                for (let i = 0; i < arrCookie.length; i++) {
                  const val = arrCookie[i].split('=')
                  if (val[0] === 'marketplace_00091800_u_id') {
                    isExpires = true
                    break
                  }
                }
                if (!isExpires) {
                  const d = new Date()
                  d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000))
                  const expires = 'expires=' + d.toUTCString()
                  document.cookie = 'marketplace_00091800_u_id' + '=' + data.data + '; ' + expires
                  document.cookie = 'Token' + '=' + data.data + '; '
                }

                config.headers.token = `${data.data}`
                config.headers['Content-Type'] = 'application/json'
                resolve(config)
              } else { // 403 没有访问权限
                console.log('token error', data)
                resolve(config)
              }
            })
              .catch(function (error) {
                reject(error)
              })
          })
          return retry
        }
        // return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
    // Add a response interceptor
    instance.interceptors.response.use(
      (res) => {
        /* 注意查看结构 */
        const { data } = res

        if (data.code === 401) {
          localStorage.removeItem('token')
          const config = res.config
          return instance(config)
        } else {
          return data
        }
      },
      (err) => {
        return Promise.reject(err)
      }
    )
    function sortASCII (jsonObj) {
      const arr = []
      let num = 0
      for (const i in jsonObj) {
        arr[num] = i
        num++
      }
      const sortArr = arr.sort()
      const sortObj = {}
      for (const i in sortArr) {
        sortObj[sortArr[i]] = jsonObj[sortArr[i]]
      }

      return sortObj
    }

    return instance
  }
}
