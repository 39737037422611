<template>
    <transition>
      <div class="message-container" :style="style[type]">
        <div>{{ msg }}</div>
      </div>
    </transition>
  </template>
<script>
export default {
  name: 'commonMessage',
  props: {
    type: {
      type: String,
      default: 'success'
    },
    top: {
      type: Number,
      default: 20
    },
    fontSize: {
      type: Number,
      default: 14
    },
    msg: {
      type: String
    }
  },
  setup (props) {
    // 三种情况的样式，对象key就是类型字符串
    const style = {
      warning: {
        color: '#e6a23c',
        backgroundColor: '#fdf6ec',
        borderColor: '#faecd8',
        top: props.top + 'px',
        fontSize: props.fontSize + 'px',
      },
      error: {
        color: '#f56c6c',
        backgroundColor: '#fef0f0',
        borderColor: '#fde2e2',
        top: props.top + 'px',
        fontSize: props.fontSize + 'px',
      },
      success: {
        color: '#67c23a',
        backgroundColor: '#f0f9eb',
        borderColor: '#e1f3d8',
        top: props.top + 'px',
        fontSize: props.fontSize + 'px',
      }
    }
    return { style }
  }
}
</script>
<style lang="scss" scoped>
  .v-enter-active, .v-leave-active {
    transition: all 1s;
  }
  .v-enter, .v-leave-to {
    opacity: 0;
  }
  .message-container {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100px;
    padding: 5px 15px;
    border-radius: 4px;
    z-index: 9999;
    color: #ffffff;
    background-color: rgb(0, 0, 40);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    font-size: 14px;
  }
</style>
