const schools = [
  {
    province_name: '北京市',
    cities: [
      {
        city_name: '北京市',
        universities: [
          '北京大学',
          '中国人民大学',
          '清华大学',
          '北京交通大学',
          '北京工业大学',
          '北京航空航天大学',
          '北京理工大学',
          '北京科技大学',
          '北方工业大学',
          '北京化工大学',
          '北京工商大学',
          '北京服装学院',
          '北京邮电大学',
          '北京印刷学院',
          '北京建筑大学',
          '北京石油化工学院',
          '北京电子科技学院',
          '中国农业大学',
          '北京农学院',
          '北京林业大学',
          '北京协和医学院',
          '首都医科大学',
          '北京中医药大学',
          '北京师范大学',
          '首都师范大学',
          '首都体育学院',
          '北京外国语大学',
          '北京第二外国语学院',
          '北京语言大学',
          '中国传媒大学',
          '中央财经大学',
          '对外经济贸易大学',
          '北京物资学院',
          '首都经济贸易大学',
          '外交学院',
          '中国人民公安大学',
          '国际关系学院',
          '北京体育大学',
          '中央音乐学院',
          '中国音乐学院',
          '中央美术学院',
          '中央戏剧学院',
          '中国戏曲学院',
          '北京电影学院',
          '北京舞蹈学院',
          '中央民族大学',
          '中国政法大学',
          '华北电力大学',
          '中华女子学院',
          '北京信息科技大学',
          '中国矿业大学（北京）',
          '中国石油大学（北京）',
          '中国地质大学（北京）',
          '北京联合大学',
          '北京城市学院',
          '中国青年政治学院',
          '首钢工学院',
          '中国劳动关系学院',
          '北京吉利学院',
          '首都师范大学科德学院',
          '北京工商大学嘉华学院',
          '北京邮电大学世纪学院',
          '北京工业大学耿丹学院',
          '北京警察学院',
          '北京第二外国语学院中瑞酒店管理学院',
          '中国科学院大学',
          '中国社会科学院大学',
          '北京工业职业技术学院',
          '北京信息职业技术学院',
          '北京电子科技职业学院',
          '北京京北职业技术学院',
          '北京交通职业技术学院',
          '北京青年政治学院',
          '北京农业职业学院',
          '北京政法职业学院',
          '北京财贸职业学院',
          '北京北大方正软件职业技术学院',
          '北京经贸职业学院',
          '北京经济技术职业学院',
          '北京戏曲艺术职业学院',
          '北京汇佳职业学院',
          '北京科技经营管理学院',
          '北京科技职业学院',
          '北京培黎职业学院',
          '北京经济管理职业学院',
          '北京劳动保障职业学院',
          '北京社会管理职业学院',
          '北京艺术传媒职业学院',
          '北京体育职业学院',
          '北京交通运输职业学院',
          '北京卫生职业学院',
          '北京网络职业学院'
        ]
      }
    ]
  },
  {
    province_name: '天津市',
    cities: [
      {
        city_name: '天津市',
        universities: [
          '南开大学',
          '天津大学',
          '天津科技大学',
          '天津工业大学',
          '中国民航大学',
          '天津理工大学',
          '天津农学院',
          '天津医科大学',
          '天津中医药大学',
          '天津师范大学',
          '天津职业技术师范大学',
          '天津外国语大学',
          '天津商业大学',
          '天津财经大学',
          '天津体育学院',
          '天津音乐学院',
          '天津美术学院',
          '天津城建大学',
          '天津天狮学院',
          '天津中德应用技术大学',
          '天津外国语大学滨海外事学院',
          '天津体育学院运动与文化艺术学院',
          '天津商业大学宝德学院',
          '天津医科大学临床医学院',
          '南开大学滨海学院',
          '天津师范大学津沽学院',
          '天津理工大学中环信息学院',
          '北京科技大学天津学院',
          '天津大学仁爱学院',
          '天津财经大学珠江学院',
          '天津市职业大学',
          '天津滨海职业学院',
          '天津工程职业技术学院',
          '天津青年职业学院',
          '天津渤海职业技术学院',
          '天津电子信息职业技术学院',
          '天津机电职业技术学院',
          '天津现代职业技术学院',
          '天津公安警官职业学院',
          '天津轻工职业技术学院',
          '天津商务职业学院',
          '天津国土资源和房屋职业学院',
          '天津医学高等专科学校',
          '天津开发区职业技术学院',
          '天津艺术职业学院',
          '天津交通职业学院',
          '天津冶金职业技术学院',
          '天津石油职业技术学院',
          '天津城市职业学院',
          '天津铁道职业技术学院',
          '天津工艺美术职业学院',
          '天津城市建设管理职业技术学院',
          '天津生物工程职业技术学院',
          '天津海运职业学院',
          '天津广播影视职业学院',
          '天津体育职业学院',
          '天津滨海汽车工程职业学院'
        ]
      }
    ]
  },
  {
    province_name: '河北省',
    cities: [
      {
        city_name: '邯郸市',
        universities: [
          '邯郸学院',
          '河北工程大学科信学院',
          '邯郸职业技术学院',
          '河北司法警官职业学院',
          '河北工程大学'
        ]
      },
      {
        city_name: '石家庄市',
        universities: [
          '河北科技大学',
          '河北医科大学',
          '河北师范大学',
          '石家庄学院',
          '石家庄铁道大学',
          '河北体育学院',
          '河北经贸大学',
          '河北传媒学院',
          '河北外国语学院',
          '河北科技大学理工学院',
          '石家庄铁道大学四方学院',
          '河北中医学院',
          '河北工业职业技术学院',
          '石家庄职业技术学院',
          '河北政法职业学院',
          '石家庄铁路职业技术学院',
          '石家庄工程职业学院',
          '河北省艺术职业学院',
          '石家庄财经职业学院',
          '石家庄邮电职业技术学院',
          '河北女子职业技术学院',
          '石家庄人民医学高等专科学校',
          '石家庄幼儿师范高等专科学校',
          '河北轨道运输职业技术学院',
          '河北地质大学'
        ]
      },
      {
        city_name: '天津市',
        universities: [
          '河北工业大学'
        ]
      },
      {
        city_name: '唐山市',
        universities: [
          '唐山师范学院',
          '唐山学院',
          '华北理工大学轻工学院',
          '华北理工大学冀唐学院',
          '河北能源职业技术学院',
          '唐山职业技术学院',
          '唐山工业职业技术学院',
          '唐山科技职业技术学院',
          '唐山幼儿师范高等专科学校',
          '华北理工大学'
        ]
      },
      {
        city_name: '张家口市',
        universities: [
          '河北北方学院',
          '张家口学院',
          '张家口职业技术学院',
          '宣化科技职业学院',
          '河北建筑工程学院'
        ]
      },
      {
        city_name: '沧州市',
        universities: [
          '沧州师范学院',
          '北京交通大学海滨学院',
          '沧州职业技术学院',
          '渤海石油职业学院',
          '沧州医学高等专科学校',
          '泊头职业学院',
          '渤海理工职业学院',
          '河北水利电力学院'
        ]
      },
      {
        city_name: '承德市',
        universities: [
          '河北民族师范学院',
          '承德石油高等专科学校',
          '河北旅游职业学院',
          '承德护理职业学院',
          '承德医学院'
        ]
      },
      {
        city_name: '廊坊市',
        universities: [
          '华北科技学院',
          '北华航天工业学院',
          '河北工业大学城市学院',
          '燕京理工学院',
          '河北东方学院',
          '河北石油职业技术学院',
          '廊坊职业技术学院',
          '廊坊燕京职业技术学院',
          '廊坊卫生职业学院',
          '廊坊师范学院'
        ]
      },
      {
        city_name: '衡水市',
        universities: [
          '衡水职业技术学院',
          '衡水学院'
        ]
      },
      {
        city_name: '邢台市',
        universities: [
          '邢台职业技术学院',
          '邢台医学高等专科学校',
          '河北机电职业技术学院',
          '邢台学院'
        ]
      },
      {
        city_name: '秦皇岛市',
        universities: [
          '燕山大学里仁学院',
          '河北环境工程学院',
          '河北建材职业技术学院',
          '秦皇岛职业技术学院',
          '河北对外经贸职业学院',
          '燕山大学'
        ]
      },
      {
        city_name: '保定市',
        universities: [
          '河北农业大学',
          '保定学院',
          '河北大学',
          '河北科技师范学院',
          '河北金融学院',
          '中国人民武装警察部队学院',
          '中央司法警官学院',
          '防灾科技学院',
          '河北工程技术学院',
          '河北科技学院',
          '河北大学工商学院',
          '河北美术学院',
          '河北师范大学汇华学院',
          '河北经贸大学经济管理学院',
          '华北电力大学科技学院',
          '河北医科大学临床学院',
          '河北农业大学现代科技学院',
          '中国地质大学长城学院',
          '河北地质大学华信学院',
          '河北软件职业技术学院',
          '保定职业技术学院',
          '北京中医药大学东方学院',
          '石家庄城市经济职业学院',
          '河北交通职业技术学院',
          '河北化工医药职业技术学院',
          '保定电力职业技术学院',
          '石家庄信息工程职业学院',
          '河北公安警察职业学院',
          '石家庄工商职业学院',
          '石家庄理工职业学院',
          '石家庄科技信息职业学院',
          '石家庄医学高等专科学校',
          '冀中职业学院',
          '石家庄经济职业学院',
          '石家庄科技工程职业学院',
          '河北劳动关系职业学院',
          '保定幼儿师范高等专科学校',
          '石家庄科技职业学院',
          '河北工艺美术职业学院',
          '曹妃甸职业技术学院'
        ]
      }
    ]
  },
  {
    province_name: '山西省',
    cities: [
      {
        city_name: '晋中市',
        universities: [
          '晋中学院',
          '山西农业大学信息学院',
          '山西能源学院',
          '山西同文职业技术学院',
          '晋中师范高等专科学校',
          '山西农业大学'
        ]
      },
      {
        city_name: '长治市',
        universities: [
          '长治学院',
          '长治职业技术学院',
          '山西机电职业技术学院',
          '潞安职业技术学院',
          '长治医学院'
        ]
      },
      {
        city_name: '临汾市',
        universities: [
          '山西师范大学现代文理学院',
          '临汾职业技术学院',
          '山西信息职业技术学院',
          '山西管理职业学院',
          '山西师范大学'
        ]
      },
      {
        city_name: '大同市',
        universities: [
          '大同煤炭职业技术学院',
          '山西大同大学'
        ]
      },
      {
        city_name: '运城市',
        universities: [
          '山西水利职业技术学院',
          '山西运城农业职业技术学院',
          '运城职业技术学院',
          '运城护理职业学院',
          '运城学院'
        ]
      },
      {
        city_name: '忻州市',
        universities: [
          '忻州职业技术学院',
          '忻州师范学院'
        ]
      },
      {
        city_name: '吕梁市',
        universities: [
          '吕梁职业技术学院',
          '吕梁学院'
        ]
      },
      {
        city_name: '阳泉市',
        universities: [
          '阳泉职业技术学院',
          '阳泉师范高等专科学校',
          '山西工程技术学院'
        ]
      },
      {
        city_name: '晋城市',
        universities: [
          '晋城职业技术学院'
        ]
      },
      {
        city_name: '朔州市',
        universities: [
          '朔州师范高等专科学校',
          '朔州职业技术学院'
        ]
      },
      {
        city_name: '太原市',
        universities: [
          '山西大学',
          '太原科技大学',
          '中北大学',
          '山西医科大学',
          '太原师范学院',
          '山西财经大学',
          '太原理工大学',
          '太原学院',
          '山西中医药大学',
          '山西警察学院',
          '山西应用科技学院',
          '山西大学商务学院',
          '中北大学信息商务学院',
          '太原理工大学现代科技学院',
          '太原科技大学华科学院',
          '山西医科大学晋祠学院',
          '山西财经大学华商学院',
          '山西工商学院',
          '太原工业学院',
          '山西省财政税务专科学校',
          '山西艺术职业学院',
          '山西建筑职业技术学院',
          '山西传媒学院',
          '山西药科职业学院',
          '山西工程职业技术学院',
          '山西戏剧职业学院',
          '山西交通职业技术学院',
          '山西财贸职业技术学院',
          '山西职业技术学院',
          '山西林业职业技术学院',
          '山西煤炭职业技术学院',
          '山西金融职业学院',
          '山西体育职业学院',
          '太原城市职业技术学院',
          '山西警官职业学院',
          '太原旅游职业学院',
          '山西国际商务职业学院',
          '山西电力职业技术学院',
          '山西旅游职业学院',
          '晋中职业技术学院',
          '山西华澳商贸职业学院',
          '山西老区职业技术学院',
          '运城幼儿师范高等专科学校',
          '山西轻工职业技术学院',
          '山西青年职业学院',
          '山西经贸职业学院',
          '运城师范高等专科学校'
        ]
      }
    ]
  },
  {
    province_name: '内蒙古自治区',
    cities: [
      {
        city_name: '包头市',
        universities: [
          '包头职业技术学院',
          '包头轻工职业技术学院',
          '包头钢铁职业技术学院',
          '包头铁道职业技术学院',
          '内蒙古科技大学'
        ]
      },
      {
        city_name: '通辽市',
        universities: [
          '通辽职业学院',
          '内蒙古民族大学'
        ]
      },
      {
        city_name: '赤峰市',
        universities: [
          '内蒙古交通职业技术学院',
          '赤峰职业技术学院',
          '赤峰工业职业技术学院',
          '赤峰学院'
        ]
      },
      {
        city_name: '呼伦贝尔市',
        universities: [
          '呼伦贝尔职业技术学院',
          '扎兰屯职业学院',
          '呼伦贝尔学院'
        ]
      },
      {
        city_name: '乌兰察布市',
        universities: [
          '乌兰察布职业学院',
          '乌兰察布医学高等专科学校',
          '集宁师范学院'
        ]
      },
      {
        city_name: '巴彦淖尔市',
        universities: [
          '内蒙古美术职业学院',
          '河套学院'
        ]
      },
      {
        city_name: '鄂尔多斯市',
        universities: [
          '鄂尔多斯职业学院',
          '内蒙古民族幼儿师范高等专科学校',
          '鄂尔多斯应用技术学院'
        ]
      },
      {
        city_name: '兴安盟',
        universities: [
          '兴安职业技术学院'
        ]
      },
      {
        city_name: '锡林郭勒盟',
        universities: [
          '锡林郭勒职业学院'
        ]
      },
      {
        city_name: '乌海市',
        universities: [
          '乌海职业技术学院'
        ]
      },
      {
        city_name: '阿拉善盟',
        universities: [
          '阿拉善职业技术学院'
        ]
      },
      {
        city_name: '呼和浩特市',
        universities: [
          '内蒙古工业大学',
          '内蒙古大学',
          '内蒙古农业大学',
          '内蒙古医科大学',
          '内蒙古财经大学',
          '呼和浩特民族学院',
          '内蒙古师范大学',
          '内蒙古大学创业学院',
          '内蒙古师范大学鸿德学院',
          '内蒙古建筑职业技术学院',
          '内蒙古艺术学院',
          '呼和浩特职业学院',
          '内蒙古电子信息职业技术学院',
          '内蒙古丰州职业学院',
          '内蒙古机电职业技术学院',
          '内蒙古化工职业学院',
          '内蒙古警察职业学院',
          '内蒙古商贸职业学院',
          '内蒙古体育职业学院',
          '内蒙古科技职业学院',
          '科尔沁艺术职业学院',
          '内蒙古经贸外语职业学院',
          '内蒙古工业职业学院',
          '内蒙古北方职业技术学院',
          '内蒙古能源职业学院',
          '满洲里俄语职业学院',
          '鄂尔多斯生态环境职业学院'
        ]
      }
    ]
  },
  {
    province_name: '辽宁省',
    cities: [
      {
        city_name: '大连市',
        universities: [
          '大连交通大学',
          '大连海洋大学',
          '大连医科大学',
          '辽宁师范大学',
          '大连外国语大学',
          '辽宁对外经贸学院',
          '大连大学',
          '辽宁警察学院',
          '大连民族大学',
          '大连工业大学艺术与信息工程学院',
          '大连医科大学中山学院',
          '大连财经学院',
          '大连艺术学院',
          '大连东软信息学院',
          '大连职业技术学院',
          '辽宁税务高等专科学校',
          '大连商务职业学院',
          '大连软件职业学院',
          '大连枫叶职业技术学院',
          '大连航运职业技术学院',
          '辽宁轻工职业学院',
          '大连理工大学'
        ]
      },
      {
        city_name: '鞍山市',
        universities: [
          '鞍山师范学院',
          '辽宁科技大学'
        ]
      },
      {
        city_name: '阜新市',
        universities: [
          '阜新高等专科学校',
          '辽宁工程技术大学'
        ]
      },
      {
        city_name: '抚顺市',
        universities: [
          '辽宁石油化工大学顺华能源学院',
          '抚顺师范高等专科学校',
          '抚顺职业技术学院',
          '辽宁石油化工大学'
        ]
      },
      {
        city_name: '锦州市',
        universities: [
          '锦州医科大学',
          '渤海大学',
          '锦州医科大学医疗学院',
          '辽宁理工学院',
          '锦州师范高等专科学校',
          '辽宁理工职业学院',
          '辽宁石化职业技术学院',
          '辽宁铁道职业技术学院',
          '辽宁工业大学'
        ]
      },
      {
        city_name: '本溪市',
        universities: [
          '辽宁冶金职业技术学院',
          '辽宁科技学院'
        ]
      },
      {
        city_name: '丹东市',
        universities: [
          '辽宁机电职业技术学院',
          '辽宁地质工程职业学院',
          '辽东学院'
        ]
      },
      {
        city_name: '辽阳市',
        universities: [
          '辽阳职业技术学院',
          '辽宁建筑职业学院',
          '沈阳工业大学工程学院'
        ]
      },
      {
        city_name: '葫芦岛市',
        universities: [
          '渤海船舶职业学院',
          '辽宁财贸学院'
        ]
      },
      {
        city_name: '营口市',
        universities: [
          '营口职业技术学院',
          '辽宁农业职业技术学院',
          '营口理工学院'
        ]
      },
      {
        city_name: '朝阳市',
        universities: [
          '朝阳师范高等专科学校'
        ]
      },
      {
        city_name: '铁岭市',
        universities: [
          '辽宁职业学院',
          '辽宁工程职业学院',
          '铁岭卫生职业学院',
          '铁岭师范高等专科学校'
        ]
      },
      {
        city_name: '盘锦市',
        universities: [
          '辽河石油职业技术学院',
          '盘锦职业技术学院'
        ]
      },
      {
        city_name: '沈阳市',
        universities: [
          '沈阳工业大学',
          '辽宁大学',
          '沈阳航空航天大学',
          '沈阳理工大学',
          '沈阳化工大学',
          '东北大学',
          '大连海事大学',
          '沈阳建筑大学',
          '沈阳农业大学',
          '中国医科大学',
          '辽宁中医药大学',
          '大连工业大学',
          '沈阳药科大学',
          '沈阳师范大学',
          '沈阳医学院',
          '中国刑事警察学院',
          '东北财经大学',
          '沈阳体育学院',
          '沈阳音乐学院',
          '沈阳大学',
          '沈阳工程学院',
          '鲁迅美术学院',
          '沈阳航空航天大学北方科技学院',
          '大连理工大学城市学院',
          '沈阳工学院',
          '沈阳城市建设学院',
          '大连科技学院',
          '辽宁师范大学海华学院',
          '沈阳城市学院',
          '辽宁中医药大学杏林学院',
          '中国医科大学临床医药学院',
          '辽宁何氏医学院',
          '辽宁传媒学院',
          '辽宁省交通高等专科学校',
          '沈阳航空职业技术学院',
          '沈阳科技学院',
          '辽宁林业职业技术学院',
          '辽宁体育运动职业技术学院',
          '辽宁金融职业学院',
          '沈阳职业技术学院',
          '辽宁轨道交通职业学院',
          '辽宁经济职业技术学院',
          '辽宁广告职业学院',
          '辽宁商贸职业学院',
          '辽宁装备制造职业技术学院',
          '大连翻译职业学院',
          '大连装备制造职业技术学院',
          '辽宁现代服务职业技术学院',
          '辽宁城市建设职业技术学院',
          '大连汽车职业技术学院',
          '沈阳北软信息职业技术学院',
          '辽宁医药职业学院',
          '辽宁政法职业学院',
          '辽宁水利职业学院',
          '辽宁民族师范高等专科学校',
          '辽宁特殊教育师范高等专科学校'
        ]
      }
    ]
  },
  {
    province_name: '吉林省',
    cities: [
      {
        city_name: '延边朝鲜族自治州',
        universities: [
          '延边职业技术学院',
          '吉林职业技术学院',
          '延边大学'
        ]
      },
      {
        city_name: '吉林市',
        universities: [
          '吉林化工学院',
          '北华大学',
          '吉林农业科技学院',
          '吉林医药学院',
          '吉林电子信息职业技术学院',
          '吉林铁道职业技术学院',
          '东北电力大学'
        ]
      },
      {
        city_name: '通化市',
        universities: [
          '通化师范学院'
        ]
      },
      {
        city_name: '四平市',
        universities: [
          '吉林师范大学博达学院',
          '四平职业大学',
          '吉林工程职业学院',
          '吉林师范大学'
        ]
      },
      {
        city_name: '白城市',
        universities: [
          '白城医学高等专科学校',
          '白城职业技术学院',
          '白城师范学院'
        ]
      },
      {
        city_name: '辽源市',
        universities: [
          '辽源职业技术学院'
        ]
      },
      {
        city_name: '松原市',
        universities: [
          '松原职业技术学院'
        ]
      },
      {
        city_name: '白山市',
        universities: [
          '长白山职业技术学院'
        ]
      },
      {
        city_name: '长春市',
        universities: [
          '长春理工大学',
          '长春工业大学',
          '吉林大学',
          '吉林农业大学',
          '吉林建筑大学',
          '长春中医药大学',
          '吉林工程技术师范学院',
          '东北师范大学',
          '吉林财经大学',
          '长春师范大学',
          '吉林体育学院',
          '吉林艺术学院',
          '吉林华桥外国语学院',
          '吉林工商学院',
          '吉林警察学院',
          '长春工程学院',
          '长春大学',
          '长春光华学院',
          '长春工业大学人文信息学院',
          '长春理工大学光电信息学院',
          '长春财经学院',
          '吉林建筑大学城建学院',
          '长春建筑学院',
          '长春科技学院',
          '长春大学旅游学院',
          '吉林动画学院',
          '长春师范高等专科学校',
          '长春汽车工业高等专科学校',
          '东北师范大学人文学院',
          '长春金融高等专科学校',
          '长春医学高等专科学校',
          '吉林交通职业技术学院',
          '长春东方职业学院',
          '吉林司法警官职业学院',
          '长春职业技术学院',
          '长春信息技术职业学院',
          '吉林科技职业技术学院',
          '吉林城市职业技术学院',
          '吉林水利电力职业学院',
          '吉林工业职业技术学院',
          '长春健康职业学院'
        ]
      }
    ]
  },
  {
    province_name: '黑龙江省',
    cities: [
      {
        city_name: '大庆市',
        universities: [
          '黑龙江八一农垦大学',
          '大庆师范学院',
          '大庆职业学院',
          '大庆医学高等专科学校',
          '东北石油大学'
        ]
      },
      {
        city_name: '佳木斯市',
        universities: [
          '黑龙江农业职业技术学院',
          '黑龙江三江美术职业学院',
          '佳木斯职业学院',
          '佳木斯大学'
        ]
      },
      {
        city_name: '牡丹江市',
        universities: [
          '牡丹江师范学院',
          '牡丹江大学',
          '黑龙江林业职业技术学院',
          '黑龙江农业经济职业学院',
          '黑龙江商业职业学院',
          '黑龙江幼儿师范高等专科学校',
          '牡丹江医学院'
        ]
      },
      {
        city_name: '齐齐哈尔市',
        universities: [
          '齐齐哈尔医学院',
          '齐齐哈尔工程学院',
          '齐齐哈尔高等师范专科学校',
          '黑龙江交通职业技术学院',
          '齐齐哈尔理工职业学院',
          '齐齐哈尔大学'
        ]
      },
      {
        city_name: '绥化市',
        universities: [
          '绥化学院'
        ]
      },
      {
        city_name: '鸡西市',
        universities: [
          '黑龙江工业学院'
        ]
      },
      {
        city_name: '黑河市',
        universities: [
          '黑河学院'
        ]
      },
      {
        city_name: '伊春市',
        universities: [
          '伊春职业学院'
        ]
      },
      {
        city_name: '鹤岗市',
        universities: [
          '鹤岗师范高等专科学校'
        ]
      },
      {
        city_name: '大兴安岭地区',
        universities: [
          '大兴安岭职业学院'
        ]
      },
      {
        city_name: '双鸭山市',
        universities: [
          '黑龙江能源职业学院'
        ]
      },
      {
        city_name: '七台河市',
        universities: [
          '七台河职业学院'
        ]
      },
      {
        city_name: '哈尔滨市',
        universities: [
          '黑龙江大学',
          '哈尔滨工业大学',
          '哈尔滨理工大学',
          '哈尔滨工程大学',
          '东北农业大学',
          '黑龙江科技大学',
          '东北林业大学',
          '哈尔滨医科大学',
          '哈尔滨师范大学',
          '哈尔滨学院',
          '哈尔滨商业大学',
          '黑龙江中医药大学',
          '哈尔滨体育学院',
          '黑龙江东方学院',
          '哈尔滨金融学院',
          '哈尔滨信息工程学院',
          '黑龙江外国语学院',
          '黑龙江工程学院',
          '黑龙江财经学院',
          '哈尔滨石油学院',
          '黑龙江工商学院',
          '哈尔滨远东理工学院',
          '哈尔滨剑桥学院',
          '黑龙江工程学院昆仑旅游学院',
          '哈尔滨广厦学院',
          '哈尔滨音乐学院',
          '黑龙江职业学院',
          '哈尔滨华德学院',
          '黑龙江建筑职业技术学院',
          '黑龙江农业工程职业学院',
          '黑龙江艺术职业学院',
          '黑龙江农垦职业学院',
          '哈尔滨电力职业技术学院',
          '黑龙江司法警官职业学院',
          '哈尔滨职业技术学院',
          '哈尔滨铁道职业技术学院',
          '哈尔滨传媒职业学院',
          '黑龙江公安警官职业学院',
          '黑龙江生物科技职业学院',
          '黑龙江信息技术职业学院',
          '哈尔滨城市职业学院',
          '黑龙江农垦科技职业学院',
          '黑龙江生态工程职业学院',
          '黑龙江民族职业学院',
          '哈尔滨应用职业技术学院',
          '哈尔滨科学技术职业学院',
          '黑龙江旅游职业技术学院',
          '黑龙江护理高等专科学校',
          '哈尔滨幼儿师范高等专科学校',
          '黑龙江粮食职业学院',
          '黑龙江冰雪体育职业学院'
        ]
      }
    ]
  },
  {
    province_name: '上海市',
    cities: [
      {
        city_name: '上海市',
        universities: [
          '复旦大学',
          '同济大学',
          '上海交通大学',
          '华东理工大学',
          '上海理工大学',
          '上海海事大学',
          '东华大学',
          '上海电力学院',
          '上海应用技术大学',
          '上海健康医学院',
          '上海海洋大学',
          '上海中医药大学',
          '华东师范大学',
          '上海师范大学',
          '上海外国语大学',
          '上海财经大学',
          '上海对外经贸大学',
          '上海海关学院',
          '华东政法大学',
          '上海体育学院',
          '上海音乐学院',
          '上海戏剧学院',
          '上海大学',
          '上海公安学院',
          '上海工程技术大学',
          '上海立信会计金融学院',
          '上海电机学院',
          '上海杉达学院',
          '上海政法学院',
          '上海第二工业大学',
          '上海商学院',
          '上海建桥学院',
          '上海兴伟学院',
          '上海视觉艺术学院',
          '上海外国语大学贤达经济人文学院',
          '上海师范大学天华学院',
          '上海科技大学',
          '上海纽约大学',
          '上海旅游高等专科学校',
          '上海东海职业技术学院',
          '上海工商职业技术学院',
          '上海出版印刷高等专科学校',
          '上海行健职业学院',
          '上海城建职业学院',
          '上海交通职业技术学院',
          '上海海事职业技术学院',
          '上海电子信息职业技术学院',
          '上海震旦职业学院',
          '上海民远职业技术学院',
          '上海欧华职业技术学院',
          '上海思博职业技术学院',
          '上海立达职业技术学院',
          '上海工艺美术职业学院',
          '上海济光职业技术学院',
          '上海工商外国语职业学院',
          '上海科学技术职业学院',
          '上海农林职业技术学院',
          '上海邦德职业技术学院',
          '上海中侨职业技术学院',
          '上海电影艺术职业学院',
          '上海中华职业技术学院',
          '上海工会管理职业学院',
          '上海体育职业学院',
          '上海民航职业技术学院'
        ]
      }
    ]
  },
  {
    province_name: '江苏省',
    cities: [
      {
        city_name: '苏州市',
        universities: [
          '苏州科技大学',
          '苏州大学文正学院',
          '西交利物浦大学',
          '苏州幼儿师范高等专科学校',
          '苏州工艺美术职业技术学院',
          '苏州职业大学',
          '硅湖职业技术学院',
          '苏州经贸职业技术学院',
          '苏州农业职业技术学院',
          '苏州健雄职业技术学院',
          '苏州百年职业学院',
          '苏州高博软件技术职业学院',
          '苏州信息职业技术学院',
          '苏州工业园区服务外包职业学院',
          '苏州大学'
        ]
      },
      {
        city_name: '镇江市',
        universities: [
          '江苏大学',
          '江苏大学京江学院',
          '南京财经大学红山学院',
          '镇江市高等专科学校',
          '江苏农林职业技术学院',
          '金山职业技术学院',
          '江苏航空职业技术学院',
          '江苏科技大学'
        ]
      },
      {
        city_name: '徐州市',
        universities: [
          '徐州医科大学',
          '江苏师范大学',
          '徐州工程学院',
          '中国矿业大学徐海学院',
          '江苏师范大学科文学院',
          '江苏建筑职业技术学院',
          '九州职业技术学院',
          '徐州工业职业技术学院',
          '徐州幼儿师范高等专科学校',
          '江苏安全技术职业学院',
          '中国矿业大学'
        ]
      },
      {
        city_name: '常州市',
        universities: [
          '常州工学院',
          '江苏理工学院',
          '常州信息职业技术学院',
          '常州纺织服装职业技术学院',
          '常州轻工职业技术学院',
          '建东职业技术学院',
          '常州机电职业技术学院',
          '江苏城乡建设职业学院',
          '常州大学'
        ]
      },
      {
        city_name: '无锡市',
        universities: [
          '无锡太湖学院',
          '无锡职业技术学院',
          '无锡科技职业学院',
          '无锡商业职业技术学院',
          '太湖创意职业技术学院',
          '无锡南洋职业技术学院',
          '江苏信息职业技术学院',
          '江阴职业技术学院',
          '江南大学'
        ]
      },
      {
        city_name: '南通市',
        universities: [
          '南通理工学院',
          '南通大学杏林学院',
          '江苏工程职业技术学院',
          '南通职业大学',
          '南通科技职业学院',
          '南通航运职业技术学院',
          '江苏商贸职业学院',
          '南通大学'
        ]
      },
      {
        city_name: '盐城市',
        universities: [
          '盐城师范学院',
          '盐城幼儿师范高等专科学校',
          '明达职业技术学院',
          '江苏医药职业学院',
          '盐城工业职业技术学院',
          '盐城工学院'
        ]
      },
      {
        city_name: '淮安市',
        universities: [
          '淮阴工学院',
          '淮安信息职业技术学院',
          '炎黄职业技术学院',
          '江苏食品药品职业技术学院',
          '江苏财经职业技术学院',
          '江苏护理职业学院',
          '淮阴师范学院'
        ]
      },
      {
        city_name: '扬州市',
        universities: [
          '扬州大学广陵学院',
          '南京邮电大学通达学院',
          '扬州市职业大学',
          '扬州环境资源职业技术学院',
          '江海职业技术学院',
          '扬州工业职业技术学院',
          '扬州中瑞酒店职业学院',
          '江苏旅游职业学院',
          '扬州大学'
        ]
      },
      {
        city_name: '连云港市',
        universities: [
          '南京医科大学康达学院',
          '连云港职业技术学院',
          '连云港师范高等专科学校',
          '江苏财会职业学院',
          '淮海工学院'
        ]
      },
      {
        city_name: '泰州市',
        universities: [
          '南京理工大学泰州科技学院',
          '南京中医药大学翰林学院',
          '常州大学怀德学院',
          '泰州职业技术学院',
          '江苏农牧科技职业学院',
          '泰州学院'
        ]
      },
      {
        city_name: '张家港市',
        universities: [
          '江苏科技大学苏州理工学院'
        ]
      },
      {
        city_name: '宿迁市',
        universities: [
          '宿迁职业技术学院',
          '宿迁泽达职业技术学院',
          '宿迁学院'
        ]
      },
      {
        city_name: '昆山市',
        universities: [
          '昆山杜克大学'
        ]
      },
      {
        city_name: '南京市',
        universities: [
          '东南大学',
          '南京大学',
          '南京航空航天大学',
          '南京工业大学',
          '南京邮电大学',
          '南京理工大学',
          '南京林业大学',
          '南京信息工程大学',
          '南京农业大学',
          '河海大学',
          '南京中医药大学',
          '南京医科大学',
          '中国药科大学',
          '南京财经大学',
          '南京师范大学',
          '江苏警官学院',
          '南京体育学院',
          '南京艺术学院',
          '三江学院',
          '常熟理工学院',
          '南京工程学院',
          '南京审计大学',
          '南京特殊教育师范学院',
          '南京森林警察学院',
          '南京晓庄学院',
          '金陵科技学院',
          '南京大学金陵学院',
          '东南大学成贤学院',
          '南京理工大学紫金学院',
          '南京航空航天大学金城学院',
          '中国传媒大学南广学院',
          '南京工业大学浦江学院',
          '南京师范大学泰州学院',
          '南京信息工程大学滨江学院',
          '南京师范大学中北学院',
          '苏州大学应用技术学院',
          '南京审计大学金审学院',
          '江苏第二师范学院',
          '南京工业职业技术学院',
          '苏州科技大学天平学院',
          '江苏经贸职业技术学院',
          '江苏联合职业技术学院',
          '沙洲职业工学院',
          '江苏海事职业技术学院',
          '应天职业技术学院',
          '苏州工业职业技术学院',
          '苏州托普信息职业技术学院',
          '南京交通职业技术学院',
          '苏州卫生职业技术学院',
          '南京科技职业学院',
          '苏州工业园区职业技术学院',
          '正德职业技术学院',
          '钟山职业技术学院',
          '金肯职业技术学院',
          '江南影视艺术职业学院',
          '南京铁道职业技术学院',
          '南京信息职业技术学院',
          '常州工程职业技术学院',
          '无锡城市职业技术学院',
          '无锡工艺职业技术学院',
          '南京视觉艺术职业学院',
          '昆山登云科技职业学院',
          '江苏城市职业学院',
          '南京城市职业学院',
          '南京旅游职业学院',
          '南京机电职业技术学院',
          '江苏卫生健康职业学院',
          '徐州生物工程职业技术学院',
          '南通师范高等专科学校'
        ]
      }
    ]
  },
  {
    province_name: '浙江省',
    cities: [
      {
        city_name: '舟山市',
        universities: [
          '浙江海洋大学东海科学技术学院',
          '浙江国际海运职业技术学院',
          '浙江舟山群岛新区旅游与健康职业学院',
          '浙江海洋大学'
        ]
      },
      {
        city_name: '温州市',
        universities: [
          '温州大学',
          '温州医科大学仁济学院',
          '温州大学瓯江学院',
          '温州商学院',
          '温州肯恩大学',
          '温州职业技术学院',
          '浙江工贸职业技术学院',
          '浙江东方职业技术学院',
          '温州科技职业学院',
          '浙江安防职业技术学院',
          '温州医科大学'
        ]
      },
      {
        city_name: '金华市',
        universities: [
          '浙江师范大学行知学院',
          '上海财经大学浙江学院',
          '金华职业技术学院',
          '义乌工商职业技术学院',
          '浙江广厦建设职业技术学院',
          '浙江横店影视职业学院',
          '浙江师范大学'
        ]
      },
      {
        city_name: '湖州市',
        universities: [
          '湖州师范学院求真学院',
          '湖州职业技术学院',
          '湖州师范学院'
        ]
      },
      {
        city_name: '绍兴市',
        universities: [
          '浙江越秀外国语学院',
          '浙江农林大学暨阳学院',
          '绍兴文理学院元培学院',
          '浙江工业职业技术学院',
          '绍兴职业技术学院',
          '浙江邮电职业技术学院',
          '浙江农业商贸职业学院',
          '绍兴文理学院'
        ]
      },
      {
        city_name: '台州市',
        universities: [
          '台州职业技术学院',
          '台州科技职业学院',
          '浙江汽车职业技术学院',
          '台州学院'
        ]
      },
      {
        city_name: '丽水市',
        universities: [
          '丽水职业技术学院',
          '丽水学院'
        ]
      },
      {
        city_name: '嘉兴市',
        universities: [
          '嘉兴学院南湖学院',
          '浙江财经大学东方学院',
          '同济大学浙江学院',
          '嘉兴职业技术学院',
          '嘉兴南洋职业技术学院',
          '嘉兴学院'
        ]
      },
      {
        city_name: '宁波市',
        universities: [
          '宁波工程学院',
          '宁波大学',
          '宁波大红鹰学院',
          '浙江大学宁波理工学院',
          '宁波大学科学技术学院',
          '宁波诺丁汉大学',
          '宁波职业技术学院',
          '宁波城市职业技术学院',
          '浙江工商职业技术学院',
          '浙江医药高等专科学校',
          '浙江纺织服装职业技术学院',
          '宁波卫生职业技术学院',
          '公安海警学院'
        ]
      },
      {
        city_name: '衢州市',
        universities: [
          '衢州职业技术学院',
          '衢州学院'
        ]
      },
      {
        city_name: '杭州市',
        universities: [
          '浙江大学',
          '杭州电子科技大学',
          '浙江工业大学',
          '浙江农林大学',
          '浙江中医药大学',
          '杭州师范大学',
          '浙江工商大学',
          '中国美术学院',
          '浙江理工大学',
          '中国计量大学',
          '浙江科技学院',
          '浙江水利水电学院',
          '浙江万里学院',
          '浙江财经大学',
          '浙江传媒学院',
          '浙江警察学院',
          '浙江大学城市学院',
          '杭州医学院',
          '浙江树人学院',
          '杭州电子科技大学信息工程学院',
          '浙江工业大学之江学院',
          '浙江中医药大学滨江学院',
          '浙江理工大学科技与艺术学院',
          '浙江工商大学杭州商学院',
          '中国计量大学现代科技学院',
          '浙江外国语学院',
          '杭州师范大学钱江学院',
          '浙江交通职业技术学院',
          '浙江电力职业技术学院',
          '浙江音乐学院',
          '浙江机电职业技术学院',
          '浙江同济科技职业学院',
          '浙江建设职业技术学院',
          '浙江艺术职业学院',
          '浙江经贸职业技术学院',
          '浙江商业职业技术学院',
          '浙江经济职业技术学院',
          '浙江旅游职业学院',
          '浙江育英职业技术学院',
          '浙江警官职业学院',
          '杭州职业技术学院',
          '杭州科技职业技术学院',
          '浙江金融职业学院',
          '杭州万向职业技术学院',
          '浙江体育职业技术学院',
          '浙江特殊教育职业学院',
          '浙江长征职业技术学院'
        ]
      }
    ]
  },
  {
    province_name: '安徽省',
    cities: [
      {
        city_name: '马鞍山市',
        universities: [
          '安徽工业大学工商学院',
          '河海大学文天学院',
          '安徽冶金科技职业学院',
          '马鞍山师范高等专科学校',
          '马鞍山职业技术学院',
          '安徽工业大学'
        ]
      },
      {
        city_name: '淮南市',
        universities: [
          '淮南师范学院',
          '淮南联合大学',
          '淮南职业技术学院',
          '安徽工贸职业技术学院',
          '安徽理工大学'
        ]
      },
      {
        city_name: '芜湖市',
        universities: [
          '皖南医学院',
          '安徽师范大学',
          '安徽信息工程学院',
          '安徽师范大学皖江学院',
          '芜湖职业技术学院',
          '安徽商贸职业技术学院',
          '安徽中医药高等专科学校',
          '安徽机电职业技术学院',
          '安徽扬子职业技术学院',
          '安徽工程大学'
        ]
      },
      {
        city_name: '蚌埠市',
        universities: [
          '安徽财经大学',
          '蚌埠学院',
          '安徽财经大学商学院',
          '安徽电子信息职业技术学院',
          '蚌埠经济技术职业学院',
          '蚌埠医学院'
        ]
      },
      {
        city_name: '阜阳市',
        universities: [
          '阜阳师范学院信息工程学院',
          '阜阳职业技术学院',
          '阜阳科技职业学院',
          '民办安徽旅游职业学院',
          '阜阳幼儿师范高等专科学校',
          '阜阳师范学院'
        ]
      },
      {
        city_name: '安庆市',
        universities: [
          '安庆职业技术学院',
          '安庆医药高等专科学校',
          '桐城师范高等专科学校',
          '安徽黄梅戏艺术职业学院',
          '安庆师范大学'
        ]
      },
      {
        city_name: '淮北市',
        universities: [
          '淮北师范大学信息学院',
          '淮北职业技术学院',
          '安徽矿业职业技术学院',
          '淮北师范大学'
        ]
      },
      {
        city_name: '黄山市',
        universities: [
          '黄山职业技术学院',
          '黄山学院'
        ]
      },
      {
        city_name: '六安市',
        universities: [
          '六安职业技术学院',
          '安徽国防科技职业学院',
          '安徽现代信息工程职业学院',
          '皖西卫生职业学院',
          '皖西学院'
        ]
      },
      {
        city_name: '滁州市',
        universities: [
          '安徽科技学院',
          '滁州职业技术学院',
          '滁州城市职业学院',
          '滁州学院'
        ]
      },
      {
        city_name: '宿州市',
        universities: [
          '宿州职业技术学院',
          '皖北卫生职业学院',
          '宿州学院'
        ]
      },
      {
        city_name: '铜陵市',
        universities: [
          '铜陵职业技术学院',
          '安徽工业职业技术学院',
          '铜陵学院'
        ]
      },
      {
        city_name: '池州市',
        universities: [
          '池州职业技术学院',
          '安徽卫生健康职业学院',
          '池州学院'
        ]
      },
      {
        city_name: '亳州市',
        universities: [
          '亳州职业技术学院',
          '亳州学院'
        ]
      },
      {
        city_name: '宣城市',
        universities: [
          '宣城职业技术学院'
        ]
      },
      {
        city_name: '合肥市',
        universities: [
          '安徽大学',
          '中国科学技术大学',
          '安徽农业大学',
          '合肥工业大学',
          '安徽中医药大学',
          '巢湖学院',
          '安徽建筑大学',
          '安徽三联学院',
          '安徽医科大学',
          '安徽新华学院',
          '合肥学院',
          '安徽外国语学院',
          '安徽大学江淮学院',
          '安徽建筑大学城市建设学院',
          '安徽文达信息工程学院',
          '安徽医科大学临床医学院',
          '合肥师范学院',
          '安徽职业技术学院',
          '安徽水利水电职业技术学院',
          '民办万博科技职业学院',
          '安徽农业大学经济技术学院',
          '安徽工业经济职业技术学院',
          '安徽警官职业学院',
          '民办合肥经济技术职业学院',
          '合肥通用职业技术学院',
          '安徽交通职业技术学院',
          '安徽医学高等专科学校',
          '安徽体育运动职业技术学院',
          '安徽广播影视职业技术学院',
          '合肥职业技术学院',
          '民办合肥滨湖职业技术学院',
          '安徽城市管理职业学院',
          '安徽工商职业学院',
          '安徽电气工程职业技术学院',
          '安徽艺术职业学院',
          '安徽财贸职业学院',
          '安徽中澳科技职业学院',
          '安徽国际商务职业学院',
          '安徽公安职业学院',
          '安徽林业职业技术学院',
          '安徽审计职业学院',
          '安徽新闻出版职业技术学院',
          '民办合肥财经职业学院',
          '安徽涉外经济职业学院',
          '安徽邮电职业技术学院',
          '安徽绿海商务职业学院',
          '徽商职业学院',
          '合肥信息技术职业学院',
          '安徽汽车职业技术学院',
          '合肥幼儿师范高等专科学校',
          '合肥共达职业技术学院',
          '安徽粮食工程职业学院',
          '合肥科技职业学院',
          '安徽长江职业学院'
        ]
      }
    ]
  },
  {
    province_name: '福建省',
    cities: [
      {
        city_name: '泉州市',
        universities: [
          '泉州师范学院',
          '仰恩大学',
          '闽南理工学院',
          '泉州信息工程学院',
          '黎明职业大学',
          '福建电力职业技术学院',
          '泉州医学高等专科学校',
          '泉州纺织服装职业学院',
          '泉州经贸职业技术学院',
          '泉州工艺美术职业学院',
          '泉州海洋职业学院',
          '泉州幼儿师范高等专科学校',
          '泉州工程职业技术学院',
          '华侨大学'
        ]
      },
      {
        city_name: '福州市',
        universities: [
          '福建医科大学',
          '福建商学院',
          '福建农林大学东方学院',
          '阳光学院',
          '福州大学至诚学院',
          '福建师范大学协和学院',
          '福州理工学院',
          '福建华南女子职业学院',
          '福建信息职业技术学院',
          '福建农业职业技术学院',
          '福州英华职业学院',
          '福建警官职业学院',
          '福州黎明职业技术学院',
          '福州科技职业技术学院',
          '福建对外经济贸易职业技术学院',
          '福建生物工程职业技术学院',
          '福州软件职业技术学院',
          '福建体育职业技术学院',
          '闽江师范高等专科学校',
          '福州墨尔本理工职业学院',
          '福州大学'
        ]
      },
      {
        city_name: '南平市',
        universities: [
          '福建林业职业技术学院',
          '闽北职业技术学院',
          '武夷山职业学院',
          '武夷学院'
        ]
      },
      {
        city_name: '宁德市',
        universities: [
          '宁德职业技术学院',
          '宁德师范学院'
        ]
      },
      {
        city_name: '漳州市',
        universities: [
          '厦门大学嘉庚学院',
          '漳州职业技术学院',
          '漳州城市职业学院',
          '漳州科技职业学院',
          '漳州卫生职业学院',
          '闽南师范大学'
        ]
      },
      {
        city_name: '三明市',
        universities: [
          '福建水利电力职业技术学院',
          '三明医学科技职业学院',
          '三明学院'
        ]
      },
      {
        city_name: '龙岩市',
        universities: [
          '闽西职业技术学院',
          '龙岩学院'
        ]
      },
      {
        city_name: '莆田市',
        universities: [
          '湄洲湾职业技术学院',
          '莆田学院'
        ]
      },
      {
        city_name: '厦门市',
        universities: [
          '厦门大学',
          '福建工程学院',
          '集美大学',
          '福建农林大学',
          '福建中医药大学',
          '福建师范大学',
          '厦门理工学院',
          '闽江学院',
          '厦门医学院',
          '福建警察学院',
          '厦门华厦学院',
          '厦门工学院',
          '集美大学诚毅学院',
          '福建师范大学闽南科技学院',
          '福州外语外贸学院',
          '福建江夏学院',
          '福建农林大学金山学院',
          '福建船政交通职业学院',
          '厦门海洋职业技术学院',
          '福州职业技术学院',
          '福建卫生职业技术学院',
          '泉州华光职业学院',
          '厦门演艺职业学院',
          '泉州理工职业学院',
          '厦门华天涉外职业技术学院',
          '福建艺术职业学院',
          '厦门城市职业学院',
          '厦门兴才职业技术学院',
          '福建幼儿师范高等专科学校',
          '厦门南洋职业学院',
          '厦门软件职业技术学院',
          '厦门东海职业技术学院',
          '漳州理工职业学院',
          '厦门安防科技职业学院',
          '泉州轻工职业学院'
        ]
      }
    ]
  },
  {
    province_name: '江西省',
    cities: [
      {
        city_name: '抚州市',
        universities: [
          '东华理工大学长江学院',
          '抚州幼儿师范高等专科学校',
          '抚州职业技术学院',
          '东华理工大学'
        ]
      },
      {
        city_name: '赣州市',
        universities: [
          '赣南医学院',
          '赣南师范大学',
          '江西理工大学应用科学学院',
          '赣南师范大学科技学院',
          '江西环境工程职业学院',
          '江西应用技术职业学院',
          '赣州师范高等专科学校',
          '赣南卫生健康职业学院',
          '江西理工大学'
        ]
      },
      {
        city_name: '景德镇市',
        universities: [
          '景德镇学院',
          '景德镇陶瓷大学科技艺术学院',
          '江西陶瓷工艺美术职业技术学院',
          '景德镇陶瓷职业技术学院',
          '景德镇陶瓷大学'
        ]
      },
      {
        city_name: '上饶市',
        universities: [
          '上饶幼儿师范高等专科学校',
          '江西医学高等专科学校',
          '上饶职业技术学院',
          '上饶师范学院'
        ]
      },
      {
        city_name: '宜春市',
        universities: [
          '宜春职业技术学院',
          '江西农业工程职业学院',
          '宜春幼儿师范高等专科学校',
          '江西洪州职业学院',
          '宜春学院'
        ]
      },
      {
        city_name: '吉安市',
        universities: [
          '吉安职业技术学院',
          '井冈山大学'
        ]
      },
      {
        city_name: '萍乡市',
        universities: [
          '江西工业工程职业技术学院',
          '江西应用工程职业学院',
          '萍乡学院'
        ]
      },
      {
        city_name: '新余市',
        universities: [
          '江西工程学院',
          '赣西科技职业学院',
          '江西新能源科技职业学院',
          '江西冶金职业技术学院',
          '新余学院'
        ]
      },
      {
        city_name: '九江市',
        universities: [
          '南昌大学共青学院',
          '九江职业大学',
          '江西财经职业学院',
          '江西枫林涉外经贸职业学院',
          '共青科技职业学院',
          '九江学院'
        ]
      },
      {
        city_name: '鹰潭市',
        universities: [
          '江西师范高等专科学校',
          '鹰潭职业技术学院'
        ]
      },
      {
        city_name: '南昌市',
        universities: [
          '南昌大学',
          '南昌航空大学',
          '江西农业大学',
          '华东交通大学',
          '江西师范大学',
          '江西财经大学',
          '江西中医药大学',
          '江西科技师范大学',
          '江西科技学院',
          '南昌工程学院',
          '南昌理工学院',
          '江西警察学院',
          '江西应用科技学院',
          '江西服装学院',
          '南昌工学院',
          '华东交通大学理工学院',
          '南昌航空大学科技学院',
          '江西农业大学南昌商学院',
          '南昌大学科学技术学院',
          '江西中医药大学科技学院',
          '江西科技师范大学理工学院',
          '江西师范大学科学技术学院',
          '江西财经大学现代经济管理学院',
          '豫章师范学院',
          '江西工业职业技术学院',
          '南昌师范学院',
          '江西司法警官职业学院',
          '江西旅游商贸职业学院',
          '九江职业技术学院',
          '江西艺术职业学院',
          '江西信息应用职业技术学院',
          '江西电力职业技术学院',
          '江西现代职业技术学院',
          '江西机电职业技术学院',
          '江西交通职业技术学院',
          '江西科技职业学院',
          '南昌职业学院',
          '江西外语外贸职业学院',
          '江西生物科技职业学院',
          '江西工业贸易职业技术学院',
          '江西建设职业技术学院',
          '江西先锋软件职业技术学院',
          '江西中医药高等专科学校',
          '江西经济管理职业学院',
          '江西制造职业技术学院',
          '江西工程职业学院',
          '江西航空职业技术学院',
          '江西卫生职业学院',
          '江西泰豪动漫职业学院',
          '江西管理职业学院',
          '江西青年职业学院',
          '江西传媒职业学院',
          '江西水利职业学院',
          '南昌影视传播职业学院',
          '江西工商职业技术学院'
        ]
      }
    ]
  },
  {
    province_name: '山东省',
    cities: [
      {
        city_name: '青岛市',
        universities: [
          '青岛理工大学',
          '青岛农业大学',
          '青岛滨海学院',
          '青岛大学',
          '青岛恒星科技学院',
          '青岛理工大学琴岛学院',
          '青岛工学院',
          '北京电影学院现代创意媒体学院',
          '青岛职业技术学院',
          '青岛飞洋职业技术学院',
          '山东外贸职业学院',
          '青岛港湾职业技术学院',
          '青岛求实职业技术学院',
          '青岛远洋船员职业学院',
          '中国海洋大学'
        ]
      },
      {
        city_name: '淄博市',
        universities: [
          '齐鲁医药学院',
          '淄博职业学院',
          '山东工业职业学院',
          '淄博师范高等专科学校',
          '山东铝业职业学院',
          '山东轻工职业学院',
          '山东理工大学'
        ]
      },
      {
        city_name: '泰安市',
        universities: [
          '泰山医学院',
          '泰山学院',
          '山东科技大学泰山科技学院',
          '山东财经大学东方学院',
          '山东服装职业学院',
          '山东力明科技职业学院',
          '泰山职业技术学院',
          '泰山护理职业学院',
          '山东农业大学'
        ]
      },
      {
        city_name: '潍坊市',
        universities: [
          '潍坊学院',
          '潍坊科技学院',
          '潍坊职业学院',
          '山东科技职业学院',
          '山东畜牧兽医职业学院',
          '山东交通职业学院',
          '山东信息职业技术学院',
          '山东经贸职业学院',
          '潍坊工商职业学院',
          '山东海事职业学院',
          '潍坊医学院'
        ]
      },
      {
        city_name: '滨州市',
        universities: [
          '滨州学院',
          '滨州职业学院',
          '滨州医学院'
        ]
      },
      {
        city_name: '济宁市',
        universities: [
          '曲阜师范大学',
          '济宁学院',
          '曲阜远东职业技术学院',
          '济宁职业技术学院',
          '山东理工职业学院',
          '济宁医学院'
        ]
      },
      {
        city_name: '聊城市',
        universities: [
          '聊城大学东昌学院',
          '聊城职业技术学院',
          '聊城大学'
        ]
      },
      {
        city_name: '德州市',
        universities: [
          '山东华宇工学院',
          '德州科技职业学院',
          '德州职业技术学院',
          '德州学院'
        ]
      },
      {
        city_name: '烟台市',
        universities: [
          '烟台大学',
          '山东工商学院',
          '烟台南山学院',
          '烟台大学文经学院',
          '青岛农业大学海都学院',
          '烟台职业学院',
          '烟台工程职业技术学院',
          '山东中医药高等专科学校',
          '山东商务职业学院',
          '烟台汽车工程职业学院',
          '山东文化产业职业学院',
          '烟台黄金职业学院',
          '鲁东大学'
        ]
      },
      {
        city_name: '临沂市',
        universities: [
          '山东医学高等专科学校',
          '临沂职业学院',
          '临沂大学'
        ]
      },
      {
        city_name: '菏泽市',
        universities: [
          '菏泽医学专科学校',
          '菏泽家政职业学院',
          '菏泽职业学院',
          '菏泽学院'
        ]
      },
      {
        city_name: '枣庄市',
        universities: [
          '枣庄科技职业学院',
          '枣庄职业学院',
          '枣庄学院'
        ]
      },
      {
        city_name: '东营市',
        universities: [
          '东营职业学院',
          '东营科技职业学院',
          '山东胜利职业学院',
          '中国石油大学胜利学院'
        ]
      },
      {
        city_name: '日照市',
        universities: [
          '山东水利职业学院',
          '山东外国语职业学院',
          '日照航海工程职业学院',
          '日照职业技术学院'
        ]
      },
      {
        city_name: '威海市',
        universities: [
          '山东外事翻译职业学院',
          '威海海洋职业学院',
          '威海职业学院'
        ]
      },
      {
        city_name: '莱芜市',
        universities: [
          '莱芜职业技术学院'
        ]
      },
      {
        city_name: '济南市',
        universities: [
          '山东大学',
          '山东科技大学',
          '中国石油大学（华东）',
          '济南大学',
          '山东建筑大学',
          '青岛科技大学',
          '山东中医药大学',
          '山东师范大学',
          '山东财经大学',
          '齐鲁工业大学',
          '山东体育学院',
          '山东工艺美术学院',
          '山东警察学院',
          '山东艺术学院',
          '山东女子学院',
          '山东英才学院',
          '山东交通学院',
          '山东现代学院',
          '青岛黄海学院',
          '山东师范大学历山学院',
          '山东协和学院',
          '齐鲁理工学院',
          '济南大学泉城学院',
          '山东财经大学燕山学院',
          '山东政法学院',
          '齐鲁师范学院',
          '山东管理学院',
          '山东青年政治学院',
          '山东商业职业技术学院',
          '山东农业工程学院',
          '山东职业学院',
          '山东电力高等专科学校',
          '山东圣翰财贸职业学院',
          '山东劳动职业技术学院',
          '青岛酒店管理职业技术学院',
          '济南职业学院',
          '山东凯文科技职业学院',
          '济南工程职业技术学院',
          '山东化工职业学院',
          '山东电子职业技术学院',
          '山东杏林科技职业学院',
          '山东旅游职业学院',
          '山东城市建设职业学院',
          '山东司法警官职业学院',
          '山东传媒职业学院',
          '济南幼儿师范高等专科学校',
          '山东药品食品职业学院',
          '济南护理职业学院',
          '潍坊护理职业学院',
          '山东艺术设计职业学院',
          '山东特殊教育职业学院',
          '潍坊工程职业学院'
        ]
      }
    ]
  },
  {
    province_name: '河南省',
    cities: [
      {
        city_name: '焦作市',
        universities: [
          '黄河交通学院',
          '焦作大学',
          '河南工业和信息化职业学院',
          '焦作师范高等专科学校',
          '焦作工贸职业学院',
          '河南理工大学'
        ]
      },
      {
        city_name: '洛阳市',
        universities: [
          '洛阳师范学院',
          '洛阳理工学院',
          '河南林业职业学院',
          '河南推拿职业学院',
          '洛阳科技职业学院',
          '河南科技大学'
        ]
      },
      {
        city_name: '新乡市',
        universities: [
          '新乡医学院',
          '河南师范大学',
          '新乡学院',
          '河南工学院',
          '新乡医学院三全学院',
          '新乡职业技术学院',
          '长垣烹饪职业技术学院',
          '河南科技学院'
        ]
      },
      {
        city_name: '开封市',
        universities: [
          '河南大学民生学院',
          '开封大学',
          '黄河水利职业技术学院',
          '开封文化艺术职业学院',
          '河南大学'
        ]
      },
      {
        city_name: '信阳市',
        universities: [
          '信阳农林学院',
          '信阳学院',
          '信阳职业技术学院',
          '信阳涉外职业技术学院',
          '信阳师范学院'
        ]
      },
      {
        city_name: '周口市',
        universities: [
          '周口职业技术学院',
          '周口科技职业学院',
          '周口师范学院'
        ]
      },
      {
        city_name: '安阳市',
        universities: [
          '安阳工学院',
          '安阳学院',
          '安阳职业技术学院',
          '河南护理职业学院',
          '安阳幼儿师范高等专科学校',
          '安阳师范学院'
        ]
      },
      {
        city_name: '许昌市',
        universities: [
          '许昌职业技术学院',
          '许昌陶瓷职业学院',
          '许昌电气职业学院',
          '许昌学院'
        ]
      },
      {
        city_name: '南阳市',
        universities: [
          '南阳理工学院',
          '河南工业职业技术学院',
          '南阳医学高等专科学校',
          '南阳职业学院',
          '南阳农业职业学院',
          '南阳师范学院'
        ]
      },
      {
        city_name: '商丘市',
        universities: [
          '商丘工学院',
          '商丘学院',
          '商丘职业技术学院',
          '商丘医学高等专科学校',
          '永城职业学院',
          '商丘师范学院'
        ]
      },
      {
        city_name: '驻马店市',
        universities: [
          '驻马店职业技术学院',
          '驻马店幼儿师范高等专科学校',
          '黄淮学院'
        ]
      },
      {
        city_name: '平顶山市',
        universities: [
          '河南城建学院',
          '平顶山工业职业技术学院',
          '河南质量工程职业学院',
          '平顶山文化艺术职业学院',
          '平顶山学院'
        ]
      },
      {
        city_name: '漯河市',
        universities: [
          '漯河医学高等专科学校',
          '漯河食品职业学院',
          '漯河职业技术学院'
        ]
      },
      {
        city_name: '三门峡市',
        universities: [
          '三门峡社会管理职业学院',
          '三门峡职业技术学院'
        ]
      },
      {
        city_name: '濮阳市',
        universities: [
          '濮阳医学高等专科学校',
          '濮阳职业技术学院'
        ]
      },
      {
        city_name: '济源市',
        universities: [
          '济源职业技术学院'
        ]
      },
      {
        city_name: '鹤壁市',
        universities: [
          '鹤壁汽车工程职业学院',
          '鹤壁能源化工职业学院',
          '鹤壁职业技术学院'
        ]
      },
      {
        city_name: '郑州市',
        universities: [
          '华北水利水电大学',
          '郑州轻工业学院',
          '郑州大学',
          '中原工学院',
          '河南工业大学',
          '河南牧业经济学院',
          '河南农业大学',
          '河南财经政法大学',
          '河南中医药大学',
          '郑州工程技术学院',
          '河南工程学院',
          '郑州航空工业管理学院',
          '河南警察学院',
          '河南财政金融学院',
          '黄河科技学院',
          '铁道警察学院',
          '郑州科技学院',
          '郑州工业应用技术学院',
          '郑州师范学院',
          '河南师范大学新联学院',
          '郑州财经学院',
          '郑州工商学院',
          '河南科技学院新科学院',
          '郑州成功财经学院',
          '中原工学院信息商务学院',
          '郑州升达经贸管理学院',
          '郑州铁路职业技术学院',
          '郑州电力高等专科学校',
          '河南水利与环境职业学院',
          '河南司法警官职业学院',
          '郑州澍青医学高等专科学校',
          '河南检察职业学院',
          '郑州信息科技职业学院',
          '郑州电子信息职业技术学院',
          '嵩山少林武术职业学院',
          '河南职业技术学院',
          '河南经贸职业学院',
          '郑州工业安全职业学院',
          '河南交通职业技术学院',
          '河南农业职业学院',
          '郑州旅游职业学院',
          '郑州职业技术学院',
          '河南工业贸易职业学院',
          '河南信息统计职业学院',
          '河南建筑职业技术学院',
          '郑州城市职业学院',
          '郑州理工职业学院',
          '郑州电力职业技术学院',
          '河南应用技术职业学院',
          '郑州信息工程职业学院',
          '河南艺术职业学院',
          '郑州商贸旅游职业学院',
          '河南机电职业学院',
          '郑州幼儿师范高等专科学校',
          '郑州黄河护理职业学院',
          '洛阳职业技术学院',
          '河南医学高等专科学校',
          '河南轻工职业学院',
          '郑州财税金融职业学院',
          '河南测绘职业学院'
        ]
      }
    ]
  },
  {
    province_name: '湖北省',
    cities: [
      {
        city_name: '荆州市',
        universities: [
          '长江大学工程技术学院',
          '荆州理工职业学院',
          '荆州职业技术学院',
          '湖北中医药高等专科学校',
          '长江艺术工程职业学院',
          '长江大学'
        ]
      },
      {
        city_name: '黄石市',
        universities: [
          '湖北理工学院',
          '湖北师范大学文理学院',
          '湖北工程职业学院',
          '湖北师范大学'
        ]
      },
      {
        city_name: '黄冈市',
        universities: [
          '黄冈职业技术学院',
          '鄂东职业技术学院',
          '黄冈科技职业学院',
          '黄冈师范学院'
        ]
      },
      {
        city_name: '恩施土家族苗族自治州',
        universities: [
          '湖北民族学院科技学院',
          '恩施职业技术学院',
          '湖北民族学院'
        ]
      },
      {
        city_name: '十堰市',
        universities: [
          '湖北汽车工业学院',
          '湖北医药学院',
          '湖北汽车工业学院科技学院',
          '湖北工业职业技术学院',
          '汉江师范学院'
        ]
      },
      {
        city_name: '襄阳市',
        universities: [
          '湖北文理学院理工学院',
          '襄阳职业技术学院',
          '襄阳汽车职业技术学院',
          '湖北文理学院'
        ]
      },
      {
        city_name: '孝感市',
        universities: [
          '湖北工程学院新技术学院',
          '湖北职业技术学院',
          '湖北工程学院'
        ]
      },
      {
        city_name: '咸宁市',
        universities: [
          '咸宁职业技术学院',
          '湖北科技学院'
        ]
      },
      {
        city_name: '宜昌市',
        universities: [
          '三峡大学科技学院',
          '湖北三峡职业技术学院',
          '三峡电力职业学院',
          '三峡旅游职业技术学院',
          '三峡大学'
        ]
      },
      {
        city_name: '荆门市',
        universities: [
          '荆门职业学院',
          '荆楚理工学院'
        ]
      },
      {
        city_name: '鄂州市',
        universities: [
          '鄂州职业大学'
        ]
      },
      {
        city_name: '仙桃市',
        universities: [
          '仙桃职业学院'
        ]
      },
      {
        city_name: '随州市',
        universities: [
          '随州职业技术学院'
        ]
      },
      {
        city_name: '潜江市',
        universities: [
          '江汉艺术职业学院'
        ]
      },
      {
        city_name: '天门市',
        universities: [
          '天门职业学院'
        ]
      },
      {
        city_name: '武汉市',
        universities: [
          '武汉大学',
          '华中科技大学',
          '武汉工程大学',
          '武汉科技大学',
          '中国地质大学（武汉）',
          '武汉纺织大学',
          '武汉轻工大学',
          '武汉理工大学',
          '湖北工业大学',
          '华中农业大学',
          '湖北中医药大学',
          '华中师范大学',
          '中南财经政法大学',
          '湖北大学',
          '武汉体育学院',
          '湖北美术学院',
          '江汉大学',
          '湖北警官学院',
          '武汉音乐学院',
          '中南民族大学',
          '湖北经济学院',
          '武汉商学院',
          '武汉东湖学院',
          '汉口学院',
          '武昌首义学院',
          '武昌理工学院',
          '武汉生物工程学院',
          '武汉晴川学院',
          '湖北大学知行学院',
          '江汉大学文理学院',
          '武汉科技大学城市学院',
          '湖北工业大学工程技术学院',
          '武汉工程大学邮电与信息工程学院',
          '武汉纺织大学外经贸学院',
          '武昌工学院',
          '武汉工商学院',
          '湖北商贸学院',
          '长江大学文理学院',
          '湖北经济学院法商学院',
          '湖北医药学院药护学院',
          '文华学院',
          '武汉体育学院体育科技学院',
          '武汉学院',
          '武汉工程科技学院',
          '武汉华夏理工学院',
          '武汉传媒学院',
          '武汉设计工程学院',
          '湖北第二师范学院',
          '长江职业学院',
          '武汉城市职业学院',
          '武汉船舶职业技术学院',
          '武汉工贸职业学院',
          '武汉工程职业技术学院',
          '湖北轻工职业技术学院',
          '武汉职业技术学院',
          '武汉航海职业技术学院',
          '湖北交通职业技术学院',
          '武汉铁路职业技术学院',
          '武汉电力职业技术学院',
          '武汉软件工程职业学院',
          '湖北水利水电职业技术学院',
          '湖北城市建设职业技术学院',
          '武汉警官职业学院',
          '湖北生物科技职业学院',
          '湖北开放职业学院',
          '武汉科技职业学院',
          '武汉外语外事职业学院',
          '武汉信息传播职业技术学院',
          '武昌职业学院',
          '武汉商贸职业学院',
          '湖北艺术职业学院',
          '长江工程职业技术学院',
          '武汉工业职业技术学院',
          '武汉交通职业学院',
          '湖北财税职业学院',
          '湖北国土资源职业学院',
          '武汉民政职业学院',
          '湖北科技职业学院',
          '湖北生态工程职业技术学院',
          '湖北体育职业学院',
          '湖北幼儿师范高等专科学校',
          '湖北青年职业学院',
          '湖北铁道运输职业学院',
          '武汉铁路桥梁职业学院',
          '武汉海事职业学院',
          '武汉光谷职业学院'
        ]
      }
    ]
  },
  {
    province_name: '湖南省',
    cities: [
      {
        city_name: '湘西土家族苗族自治州',
        universities: [
          '湘西民族职业技术学院',
          '吉首大学'
        ]
      },
      {
        city_name: '长沙市',
        universities: [
          '长沙理工大学',
          '湖南商学院',
          '长沙医学院',
          '湖南财政经济学院',
          '湖南女子学院',
          '湖南涉外经济学院',
          '湖南商学院北津学院',
          '湖南中医药大学湘杏学院',
          '长沙理工大学城南学院',
          '湖南信息学院',
          '长沙民政职业技术学院',
          '湖南信息职业技术学院',
          '长沙航空职业技术学院',
          '湖南科技职业学院',
          '湖南司法警官职业学院',
          '湖南邮电职业技术学院',
          '长沙环境保护职业技术学院',
          '长沙南方职业学院',
          '长沙电力职业技术学院',
          '湖南安全技术职业学院',
          '湖南外国语职业学院',
          '湖南都市职业学院',
          '湖南三一工业职业技术学院',
          '湖南劳动人事职业学院',
          '湖南大学'
        ]
      },
      {
        city_name: '岳阳市',
        universities: [
          '湖南理工学院南湖学院',
          '岳阳职业技术学院',
          '湖南石油化工职业技术学院',
          '湖南民族职业学院',
          '湖南理工学院'
        ]
      },
      {
        city_name: '郴州市',
        universities: [
          '郴州职业技术学院',
          '湘南幼儿师范高等专科学校',
          '湘南学院'
        ]
      },
      {
        city_name: '衡阳市',
        universities: [
          '南华大学',
          '湖南工学院',
          '南华大学船山学院',
          '衡阳师范学院南岳学院',
          '湖南交通工程学院',
          '湖南环境生物职业技术学院',
          '湖南财经工业职业技术学院',
          '湖南高速铁路职业技术学院',
          '湖南工商职业学院',
          '衡阳师范学院'
        ]
      },
      {
        city_name: '邵阳市',
        universities: [
          '湘中幼儿师范高等专科学校',
          '邵阳职业技术学院',
          '邵阳学院'
        ]
      },
      {
        city_name: '怀化市',
        universities: [
          '湖南医药学院',
          '怀化职业技术学院',
          '怀化学院'
        ]
      },
      {
        city_name: '常德市',
        universities: [
          '湖南文理学院芙蓉学院',
          '湖南应用技术学院',
          '常德职业技术学院',
          '湖南高尔夫旅游职业学院',
          '湖南幼儿师范高等专科学校',
          '湖南文理学院'
        ]
      },
      {
        city_name: '永州市',
        universities: [
          '永州职业技术学院',
          '湖南九嶷职业技术学院',
          '湖南科技学院'
        ]
      },
      {
        city_name: '娄底市',
        universities: [
          '娄底职业技术学院',
          '潇湘职业学院',
          '湖南人文科技学院'
        ]
      },
      {
        city_name: '益阳市',
        universities: [
          '益阳职业技术学院',
          '益阳医学高等专科学校',
          '湖南城市学院'
        ]
      },
      {
        city_name: '株洲市',
        universities: [
          '湖南工业大学科技学院',
          '株洲师范高等专科学校',
          '湖南冶金职业技术学院',
          '湖南铁道职业技术学院',
          '湖南化工职业技术学院',
          '湖南中医药高等专科学校',
          '湖南汽车工程职业学院',
          '湖南铁路科技职业技术学院',
          '湖南有色金属职业技术学院',
          '湖南工业大学'
        ]
      },
      {
        city_name: '张家界市',
        universities: [
          '张家界航空工业职业技术学院',
          '吉首大学张家界学院'
        ]
      },
      {
        city_name: '湘潭市',
        universities: [
          '湘潭大学',
          '湖南科技大学',
          '中南大学',
          '湖南农业大学',
          '中南林业科技大学',
          '湖南中医药大学',
          '湖南师范大学',
          '湖南工程学院',
          '长沙学院',
          '湖南警察学院',
          '湘潭大学兴湘学院',
          '湖南科技大学潇湘学院',
          '湖南第一师范学院',
          '湖南师范大学树达学院',
          '湖南农业大学东方科技学院',
          '湖南工程学院应用技术学院',
          '中南林业科技大学涉外学院',
          '长沙师范学院',
          '湖南工业职业技术学院',
          '湖南税务高等专科学校',
          '湖南大众传媒职业技术学院',
          '湖南生物机电职业技术学院',
          '湖南交通职业技术学院',
          '湖南商务职业技术学院',
          '湖南体育职业学院',
          '湖南工程职业技术学院',
          '保险职业学院',
          '湖南外贸职业学院',
          '湖南网络工程职业学院',
          '湘潭医卫职业技术学院',
          '长沙商贸旅游职业技术学院',
          '湖南艺术职业学院',
          '湖南机电职业技术学院',
          '湖南城建职业技术学院',
          '长沙职业技术学院',
          '湖南理工职业技术学院',
          '湖南工艺美术职业学院',
          '湖南软件职业学院',
          '湖南水利水电职业技术学院',
          '湖南电气职业技术学院',
          '湖南现代物流职业技术学院',
          '湖南国防工业职业技术学院',
          '湖南电子科技职业学院',
          '长沙卫生职业学院',
          '湖南吉利汽车职业技术学院',
          '湖南食品药品职业学院'
        ]
      }
    ]
  },
  {
    province_name: '广东省',
    cities: [
      {
        city_name: '汕头市',
        universities: [
          '广东以色列理工学院',
          '汕头职业技术学院',
          '汕头大学'
        ]
      },
      {
        city_name: '湛江市',
        universities: [
          '广东医科大学',
          '岭南师范学院',
          '广东海洋大学寸金学院',
          '广东文理职业学院',
          '湛江幼儿师范专科学校',
          '广东海洋大学'
        ]
      },
      {
        city_name: '韶关市',
        universities: [
          '广东松山职业技术学院',
          '韶关学院'
        ]
      },
      {
        city_name: '惠州市',
        universities: [
          '惠州经济职业技术学院',
          '惠州卫生职业技术学院',
          '惠州城市职业学院',
          '惠州工程职业学院',
          '惠州学院'
        ]
      },
      {
        city_name: '潮州市',
        universities: [
          '韩山师范学院'
        ]
      },
      {
        city_name: '肇庆市',
        universities: [
          '广东理工学院',
          '广东工商职业学院',
          '广东信息工程职业学院',
          '肇庆学院'
        ]
      },
      {
        city_name: '梅州市',
        universities: [
          '嘉应学院'
        ]
      },
      {
        city_name: '深圳市',
        universities: [
          '南方科技大学',
          '香港中文大学（深圳）',
          '深圳职业技术学院',
          '广东新安职业技术学院',
          '深圳信息职业技术学院',
          '深圳大学'
        ]
      },
      {
        city_name: '江门市',
        universities: [
          '江门职业技术学院',
          '广东南方职业学院',
          '广东江门中医药职业学院',
          '五邑大学'
        ]
      },
      {
        city_name: '中山市',
        universities: [
          '中山火炬职业技术学院',
          '中山职业技术学院',
          '电子科技大学中山学院'
        ]
      },
      {
        city_name: '茂名市',
        universities: [
          '茂名职业技术学院',
          '广东茂名健康职业学院',
          '广东茂名幼儿师范专科学校',
          '广东石油化工学院'
        ]
      },
      {
        city_name: '东莞市',
        universities: [
          '广东科技学院',
          '东莞理工学院城市学院',
          '广东亚视演艺职业学院',
          '东莞职业技术学院',
          '广东创新科技职业学院',
          '广东酒店管理职业技术学院',
          '东莞理工学院'
        ]
      },
      {
        city_name: '佛山市',
        universities: [
          '广东东软学院',
          '顺德职业技术学院',
          '佛山职业技术学院',
          '广东职业技术学院',
          '广东环境保护工程职业学院',
          '佛山科学技术学院'
        ]
      },
      {
        city_name: '珠海市',
        universities: [
          '北京理工大学珠海学院',
          '北京师范大学-香港浸会大学联合国际学院',
          '珠海艺术职业学院',
          '珠海城市职业技术学院',
          '北京师范大学珠海分校'
        ]
      },
      {
        city_name: '揭阳市',
        universities: [
          '揭阳职业技术学院',
          '潮汕职业技术学院'
        ]
      },
      {
        city_name: '汕尾市',
        universities: [
          '汕尾职业技术学院'
        ]
      },
      {
        city_name: '云浮市',
        universities: [
          '罗定职业技术学院'
        ]
      },
      {
        city_name: '阳江市',
        universities: [
          '阳江职业技术学院'
        ]
      },
      {
        city_name: '河源市',
        universities: [
          '河源职业技术学院'
        ]
      },
      {
        city_name: '清远市',
        universities: [
          '广东碧桂园职业学院',
          '清远职业技术学院'
        ]
      },
      {
        city_name: '广州市',
        universities: [
          '中山大学',
          '华南理工大学',
          '暨南大学',
          '广州医科大学',
          '广州中医药大学',
          '华南农业大学',
          '广东药科大学',
          '广州体育学院',
          '华南师范大学',
          '广州美术学院',
          '星海音乐学院',
          '广东财经大学',
          '广东技术师范学院',
          '广东白云学院',
          '广州大学',
          '广州航海学院',
          '广东警官学院',
          '广东金融学院',
          '广东工业大学',
          '仲恺农业工程学院',
          '广东培正学院',
          '广东外语外贸大学',
          '华南理工大学广州学院',
          '南方医科大学',
          '广州大学华软软件学院',
          '中山大学南方学院',
          '广东外语外贸大学南国商学院',
          '华南农业大学珠江学院',
          '广东财经大学华商学院',
          '广东工业大学华立学院',
          '广东技术师范学院天河学院',
          '广州大学松田学院',
          '广州商学院',
          '广州工商学院',
          '中山大学新华学院',
          '吉林大学珠海学院',
          '广东第二师范学院',
          '广东轻工职业技术学院',
          '深圳北理莫斯科大学',
          '广东交通职业技术学院',
          '广东南华工商职业学院',
          '广东水利电力职业技术学院',
          '私立华联学院',
          '广州民航职业技术学院',
          '广东农工商职业技术学院',
          '广东科学技术职业学院',
          '广州番禺职业技术学院',
          '广东食品药品职业学院',
          '广东行政职业学院',
          '广州康大职业技术学院',
          '广东建设职业技术学院',
          '广东体育职业技术学院',
          '广东女子职业技术学院',
          '广东机电职业技术学院',
          '广东邮电职业技术学院',
          '广东工贸职业技术学院',
          '广东岭南职业技术学院',
          '广东省外语艺术职业学院',
          '广东司法警官职业学院',
          '广东文艺职业学院',
          '广州体育职业技术学院',
          '广州涉外经济职业技术学院',
          '广州工程技术职业学院',
          '广州南洋理工职业学院',
          '广州科技职业技术学院',
          '广州现代信息工程职业技术学院',
          '肇庆医学高等专科学校',
          '广东理工职业学院',
          '广州华南商贸职业学院',
          '广州华立科技职业学院',
          '广州城市职业学院',
          '广东工程职业技术学院',
          '广州铁路职业技术学院',
          '广东科贸职业学院',
          '广州珠江职业技术学院',
          '广州科技贸易职业学院',
          '广州城建职业学院',
          '广州华商职业学院',
          '广州松田职业学院',
          '广东青年职业学院',
          '广州华夏职业学院',
          '广东舞蹈戏剧职业学院',
          '广东生态工程职业学院',
          '公安边防部队高等专科学校',
          '广州卫生职业技术学院',
          '广州东华职业学院'
        ]
      }
    ]
  },
  {
    province_name: '广西壮族自治区',
    cities: [
      {
        city_name: '柳州市',
        universities: [
          '广西科技大学鹿山学院',
          '柳州职业技术学院',
          '柳州铁道职业技术学院',
          '柳州城市职业学院',
          '广西科技大学'
        ]
      },
      {
        city_name: '桂林市',
        universities: [
          '桂林医学院',
          '桂林航天工业学院',
          '广西师范大学漓江学院',
          '桂林电子科技大学信息科技学院',
          '桂林师范高等专科学校',
          '桂林山水职业学院',
          '桂林电子科技大学'
        ]
      },
      {
        city_name: '百色市',
        universities: [
          '百色学院',
          '百色职业学院',
          '广西培贤国际职业学院',
          '右江民族医学院'
        ]
      },
      {
        city_name: '崇左市',
        universities: [
          '广西城市职业学院',
          '广西理工职业技术学院',
          '广西科技职业学院',
          '广西中远职业学院',
          '广西民族师范学院'
        ]
      },
      {
        city_name: '河池市',
        universities: [
          '广西现代职业技术学院',
          '河池学院'
        ]
      },
      {
        city_name: '玉林市',
        universities: [
          '玉柴职业技术学院',
          '玉林师范学院'
        ]
      },
      {
        city_name: '梧州市',
        universities: [
          '梧州职业学院',
          '梧州学院'
        ]
      },
      {
        city_name: '来宾市',
        universities: [
          '广西蓝天航空职业学院',
          '广西科技师范学院'
        ]
      },
      {
        city_name: '钦州市',
        universities: [
          '广西英华国际职业学院',
          '钦州学院'
        ]
      },
      {
        city_name: '贺州市',
        universities: [
          '贺州学院'
        ]
      },
      {
        city_name: '北海市',
        universities: [
          '北京航空航天大学北海学院',
          '北海职业学院',
          '北海艺术设计学院'
        ]
      },
      {
        city_name: '南宁市',
        universities: [
          '广西大学',
          '广西医科大学',
          '广西中医药大学',
          '桂林理工大学',
          '广西师范学院',
          '广西艺术学院',
          '广西师范大学',
          '广西财经学院',
          '广西民族大学',
          '南宁学院',
          '广西警察学院',
          '广西大学行健文理学院',
          '广西民族大学相思湖学院',
          '广西师范学院师园学院',
          '桂林旅游学院',
          '广西中医药大学赛恩斯新医药学院',
          '广西外国语学院',
          '广西机电职业技术学院',
          '桂林理工大学博文管理学院',
          '广西体育高等专科学校',
          '南宁职业技术学院',
          '广西职业技术学院',
          '广西水利电力职业技术学院',
          '广西交通职业技术学院',
          '广西生态工程职业技术学院',
          '广西工业职业技术学院',
          '广西国际商务职业技术学院',
          '广西建设职业技术学院',
          '广西经贸职业技术学院',
          '广西农业职业技术学院',
          '广西工商职业技术学院',
          '广西演艺职业学院',
          '广西电力职业技术学院',
          '广西经济职业学院',
          '广西工程职业学院',
          '广西卫生职业技术学院',
          '广西金融职业技术学院',
          '广西安全工程职业技术学院',
          '广西幼儿师范高等专科学校'
        ]
      }
    ]
  },
  {
    province_name: '海南省',
    cities: [
      {
        city_name: '三亚市',
        universities: [
          '三亚学院',
          '三亚城市职业学院',
          '三亚航空旅游职业学院',
          '三亚理工职业学院',
          '三亚中瑞酒店管理职业学院',
          '海南热带海洋学院'
        ]
      },
      {
        city_name: '琼海市',
        universities: [
          '海南软件职业技术学院'
        ]
      },
      {
        city_name: '文昌市',
        universities: [
          '海南外国语职业学院'
        ]
      },
      {
        city_name: '海口市',
        universities: [
          '海南师范大学',
          '海南大学',
          '海南医学院',
          '海口经济学院',
          '海南职业技术学院',
          '海南政法职业学院',
          '海南经贸职业技术学院',
          '琼台师范学院',
          '海南科技职业学院',
          '海南体育职业技术学院',
          '海南工商职业学院'
        ]
      }
    ]
  },
  {
    province_name: '重庆市',
    cities: [
      {
        city_name: '重庆市',
        universities: [
          '重庆大学',
          '重庆邮电大学',
          '重庆交通大学',
          '重庆医科大学',
          '西南大学',
          '重庆师范大学',
          '重庆文理学院',
          '重庆三峡学院',
          '长江师范学院',
          '四川外国语大学',
          '西南政法大学',
          '四川美术学院',
          '重庆科技学院',
          '重庆理工大学',
          '重庆工商大学',
          '重庆工程学院',
          '重庆大学城市科技学院',
          '重庆警察学院',
          '重庆人文科技学院',
          '四川外国语大学重庆南方翻译学院',
          '重庆师范大学涉外商贸学院',
          '重庆工商大学融智学院',
          '重庆工商大学派斯学院',
          '重庆邮电大学移通学院',
          '重庆第二师范学院',
          '重庆航天职业技术学院',
          '重庆电力高等专科学校',
          '重庆工业职业技术学院',
          '重庆三峡职业学院',
          '重庆工贸职业技术学院',
          '重庆机电职业技术学院',
          '重庆电子工程职业学院',
          '重庆海联职业技术学院',
          '重庆信息技术职业学院',
          '重庆传媒职业学院',
          '重庆城市管理职业学院',
          '重庆工程职业技术学院',
          '重庆房地产职业学院',
          '重庆城市职业学院',
          '重庆水利电力职业技术学院',
          '重庆工商职业学院',
          '重庆应用技术职业学院',
          '重庆三峡医药高等专科学校',
          '重庆医药高等专科学校',
          '重庆青年职业技术学院',
          '重庆财经职业学院',
          '重庆科创职业学院',
          '重庆建筑工程职业学院',
          '重庆电讯职业学院',
          '重庆能源职业学院',
          '重庆商务职业学院',
          '重庆交通职业学院',
          '重庆化工职业学院',
          '重庆旅游职业学院',
          '重庆安全技术职业学院',
          '重庆公共运输职业学院',
          '重庆艺术工程职业学院',
          '重庆轻工职业学院',
          '重庆电信职业学院',
          '重庆经贸职业学院',
          '重庆幼儿师范高等专科学校',
          '重庆文化艺术职业学院',
          '重庆科技职业学院',
          '重庆资源与环境保护职业学院',
          '重庆护理职业学院'
        ]
      }
    ]
  },
  {
    province_name: '四川省',
    cities: [
      {
        city_name: '绵阳市',
        universities: [
          '绵阳师范学院',
          '西南财经大学天府学院',
          '四川文化艺术学院',
          '绵阳职业技术学院',
          '四川中医药高等专科学校',
          '四川幼儿师范高等专科学校',
          '四川汽车职业技术学院',
          '四川电子机械职业技术学院',
          '西南科技大学'
        ]
      },
      {
        city_name: '自贡市',
        universities: [
          '四川卫生康复职业学院',
          '四川理工学院'
        ]
      },
      {
        city_name: '德阳市',
        universities: [
          '四川工业科技学院',
          '四川工程职业技术学院',
          '四川司法警官职业学院',
          '四川护理职业学院',
          '中国民用航空飞行学院'
        ]
      },
      {
        city_name: '雅安市',
        universities: [
          '雅安职业技术学院',
          '四川农业大学'
        ]
      },
      {
        city_name: '凉山彝族自治州',
        universities: [
          '西昌学院'
        ]
      },
      {
        city_name: '泸州市',
        universities: [
          '四川警察学院',
          '四川化工职业技术学院',
          '泸州职业技术学院',
          '四川三河职业学院',
          '西南医科大学'
        ]
      },
      {
        city_name: '南充市',
        universities: [
          '西华师范大学',
          '西南交通大学希望学院',
          '南充职业技术学院',
          '川北医学院'
        ]
      },
      {
        city_name: '内江市',
        universities: [
          '内江职业技术学院',
          '川南幼儿师范高等专科学校',
          '内江师范学院'
        ]
      },
      {
        city_name: '宜宾市',
        universities: [
          '宜宾职业技术学院',
          '宜宾学院'
        ]
      },
      {
        city_name: '达州市',
        universities: [
          '达州职业技术学院',
          '四川文理学院'
        ]
      },
      {
        city_name: '阿坝藏族羌族自治州',
        universities: [
          '阿坝师范学院'
        ]
      },
      {
        city_name: '乐山市',
        universities: [
          '成都理工大学工程技术学院',
          '乐山职业技术学院',
          '乐山师范学院'
        ]
      },
      {
        city_name: '攀枝花市',
        universities: [
          '四川机电职业技术学院',
          '攀枝花学院'
        ]
      },
      {
        city_name: '甘孜藏族自治州',
        universities: [
          '四川民族学院'
        ]
      },
      {
        city_name: '眉山市',
        universities: [
          '眉山职业技术学院',
          '四川大学锦江学院'
        ]
      },
      {
        city_name: '遂宁市',
        universities: [
          '四川职业技术学院'
        ]
      },
      {
        city_name: '广安市',
        universities: [
          '广安职业技术学院'
        ]
      },
      {
        city_name: '广元市',
        universities: [
          '川北幼儿师范高等专科学校',
          '四川信息职业技术学院'
        ]
      },
      {
        city_name: '巴中市',
        universities: [
          '巴中职业技术学院'
        ]
      },
      {
        city_name: '资阳市',
        universities: [
          '四川希望汽车职业学院'
        ]
      },
      {
        city_name: '凉山州',
        universities: [
          '四川应用技术职业学院'
        ]
      },
      {
        city_name: '成都市',
        universities: [
          '四川大学',
          '西南交通大学',
          '电子科技大学',
          '西南石油大学',
          '成都信息工程大学',
          '西华大学',
          '成都中医药大学',
          '四川师范大学',
          '西南财经大学',
          '成都理工大学',
          '成都体育学院',
          '四川音乐学院',
          '西南民族大学',
          '成都学院',
          '四川旅游学院',
          '成都东软学院',
          '成都工业学院',
          '四川传媒学院',
          '电子科技大学成都学院',
          '成都信息工程大学银杏酒店管理学院',
          '成都文理学院',
          '四川工商学院',
          '四川外国语大学成都学院',
          '四川大学锦城学院',
          '成都医学院',
          '成都师范学院',
          '西南科技大学城市学院',
          '四川电影电视学院',
          '成都纺织高等专科学校',
          '民办四川天一学院',
          '成都航空职业技术学院',
          '四川电力职业技术学院',
          '四川水利职业技术学院',
          '四川航天职业技术学院',
          '成都职业技术学院',
          '四川交通职业技术学院',
          '四川邮电职业技术学院',
          '四川工商职业技术学院',
          '四川托普信息技术职业学院',
          '四川建筑职业技术学院',
          '四川国际标榜职业学院',
          '成都艺术职业学院',
          '四川商务职业学院',
          '四川文化传媒职业学院',
          '成都农业科技职业学院',
          '四川华新现代职业学院',
          '四川管理职业学院',
          '四川科技职业学院',
          '四川艺术职业学院',
          '四川文化产业职业学院',
          '四川财经职业学院',
          '四川城市职业学院',
          '四川长江职业学院',
          '四川文轩职业学院',
          '成都工业职业技术学院',
          '四川现代职业学院',
          '四川西南航空职业学院',
          '成都工贸职业技术学院'
        ]
      }
    ]
  },
  {
    province_name: '贵州省',
    cities: [
      {
        city_name: '遵义市',
        universities: [
          '遵义师范学院',
          '遵义医学院医学与科技学院',
          '茅台学院',
          '贵州航天职业技术学院',
          '遵义职业技术学院',
          '遵义医药高等专科学校',
          '遵义医学院'
        ]
      },
      {
        city_name: '铜仁市',
        universities: [
          '铜仁职业技术学院',
          '铜仁幼儿师范高等专科学校',
          '贵州工程职业学院',
          '贵州健康职业学院',
          '铜仁学院'
        ]
      },
      {
        city_name: '黔西南布依族苗族自治州',
        universities: [
          '黔西南民族职业技术学院',
          '兴义民族师范学院'
        ]
      },
      {
        city_name: '安顺市',
        universities: [
          '安顺职业技术学院',
          '安顺学院'
        ]
      },
      {
        city_name: '毕节市',
        universities: [
          '毕节职业技术学院',
          '毕节医学高等专科学校',
          '毕节幼儿师范高等专科学校',
          '贵州工贸职业学院',
          '贵州工程应用技术学院'
        ]
      },
      {
        city_name: '黔东南苗族侗族自治州',
        universities: [
          '贵州电子信息职业技术学院',
          '黔东南民族职业技术学院',
          '凯里学院'
        ]
      },
      {
        city_name: '黔南布依族苗族自治州',
        universities: [
          '黔南民族医学高等专科学校',
          '黔南民族职业技术学院',
          '贵州盛华职业学院',
          '黔南民族幼儿师范高等专科学校',
          '贵州应用技术职业学院',
          '贵州经贸职业技术学院',
          '黔南民族师范学院'
        ]
      },
      {
        city_name: '六盘水市',
        universities: [
          '六盘水职业技术学院',
          '六盘水师范学院'
        ]
      },
      {
        city_name: '贵阳市',
        universities: [
          '贵州大学',
          '贵阳中医学院',
          '贵州医科大学',
          '贵州财经大学',
          '贵州师范大学',
          '贵州民族大学',
          '贵州商学院',
          '贵阳学院',
          '贵州警察学院',
          '贵阳中医学院时珍学院',
          '贵州财经大学商务学院',
          '贵州大学科技学院',
          '贵州大学明德学院',
          '贵州民族大学人文科技学院',
          '贵州医科大学神奇民族医药学院',
          '贵州师范大学求是学院',
          '贵州师范学院',
          '贵州交通职业技术学院',
          '贵州城市职业学院',
          '贵州理工学院',
          '贵州工业职业技术学院',
          '贵州轻工职业技术学院',
          '贵阳护理职业学院',
          '贵州电力职业技术学院',
          '贵州职业技术学院',
          '贵州工商职业学院',
          '贵阳职业技术学院',
          '贵州建设职业技术学院',
          '贵州农业职业学院',
          '贵州水利水电职业技术学院',
          '贵阳幼儿师范高等专科学校',
          '贵州电子科技职业学院',
          '贵州电子商务职业技术学院',
          '贵州食品工程职业学院',
          '贵州装备制造职业学院',
          '贵州航空职业技术学院',
          '贵州护理职业技术学院'
        ]
      }
    ]
  },
  {
    province_name: '云南省',
    cities: [
      {
        city_name: '大理白族自治州',
        universities: [
          '滇西应用技术大学',
          '大理农林职业技术学院',
          '大理护理职业学院',
          '大理大学'
        ]
      },
      {
        city_name: '昭通市',
        universities: [
          '昭通卫生职业学院',
          '昭通学院'
        ]
      },
      {
        city_name: '曲靖市',
        universities: [
          '云南能源职业技术学院',
          '曲靖医学高等专科学校',
          '曲靖师范学院'
        ]
      },
      {
        city_name: '普洱市',
        universities: [
          '普洱学院'
        ]
      },
      {
        city_name: '保山市',
        universities: [
          '保山中医药高等专科学校',
          '保山学院'
        ]
      },
      {
        city_name: '红河哈尼族彝族自治州',
        universities: [
          '云南锡业职业技术学院',
          '红河卫生职业学院',
          '红河学院'
        ]
      },
      {
        city_name: '玉溪市',
        universities: [
          '玉溪农业职业技术学院',
          '玉溪师范学院'
        ]
      },
      {
        city_name: '楚雄彝族自治州',
        universities: [
          '楚雄医药高等专科学校',
          '云南现代职业技术学院',
          '楚雄师范学院'
        ]
      },
      {
        city_name: '文山壮族苗族自治州',
        universities: [
          '云南三鑫职业技术学院',
          '文山学院'
        ]
      },
      {
        city_name: '丽江市',
        universities: [
          '丽江师范高等专科学校',
          '云南大学旅游文化学院'
        ]
      },
      {
        city_name: '临沧市',
        universities: [
          '滇西科技师范学院'
        ]
      },
      {
        city_name: '西双版纳傣族自治州',
        universities: [
          '西双版纳职业技术学院'
        ]
      },
      {
        city_name: '德宏傣族景颇族自治州',
        universities: [
          '德宏职业学院',
          '德宏师范高等专科学校'
        ]
      },
      {
        city_name: '昆明市',
        universities: [
          '云南大学',
          '昆明理工大学',
          '云南农业大学',
          '西南林业大学',
          '云南中医学院',
          '昆明医科大学',
          '云南财经大学',
          '云南师范大学',
          '云南艺术学院',
          '云南警官学院',
          '云南民族大学',
          '云南经济管理学院',
          '昆明学院',
          '昆明理工大学津桥学院',
          '云南大学滇池学院',
          '云南师范大学商学院',
          '云南师范大学文理学院',
          '昆明医科大学海源学院',
          '云南艺术学院文华学院',
          '昆明冶金高等专科学校',
          '云南工商学院',
          '云南国土资源职业学院',
          '云南交通职业技术学院',
          '昆明工业职业技术学院',
          '云南农业职业技术学院',
          '云南司法警官职业学院',
          '云南文化艺术职业学院',
          '云南体育运动职业技术学院',
          '昆明艺术职业学院',
          '云南国防工业职业技术学院',
          '云南科技信息职业学院',
          '云南机电职业技术学院',
          '云南林业职业技术学院',
          '云南城市建设职业学院',
          '云南新兴职业学院',
          '云南经贸外事职业学院',
          '云南商务职业学院',
          '云南工程职业学院',
          '云南旅游职业学院',
          '云南外事外语职业学院',
          '公安消防部队高等专科学校',
          '昆明卫生职业学院',
          '云南财经职业学院',
          '云南水利水电职业学院',
          '昆明铁道职业技术学院',
          '云南轻纺职业学院',
          '云南特殊教育职业学院',
          '云南工贸职业技术学院',
          '云南交通运输职业学院'
        ]
      }
    ]
  },
  {
    province_name: '西藏自治区',
    cities: [
      {
        city_name: '咸阳市',
        universities: [
          '西藏民族大学'
        ]
      },
      {
        city_name: '林芝市',
        universities: [
          '西藏农牧学院'
        ]
      },
      {
        city_name: '拉萨市',
        universities: [
          '西藏藏医学院',
          '西藏警官高等专科学校',
          '西藏大学',
          '拉萨师范高等专科学校',
          '西藏职业技术学院'
        ]
      }
    ]
  },
  {
    province_name: '陕西省',
    cities: [
      {
        city_name: '咸阳市',
        universities: [
          '咸阳师范学院',
          '陕西国际商贸学院',
          '陕西科技大学镐京学院',
          '陕西工业职业技术学院',
          '陕西能源职业技术学院',
          '陕西财经职业技术学院',
          '陕西邮电职业技术学院',
          '咸阳职业技术学院',
          '西北农林科技大学'
        ]
      },
      {
        city_name: '延安市',
        universities: [
          '延安职业技术学院',
          '延安大学'
        ]
      },
      {
        city_name: '汉中市',
        universities: [
          '陕西航空职业技术学院',
          '汉中职业技术学院',
          '陕西理工大学'
        ]
      },
      {
        city_name: '宝鸡市',
        universities: [
          '宝鸡职业技术学院',
          '陕西机电职业技术学院',
          '宝鸡文理学院'
        ]
      },
      {
        city_name: '渭南市',
        universities: [
          '陕西铁路工程职业技术学院',
          '渭南职业技术学院',
          '渭南师范学院'
        ]
      },
      {
        city_name: '榆林市',
        universities: [
          '榆林职业技术学院',
          '榆林学院'
        ]
      },
      {
        city_name: '商洛市',
        universities: [
          '商洛职业技术学院',
          '商洛学院'
        ]
      },
      {
        city_name: '安康市',
        universities: [
          '安康职业技术学院',
          '安康学院'
        ]
      },
      {
        city_name: '铜川市',
        universities: [
          '铜川职业技术学院'
        ]
      },
      {
        city_name: '西安市',
        universities: [
          '西北大学',
          '西安交通大学',
          '西北工业大学',
          '西安理工大学',
          '西安电子科技大学',
          '西安工业大学',
          '西安建筑科技大学',
          '西安科技大学',
          '西安石油大学',
          '陕西科技大学',
          '西安工程大学',
          '长安大学',
          '陕西师范大学',
          '西安外国语大学',
          '陕西中医药大学',
          '西北政法大学',
          '西安体育学院',
          '西安音乐学院',
          '西安美术学院',
          '西安培华学院',
          '西安文理学院',
          '西安财经学院',
          '西安邮电大学',
          '西安航空学院',
          '西安医学院',
          '西安欧亚学院',
          '西安外事学院',
          '西安翻译学院',
          '西京学院',
          '西安思源学院',
          '西安交通工程学院',
          '陕西服装工程学院',
          '西安交通大学城市学院',
          '西北大学现代学院',
          '西安建筑科技大学华清学院',
          '西安工业大学北方信息工程学院',
          '西安财经学院行知学院',
          '延安大学西安创新学院',
          '西安电子科技大学长安学院',
          '西北工业大学明德学院',
          '长安大学兴华学院',
          '西安理工大学高科学院',
          '西安科技大学高新学院',
          '陕西学前师范学院',
          '西安电力高等专科学校',
          '陕西国防工业职业技术学院',
          '杨凌职业技术学院',
          '陕西交通职业技术学院',
          '西安航空职业技术学院',
          '陕西职业技术学院',
          '西安高新科技职业学院',
          '陕西电子信息职业技术学院',
          '西安海棠职业学院',
          '西安城市建设职业学院',
          '西安汽车科技职业学院',
          '西安东方亚太职业技术学院',
          '陕西警官职业学院',
          '陕西经济管理职业技术学院',
          '西安职业技术学院',
          '陕西青年职业学院',
          '西安铁路职业技术学院',
          '陕西工商职业学院',
          '陕西电子科技职业学院',
          '陕西旅游烹饪职业学院',
          '陕西艺术职业学院',
          '西安医学高等专科学校'
        ]
      }
    ]
  },
  {
    province_name: '甘肃省',
    cities: [
      {
        city_name: '庆阳市',
        universities: [
          '庆阳职业技术学院',
          '陇东学院'
        ]
      },
      {
        city_name: '天水市',
        universities: [
          '甘肃林业职业技术学院',
          '甘肃机电职业技术学院',
          '天水师范学院'
        ]
      },
      {
        city_name: '张掖市',
        universities: [
          '河西学院'
        ]
      },
      {
        city_name: '甘南藏族自治州',
        universities: [
          '甘肃民族师范学院'
        ]
      },
      {
        city_name: '平凉市',
        universities: [
          '平凉职业技术学院',
          '甘肃医学院'
        ]
      },
      {
        city_name: '陇南市',
        universities: [
          '陇南师范高等专科学校'
        ]
      },
      {
        city_name: '定西市',
        universities: [
          '定西师范高等专科学校'
        ]
      },
      {
        city_name: '酒泉市',
        universities: [
          '酒泉职业技术学院'
        ]
      },
      {
        city_name: '武威市',
        universities: [
          '甘肃畜牧工程职业技术学院',
          '武威职业学院'
        ]
      },
      {
        city_name: '嘉峪关市',
        universities: [
          '甘肃钢铁职业技术学院'
        ]
      },
      {
        city_name: '金昌市',
        universities: [
          '甘肃有色冶金职业技术学院'
        ]
      },
      {
        city_name: '白银市',
        universities: [
          '白银矿冶职业技术学院'
        ]
      },
      {
        city_name: '临夏州',
        universities: [
          '临夏现代职业学院'
        ]
      },
      {
        city_name: '兰州市',
        universities: [
          '兰州大学',
          '兰州理工大学',
          '兰州交通大学',
          '甘肃农业大学',
          '甘肃中医药大学',
          '西北师范大学',
          '兰州财经大学',
          '兰州城市学院',
          '西北民族大学',
          '兰州文理学院',
          '兰州工业学院',
          '甘肃政法学院',
          '西北师范大学知行学院',
          '兰州财经大学陇桥学院',
          '兰州财经大学长青学院',
          '兰州交通大学博文学院',
          '兰州理工大学技术工程学院',
          '甘肃建筑职业技术学院',
          '兰州外语职业学院',
          '兰州石化职业技术学院',
          '兰州职业技术学院',
          '甘肃警察职业学院',
          '甘肃交通职业技术学院',
          '甘肃工业职业技术学院',
          '兰州资源环境职业技术学院',
          '甘肃卫生职业学院',
          '甘肃农业职业技术学院',
          '甘肃能源化工职业学院',
          '兰州科技职业学院',
          '甘肃财贸职业学院',
          '兰州现代职业学院'
        ]
      }
    ]
  },
  {
    province_name: '青海省',
    cities: [
      {
        city_name: '海东市',
        universities: [
          '青海高等职业技术学院'
        ]
      },
      {
        city_name: '海西蒙古族藏族自治州',
        universities: [
          '青海柴达木职业技术学院'
        ]
      },
      {
        city_name: '西宁市',
        universities: [
          '青海大学',
          '青海师范大学',
          '青海民族大学',
          '青海大学昆仑学院',
          '青海卫生职业技术学院',
          '青海警官职业学院',
          '青海畜牧兽医职业技术学院',
          '青海交通职业技术学院',
          '青海建筑职业技术学院',
          '西宁城市职业技术学院'
        ]
      }
    ]
  },
  {
    province_name: '宁夏回族自治区',
    cities: [
      {
        city_name: '固原市',
        universities: [
          '宁夏师范学院'
        ]
      },
      {
        city_name: '石嘴山市',
        universities: [
          '宁夏理工学院'
        ]
      },
      {
        city_name: '吴忠市',
        universities: [
          '宁夏民族职业技术学院'
        ]
      },
      {
        city_name: '银川市',
        universities: [
          '宁夏大学',
          '北方民族大学',
          '宁夏大学新华学院',
          '宁夏医科大学',
          '银川能源学院',
          '宁夏工业职业学院',
          '中国矿业大学银川学院',
          '宁夏职业技术学院',
          '宁夏工商职业技术学院',
          '宁夏财经职业技术学院',
          '宁夏警官职业学院',
          '宁夏建设职业技术学院',
          '宁夏葡萄酒与防沙治沙职业技术学院',
          '宁夏幼儿师范高等专科学校',
          '宁夏艺术职业学院',
          '宁夏体育职业学院'
        ]
      }
    ]
  },
  {
    province_name: '新疆维吾尔自治区',
    cities: [
      {
        city_name: '阿拉尔市',
        universities: [
          '塔里木大学'
        ]
      },
      {
        city_name: '石河子市',
        universities: [
          '石河子大学科技学院',
          '新疆石河子职业技术学院',
          '石河子大学'
        ]
      },
      {
        city_name: '喀什地区',
        universities: [
          '喀什大学'
        ]
      },
      {
        city_name: '伊犁哈萨克自治州',
        universities: [
          '伊犁职业技术学院',
          '新疆应用职业技术学院',
          '伊犁师范学院'
        ]
      },
      {
        city_name: '昌吉回族自治州',
        universities: [
          '新疆农业职业技术学院',
          '昌吉职业技术学院',
          '昌吉学院'
        ]
      },
      {
        city_name: '和田地区',
        universities: [
          '新疆维吾尔医学专科学校',
          '和田师范专科学校'
        ]
      },
      {
        city_name: '克拉玛依市',
        universities: [
          '克拉玛依职业技术学院'
        ]
      },
      {
        city_name: '阿克苏地区',
        universities: [
          '阿克苏职业技术学院'
        ]
      },
      {
        city_name: '巴音郭楞蒙古自治州',
        universities: [
          '巴音郭楞职业技术学院'
        ]
      },
      {
        city_name: '五家渠市',
        universities: [
          '新疆兵团警官高等专科学校'
        ]
      },
      {
        city_name: '哈密市',
        universities: [
          '哈密职业技术学院'
        ]
      },
      {
        city_name: '吐鲁番市',
        universities: [
          '吐鲁番职业技术学院'
        ]
      },
      {
        city_name: '博尔塔拉蒙古自治州',
        universities: [
          '博尔塔拉职业技术学院'
        ]
      },
      {
        city_name: '乌鲁木齐市',
        universities: [
          '新疆农业大学',
          '新疆医科大学',
          '新疆大学',
          '新疆财经大学',
          '新疆师范大学',
          '新疆艺术学院',
          '新疆警察学院',
          '新疆工程学院',
          '新疆大学科学技术学院',
          '新疆农业大学科学技术学院',
          '新疆医科大学厚博学院',
          '乌鲁木齐职业大学',
          '新疆机电职业技术学院',
          '新疆财经大学商务学院',
          '新疆轻工职业技术学院',
          '新疆建设职业技术学院',
          '新疆现代职业技术学院',
          '新疆能源职业技术学院',
          '新疆天山职业技术学院',
          '新疆职业大学',
          '新疆交通职业技术学院',
          '新疆师范高等专科学校',
          '新疆体育职业技术学院',
          '新疆铁道职业技术学院',
          '新疆科技职业技术学院',
          '新疆工业职业技术学院',
          '新疆生产建设兵团兴新职业技术学院'
        ]
      }
    ]
  }
]
let schoolsArr = []

schools.map(item => {
  item.cities.map(city => {
    schoolsArr = schoolsArr.concat(city.universities)
    return city
  })
  return item
})

export default schoolsArr
