import store from '@/store'
import { getLang } from '@/utils/lang'

/**
 * GIO埋点事件
 * @param {*} eventType 埋点标识符
 * @param {*} params 参数
 * @param {any | undefined} sourceRoute 来源路由信息
 * @param {any | undefined} isNeedPreOfferingTags 是否需要计算前项入口的topics和Verticals / Markets
 */
export const commonGIOEvent = (eventType, params, sourceRoute, isNeedPreOfferingTags) => {
  let commonParams = { ...params }
  if (sourceRoute) {
    // 此时入口页面信息：entryNameID_var、offeringID_var、prePageType_var为公共字段
    commonParams = { ...analysisEntryPageData(sourceRoute, isNeedPreOfferingTags), ...params }
  }
  // 所有空值改为“-”
  const keys = Object.keys(commonParams)
  keys.length && keys.forEach(item => {
    (commonParams[item] === '' || !commonParams[item]) && (commonParams[item] = '-')
  })
  if (commonParams?.offeringID_var) {
    commonParams.offeringID_var !== '-' && (commonParams.offeringID_var = 'offering_' + commonParams.offeringID_var)
  }
  console.log('GIO eventType&params:', eventType, { ...commonParams, languageVersion_var: getLang() === 'zh' ? '中文简体' : 'English' })
  eventType && window.gdp('track', eventType, { ...commonParams, languageVersion_var: getLang() === 'zh' ? '中文简体' : 'English' })
}

/**
 * 频道页面浏览，在路由beforeEach内拦截，统一处理
 * @param {*} routeTo 路由信息
 */
export const GIOChannelPageviewEvent = (routeTo) => {
  const params = {}
  const { id } = matchPageTypeData(routeTo.path, routeTo.href)
  if (routeTo.meta?.needGio) {
    params.pageNameID_var = id
  }
  Object.keys(params).length && commonGIOEvent('channelPageview', params)
  const params2 = {
    pageNameID_var: id,
    pageURL: window.location.href
  }
  commonGIOEvent('webPageview', params2)
}

/**
 * 解析前向入口信息
 * @param {*} route 路由信息
 * @param {*} isNeedPreOfferingTags 是否需要计算前项入口的topics和Verticals / Markets
 * @returns {entryNameID_var: '', offeringID_var: '', prePageType_var: ''}
 */
export const analysisEntryPageData = (route, isNeedPreOfferingTags) => {
  const entryPageData = { entryNameID_var: '', offeringID_var: '' }
  if (isNeedPreOfferingTags) {
    entryPageData.prePageTopic_var = ''
    entryPageData.prePageMarket_var = ''
  }
  if (route?.path?.indexOf('solution-details') > -1) { // offering路由处理，获取offering中英文名称
    if (route?.fullPath?.indexOf('whitePaper=true') > 0) { // 白皮书
      const { id } = localStorage.getItem('currentWhitePaper') ? JSON.parse(localStorage.getItem('currentWhitePaper')) : {}
      entryPageData.entryNameID_var = 'white_paper_' + id
      entryPageData.offeringID_var = ''
    } else if (localStorage.getItem('currentOffering')) { // offering
      const { tags, id } = localStorage.getItem('currentOffering') ? JSON.parse(localStorage.getItem('currentOffering')) : {}
      entryPageData.entryNameID_var = 'offering_' + id
      entryPageData.offeringID_var = id || ''
      if (isNeedPreOfferingTags) {
        if (tags) {
          entryPageData.prePageTopic_var = tags?.Topics?.map(item => item.label).join(',') || ''
          entryPageData.prePageMarket_var = tags['Verticals / Markets']?.map(item => item.label).join(',') || ''
        }
      }
    }
  } else { // 其他路由
    let entryPath = route.path
    let entryFullPath = route.fullPath
    if (!route.name) { // 当前页面刷新
      entryPath = window.location.pathname
      entryFullPath = window.location.href
    }
    // path对应的中英文
    const pageTypaData = matchPageTypeData(entryPath, entryFullPath)
    entryPageData.entryNameID_var = pageTypaData?.id
    entryPageData.offeringID_var = ''
  }
  return entryPageData
}

// 从本地存储内获取offering信息
export const getCurretOfferingInfoFromStore = () => {
  const offeringInfo = {
    id: '',
    name: '',
    nameEn: '',
    topic: '',
    market: ''
  }
  if (localStorage.getItem('currentOffering')) {
    const { name, nameEn, tags, id } = JSON.parse(localStorage.getItem('currentOffering'))
    offeringInfo.id = id || ''
    offeringInfo.name = name || ''
    offeringInfo.nameEn = nameEn || ''
    offeringInfo.topic = tags?.Topics?.map(item => item.label).join(',') || ''
    offeringInfo.market = tags['Verticals / Markets']?.map(item => item.label).join(',') || ''
  }
  return offeringInfo
}
/**
 * 匹配数据库内的pageType信息
 * @param {*} urlPath url eg: /home
 * @param fullUrl
 * @returns path对应的中英文{ pageType: '', pageTypeEn: '' }
 */
export const matchPageTypeData = (urlPath, fullUrl) => {
  const mixtureUrl = ['/partners'] // 防止小程序url与web url名称重复导致识别错误, 需要绝对匹配
  const storeRouteNameData = store.state.routeNameData.filter(item => mixtureUrl.includes(urlPath) ? item.urlPath === urlPath : item.urlPath.indexOf(urlPath) > -1)
  const pageInfo = { pageType: '', pageTypeEn: '', id: '' }
  if (storeRouteNameData.length) {
    let newId = 'page_type_' + storeRouteNameData[0].id
    if (urlPath.indexOf('solution-details') > -1) {
      if (fullUrl) {
        if (fullUrl.indexOf('whitePaper=true') < 0) { // offering
          newId = 'offering_' + fullUrl.split('id=')[1].split('&')[0]
        } else { // 白皮书
          newId = 'white_paper_' + fullUrl.split('id=')[1].split('&')[0]
        }
      }
    } else if(urlPath.indexOf('event-detail') > -1) { // 活动详情记录活动ID
      if(fullUrl) {
        if(fullUrl.indexOf('eventId=' > 0)) {
          newId = 'event_' + fullUrl.split('eventId=')[1].split('&')[0]
        }
      }
    }
    pageInfo.id = newId
  }
  return pageInfo
}
// 联系我们埋点
export const GIOClickContactUsEvent = (urlPath, fullUrl) => {
  const { id } = matchPageTypeData(urlPath, fullUrl)
  const gioParams = {
    pageNameID_var: id,
    pageTopic_var: '',
    pageMarket_var: '',
    offeringID_var: ''
  }
  if (urlPath === '/solution-details' && fullUrl.indexOf('whitePaper=true') < 0) {
    const { id, topic, market } = getCurretOfferingInfoFromStore()
    gioParams.pageTopic_var = topic
    gioParams.pageMarket_var = market
    gioParams.offeringID_var = id
  }
  commonGIOEvent('clickContactUs', gioParams)
}
/**
 * 收藏埋点
 * @param {*} type 收藏类型
 * @param {*} data 当前数据
 */
export const GIOClickFavoritedEvent = (type, data) => {
  const collectType = {
    OFFERING: 'offering',
    ARTICLE: 'success story',
    API: 'api',
    RESOURCE: 'course',
    PARTNER: 'partner'
  }
  if (collectType[type]) {
    const { id } = matchPageTypeData(window.location.pathname, window.location.href)
    const GIOParams = {
      pageNameID_var: id,
      // pageType_var: pageTypeEn,
      // resourceType_var: collectType[type] || '',
      favoritedStatus_var: data.isFav === '1' ? 'collect' : 'uncollect',
      resourceNameID_var: prefixId(collectType[type], data.id)
    }
    commonGIOEvent('clickFavorited', GIOParams)
  }
}
/**
 * 点击offering埋点事件
 * @param {*} data offering数据
 */
export const GIOClickServiceEvent = (data) => {
  const { id } = matchPageTypeData(window.location.pathname, window.location.href)
  const GIOParams = {
    pageNameID_var: id,
    offeringID_var: data.id || ''
  }
  commonGIOEvent('clickService', GIOParams)
}
// 资源位点击事件
export const GIOPositonButtonClickEvent = ({ areaType, positonNameID, buttonName, pageTopic, pageMarket, isCustomButtonName, isCustomPositonNameID }) => {
  const { id } = matchPageTypeData(window.location.pathname, window.location.href)
  const GIOParams = {
    pageNameID_var: id,
    // pageType_var: pageTypeEn,
    areaType_var: areaType,
    resourceNameID_var: isCustomPositonNameID ? positonNameID : prefixId(areaType, positonNameID),
    buttonName_var: isCustomButtonName ? buttonName : (resourceNameIDPrefix[areaType] ? resourceNameIDPrefix[areaType] : buttonName),
    pageTopic_var: pageTopic,
    pageMarket_var: pageMarket
  }
  commonGIOEvent('positonButtonClick', GIOParams)
}
// offeringPositonButtonClick
export const GIOOfferingPositonButtonClickEvent = ({ areaType, positonNameID, buttonName, offeringID }) => {
  const GIOParams = {
    areaType_var: areaType,
    offeringID_var: offeringID,
    resourceNameID_var: prefixId(areaType, positonNameID),
    buttonName_var: resourceNameIDPrefix[areaType] ? resourceNameIDPrefix[areaType] : buttonName
  }
  commonGIOEvent('offeringPositonButtonClick', GIOParams)
}
/**
 * 登录事件埋点
 * @param {*} loginType
 * 1.qrScan
  2.loginButton
  3.bannerClick
  4.offeringDetail
  5.offeringDemo
  6.offeringShuffle
  7.whitepaper
  8.favorite
  9.eventFollow
  10.eventRegister
  11.hotEventFollow
  12.eventRewatch
  13.marketingWebsite
 */
export const GIOLoginRedirectEvent = (loginType) => {
  const { id } = matchPageTypeData(window.location.pathname, window.location.href)
  const GIOParams = {
    entryNameID_var: id,
    // prePageType_var: pageTypeEn,
    loginSourceType_var: loginType,
    offeringID_var: '',
    prePageTopic_var: '',
    prePageMarket_var: '',
    isQR: loginType === 'qrScan' ? '是' : '否',
    QRcode: loginType === 'qrScan' ? window.location.href : ''
  }
  if (window.location.pathname === '/solution-details' && window.location.href.indexOf('whitePaper=true') < 0) {
    const { id, topic, market } = getCurretOfferingInfoFromStore()
    GIOParams.prePageTopic_var = topic
    GIOParams.prePageMarket_var = market
    GIOParams.offeringID_var = id
  }
  commonGIOEvent('loginRedirect', GIOParams)
}
// 获取扫码信息
export const getQRInfo = (route) => {
  return {
    isQR: window.location.href.indexOf('qrParam=') > 0 ? '是' : '否',
    QRcode: route.query?.qrLink ? window.atob(route.query.qrLink) : ''
  }
}
// 埋点各个模块ID
const resourceNameIDPrefix = {
  offering: 'offering',
  whitepaper: 'white_paper',
  'success story': 'article',
  api: 'api',
  course: 'course',
  partner: 'partner',
  banner: 'banner'
}
/**
 * 补充id前缀
 * @param {*} type 模块类型 值为resourceNameIDPrefix的key
 * @param {*} id id值
 * @returns id
 */
const prefixId = (type, id) => {
  let newId = ''
  if (id && type) {
    id !== '' && (newId = (resourceNameIDPrefix[type] ? resourceNameIDPrefix[type] + '_' : '') + id)
  }
  return newId
}

/**
 * 日志埋点 - log event
 */
export const logEvent = (eventName, params) => {
  try {
    window.gdp && window.gdp('track', eventName, { ...commonParams(), ...params })
  } catch (e) {
    console.log('gio - logEvent error: ', e)
  }
}

/**
 * 默认公共参数
 */
const commonParams = () => {
  return {
    languageVersion_var: getLang() === 'zh' ? '中文简体' : 'English'
  }
}

/**
 * 设置全局通用属性
 * @param params
 */
export const setGeneralProps = (params) => {
  try {
    window.gdp && window.gdp('setGeneralProps', params)
  } catch (e) {
    console.log('gio - setGeneralProps error: ', e)
  }
}

/**
 * 清除全局通用属性
 * @param propsName string[] 指定要移除的字段
 */
export const clearGeneralProps = (propsName = []) => {
  try {
    window.gdp && window.gdp('clearGeneralProps', propsName)
  } catch (e) {
    console.log('gio - setGeneralProps error: ', e)
  }
}

/**
 * 设置页面属性
 * @param params
 */
export const setPageAttributes = (params) => {
  try {
    window.gdp && window.gdp('setPageAttributes', params)
  } catch (e) {
    console.log('gio - setPageAttributes error: ', e)
  }
}

/**
 * 清除页面属性
 * @param propsName string[] 指定要移除的字段
 */
export const clearPageAttributes = (propsName = []) => {
  try {
    window.gdp && window.gdp('clearPageAttributes', propsName)
  } catch (e) {
    console.log('gio - clearPageAttributes error: ', e)
  }
}

/**
 * 判断新增Traffic所属层级
 * @param trafficName
 * @param gio
 */
export const addTraffic = (trafficName, gio) => {
  const trafficLevels = ['first', 'second', 'third', 'fourth', 'fifth']
  for (let i = 0; i < trafficLevels.length; i++) {
    const trafficKey = trafficLevels[i] + 'TrafficName_var'
    if (!gio[trafficKey]) {
      return {
        ...gio,
        [trafficKey]: trafficName
      }
    }
  }
}