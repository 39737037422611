<template>
  <div class="msgWrap">
    <IconFont :name="icon" size="16px" style="margin-right: 8px" />
    <div class="msg">{{ msg }}</div>
    <div class="closeBtn" v-if="showClose" @click="handleClose">
      <IconFont name="icon-Close-big" size="16px" style="margin-left: 8px; color: #9999a9" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IconFont } from '@/modules/uiKit'

type Event = { (e: 'close'): void }

const emits = defineEmits<Event>()

const handleClose = () => {
  emits('close')
}

withDefaults(
  defineProps<{
    msg?: string
    icon?: string
    showClose?: boolean
  }>(),
  {
    msg: '',
    icon: 'icon-Error',
    showClose: true,
  }
)
</script>

<style lang="scss" scoped>
.msgWrap {
  display: flex;
  align-items: center;
  background: #331131;
  color: #ff7687;
  border-radius: 8px;
}
.msg {
  flex: 1;
}
.closeBtn {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
