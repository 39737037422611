import schoolsArr from "./universe";
import { getPageTypeData } from "@/api/productData";
import { getPositionData } from "@/api/productData";
import store from "@/store/index";
import { updateMessages } from "@/main";
const getPositionList = async () => {
  // 获取后端option
  let arr = [];
  const res = await getPositionData();
  arr = res.data || [];
  let position_listZh = {};
  let position_listEn = {};
  // console.log('======this.$i18n',i18n);
  arr.forEach((el) => {
    const key = el.code;
    position_listZh[key] = el.value;
    position_listEn[key] = el.value_en;
  });
  const objZh = {
    position_list: position_listZh,
  };
  const objEn = {
    position_list: position_listEn,
  };
  const newEnMessages = position_listEn;
  const newZhMessages = position_listZh;
  // updateMessages(newMessages,'positionList')
  updateMessages(newZhMessages, newEnMessages, "positionList");
  // 储存option'
  store.commit("setPositionZhList", objZh);
  store.commit("setPositionEnList", objEn);
};
getPositionList();
export default {
  position_list: {
    110000: "企业管理层",
    120000: "业务经理",
    130000: "IT经理",
    140000: "IT人员",
    150000: "企业架构师",
    160000: "生产管理人员",
    170000: "研发经理",
    180000: "研发人员",
    190000: "工程师（调试，运维等）",
    200000: "销售相关人员",
    210000: "质量相关人员",
    220000: "业务分析与商业咨询",
    230000: "教职工",
    240000: "学生",
    250000: "开发者",
    260000: "西门子内部员工",
    280000: "工程师（设计院）",
    270000: "其他",
  },
  grade_list: {
    110000: "专科",
    120000: "本科",
    130000: "硕士研究生",
    140000: "博士研究生",
  },
  school_list: schoolsArr,
};
