const OSS_BASE_URL = process.env.VUE_APP_OSS_BASE_URL

export var isSiemensx = /^(www.)?siemens-x/g.test(window.location.hostname.toLowerCase())
// export var isSiemensx = true
export const resource = {
  cn_zh: {
    links: [
      {
        title: '探索',
        links: [
          {
            text: '效率',
            url: '/topics/Efficiency',
          },
          {
            text: '医疗',
            url: '/vertical-markets/Healthcare',
          },
          {
            text: '生态伙伴',
            url: '/partners',
          },
          {
            text: '开发者',
            url: '/developers',
          },
        ],
      },
      // {
      //   title: '社区',
      //   links: [
      //     {
      //       text: '讨论',
      //       url: '/community'
      //     },
      //     {
      //       text: '常见问题',
      //       url: '/faq'
      //     },
      //     {
      //       text: '参与规则',
      //       url: '/rules-of-participation'
      //     }
      //   ]
      // },
      {
        title: '西门子',
        target: '_blank',
        links: [
          {
            text: '企业信息',
            url: 'https://new.siemens.com/cn/zh/general/legal.html',
          },
          // {
          //   text: '使用条款',
          //   url: OSS_BASE_URL + '/doc/Terms%20of%20Use(cn)%2020221103.pdf'
          // },
          // {
          //   text: '法律条款',
          //   type: 'law',
          //   url: '/laws-regulations',
          // },
          {
            text: '数字身份证',
            url: 'https://www.siemens.com/digital-id',
          },
          {
            text: '合规举报',
            url: 'https://new.siemens.com/global/en/company/sustainability/compliance/reporting-channels.html',
          },
        ],
      },
    ],
    copyrightTitle: {
      // text: 'Copyright 1996-2023 保留公司所有权利'
      // text:isSiemensx ? 'Copyright 2024 保留西门子（中国）有限公司所有权利' : 'Copyright 2024 保留西门子数字科技(深圳)有限公司所有权利',
      text: 'Copyright 2024 保留西门子（中国）有限公司所有权利',
      showPopText: '如您对Xcelerator有任何意见或建议，请邮件联系',
    },
    copyright: [
      {
        text: '',
        url: '',
        showPop: true,
        showPhoto: true,
      },
      {
        text: '京ICP备06054295号',
        url: 'https://beian.miit.gov.cn/',
      },
      {
        text: '京公网安备 11010502040638号',
        url: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502040638号',
      },
      {
        text: '',
        url: '',
      },
      {
        text: '',
        url: '',
      },
      {
        text: '隐私政策',
        url: OSS_BASE_URL + '/doc/Data%20Privacy%20Notice(cn).pdf',
      },
      {
        text: 'Cookie公告',
        url: 'https://new.siemens.com/cn/zh/general/cookie-notice.html',
      },
      {
        text: '使用条款',
        url: OSS_BASE_URL + '/doc/Terms%20of%20Use(cn)%2020221103.pdf',
      },
      {
        text: '举报和投诉',
        url: '',
        showPop: true,
      },
    ],
    text: [
      '公司信息',
      '地址',
      '深圳市前海深港合作区南山街道前海大道',
      '前海嘉里商务中心T1写字楼704',
      '邮政编码：518052',
      '电话：0755-26935188',
      '西门子中国',
      '公众号',
      'Copyright 1996-2023 保留公司所有权利',
    ],
    wechatCode1: '西门子Xcelerator',
    wechatCode2: '公众号',
  },
  en_us: {
    links: [
      {
        title: 'Explore',
        links: [
          {
            text: 'Efficiency',
            url: '/topics/Efficiency',
          },
          {
            text: 'Healthcare',
            url: '/vertical-markets/Healthcare',
          },
          {
            text: 'Eco-Partner',
            url: '/partners',
          },
          {
            text: 'Developers',
            url: '/developers',
          },
        ],
      },
      // {
      //   title: 'Community',
      //   links: [
      //     {
      //       text: 'Community',
      //       url: '/community'
      //     },
      //     {
      //       text: 'FAQ',
      //       url: '/faq'
      //     },
      //     {
      //       text: 'Rules of Participation',
      //       url: '/rules-of-participation'
      //     }
      //   ]
      // },
      {
        title: 'Siemens',
        target: '_blank',
        links: [
          {
            text: 'Corporate Information',
            url: 'https://www.siemens.com/corporate-information',
          },
          // {
          //   text: 'Terms of Use',
          //   url: OSS_BASE_URL + '/doc/Terms%20of%20Use%2020221103.pdf'
          //   // url: 'https://new.siemens.com/cn/zh/general/terms-of-use.html'
          // },
          // {
          //   text: 'Legal provisions',
          //   type: 'law',
          //   url: '/laws-regulations',
          // },
          {
            text: 'Digital ID',
            url: 'https://www.siemens.com/digital-id',
          },
          {
            text: 'Compliance operation',
            url: 'https://new.siemens.com/global/en/company/sustainability/compliance/reporting-channels.html',
          },
        ],
      },
    ],
    copyrightTitle: {
      // text: 'Copyright 1996-2023 All rights reserved'
      text: 'Copyright 2024 保留西门子（中国）有限公司所有权利',
      showPopText: 'If you have any complaints or suggestions, please send email to',
    },
    copyright: [
      {
        text: '',
        url: '',
        showPop: true,
        showPhoto: true,
      },
      {
        // text: '粤ICP备2023083949号',
        text: '京ICP备06054295号',
        url: 'https://beian.miit.gov.cn/',
      },
      {
        text: '京公网安备 11010502040638号',
        url: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502040638号',
      },
      {
        text: '',
        url: '',
      },
      {
        text: '',
        url: '',
      },
      {
        text: 'Privacy Notice',
        url: OSS_BASE_URL + '/doc/Data%20Privacy%20Notice(en).pdf',
      },
      {
        text: 'Cookie Notice',
        url: 'https://www.siemens.com/cookie-notice',
      },
      {
        text: 'Terms of Use',
        url: OSS_BASE_URL + '/doc/Terms%20of%20Use%2020221103.pdf',
        // url: 'https://new.siemens.com/cn/zh/general/terms-of-use.html'
      },
      {
        text: 'Complaints',
        url: '',
        showPop: true,
      },
    ],
    text: [
      'Contact',
      'Address',
      '704, Tower 1, Qianhai Kerry Business Center, Qianhai Avenue, ',
      'Nanshan Street, Qianhai Shenzhen-Hong Kong Cooperation Zone, Shenzhen City',
      'Tel: 0755-26935188',
      '',
      // 'Siemens China',
      // 'WeChat Account', // 存疑
      // 'Siemens AG',
      // '公众号', // 存疑
      // '小程序', // 存疑
      // 'Copyright 1996-2023 All rights reserved' // 存疑
    ],
    wechatCode1: 'Siemens Xcelerator',
    wechatCode2: 'WeChat Account',
  },
}
export const resource_domin_siemensx = {
  cn_zh: {
    links: [
      {
        title: '探索',
        links: [
          {
            text: '效率',
            url: '/topics/Efficiency',
          },
          {
            text: '医疗',
            url: '/vertical-markets/Healthcare',
          },
          {
            text: '生态伙伴',
            url: '/partners',
          },
          {
            text: '开发者',
            url: '/developers',
          },
        ],
      },
      // {
      //   title: '社区',
      //   links: [
      //     {
      //       text: '讨论',
      //       url: '/community'
      //     },
      //     {
      //       text: '常见问题',
      //       url: '/faq'
      //     },
      //     {
      //       text: '参与规则',
      //       url: '/rules-of-participation'
      //     }
      //   ]
      // },
      {
        title: '西门子',
        target: '_blank',
        links: [
          {
            text: '企业信息',
            url: 'https://new.siemens.com/cn/zh/general/legal.html',
          },
          // {
          //   text: '使用条款',
          //   url: OSS_BASE_URL + '/doc/Terms%20of%20Use(cn)%2020221103.pdf'
          // },
          {
            text: '法律条款',
            type: 'law',
            url: '/laws-regulations',
          },
          {
            text: '数字身份证',
            url: 'https://www.siemens.com/digital-id',
          },
          {
            text: '合规举报',
            url: 'https://new.siemens.com/global/en/company/sustainability/compliance/reporting-channels.html',
          },
        ],
      },
    ],
    copyrightTitle: {
      // text: 'Copyright 1996-2023 保留公司所有权利'
      text: 'Copyright 2024 保留西门子数字科技(深圳)有限公司所有权利',
      showPopText: '如您对Xcelerator有任何意见或建议，请邮件联系',
    },
    copyright: [
      {
        text: '营业执照信息',
        url: '',
        showPop: true,
        showPhoto: true,
      },
      {
        text: '粤ICP备2023083949号',
        url: 'https://beian.miit.gov.cn/',
      },
      {
        text: '粤公网安备 44030502010326号',
        url: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502010326',
      },
      {
        text: '增值电信业务经营许可证：合字B2-20230362',
        url: '',
      },
      {
        text: '隐私政策',
        url: OSS_BASE_URL + '/doc/Data%20Privacy%20Notice(cn).pdf',
      },
      {
        text: 'Cookie公告',
        url: 'https://new.siemens.com/cn/zh/general/cookie-notice.html',
      },
      {
        text: '使用条款',
        url: OSS_BASE_URL + '/doc/Terms%20of%20Use(cn)%2020221103.pdf',
      },
      {
        text: '举报和投诉',
        url: '',
        showPop: true,
      },
    ],
    text: [
      '公司信息',
      '地址',
      '深圳市前海深港合作区南山街道前海大道',
      '前海嘉里商务中心T1写字楼704',
      '邮政编码：518052',
      '电话：0755-26935188',
      '西门子中国',
      '公众号',
      'Copyright 1996-2023 保留公司所有权利',
    ],
    wechatCode1: '西门子Xcelerator',
    wechatCode2: '公众号',
  },
  en_us: {
    links: [
      {
        title: 'Explore',
        links: [
          {
            text: 'Efficiency',
            url: '/topics/Efficiency',
          },
          {
            text: 'Healthcare',
            url: '/vertical-markets/Healthcare',
          },
          {
            text: 'Eco-Partner',
            url: '/partners',
          },
          {
            text: 'Developers',
            url: '/developers',
          },
        ],
      },
      // {
      //   title: 'Community',
      //   links: [
      //     {
      //       text: 'Community',
      //       url: '/community'
      //     },
      //     {
      //       text: 'FAQ',
      //       url: '/faq'
      //     },
      //     {
      //       text: 'Rules of Participation',
      //       url: '/rules-of-participation'
      //     }
      //   ]
      // },
      {
        title: 'Siemens',
        target: '_blank',
        links: [
          {
            text: 'Corporate Information',
            url: 'https://www.siemens.com/corporate-information',
          },
          // {
          //   text: 'Terms of Use',
          //   url: OSS_BASE_URL + '/doc/Terms%20of%20Use%2020221103.pdf'
          //   // url: 'https://new.siemens.com/cn/zh/general/terms-of-use.html'
          // },
          {
            text: 'Legal provisions',
            type: 'law',
            url: '/laws-regulations',
          },
          {
            text: 'Digital ID',
            url: 'https://www.siemens.com/digital-id',
          },
          {
            text: 'Compliance operation',
            url: 'https://new.siemens.com/global/en/company/sustainability/compliance/reporting-channels.html',
          },
        ],
      },
    ],
    copyrightTitle: {
      // text: 'Copyright 1996-2023 All rights reserved'
      text: 'Copyright 2024 保留西门子数字科技(深圳)有限公司所有权利',
      showPopText: 'If you have any complaints or suggestions, please send email to',
    },
    copyright: [
      {
        text: 'Business Licence', // 存疑
        url: '', // https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502040638
        showPop: true,
        showPhoto: true,
      },
      {
        text: '粤ICP备2023083949号',
        url: 'https://beian.miit.gov.cn/',
      },
      {
        text: '粤公网安备 44030502010326号',
        url: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502010326',
      },
      {
        text: '增值电信业务经营许可证：合字B2-20230362',
        url: '',
      },
      {
        text: 'Privacy Notice',
        url: OSS_BASE_URL + '/doc/Data%20Privacy%20Notice(en).pdf',
      },
      {
        text: 'Cookie Notice',
        url: 'https://www.siemens.com/cookie-notice',
      },
      {
        text: 'Terms of Use',
        url: OSS_BASE_URL + '/doc/Terms%20of%20Use%2020221103.pdf',
        // url: 'https://new.siemens.com/cn/zh/general/terms-of-use.html'
      },
      {
        text: 'Complaints',
        url: '',
        showPop: true,
      },
    ],
    text: [
      'Contact',
      'Address',
      '704, Tower 1, Qianhai Kerry Business Center, Qianhai Avenue, ',
      'Nanshan Street, Qianhai Shenzhen-Hong Kong Cooperation Zone, Shenzhen City',
      'Tel: 0755-26935188',
      '',
      // 'Siemens China',
      // 'WeChat Account', // 存疑
      // 'Siemens AG',
      // '公众号', // 存疑
      // '小程序', // 存疑
      // 'Copyright 1996-2023 All rights reserved' // 存疑
    ],
    wechatCode1: 'Siemens Xcelerator',
    wechatCode2: 'WeChat Account',
  },
}

export default isSiemensx ? resource_domin_siemensx : resource
