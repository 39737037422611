
import { createVNode, render } from 'vue'
import commonMessage from './message.vue'

const div = document.createElement('div')
div.setAttribute('class', 'common-message-wrapper')
document.body.appendChild(div)

let time = null
export default ({ msg, type, duration, top, fontSize }) => {
  const vnode = createVNode(commonMessage, { msg, type, duration, top, fontSize })
  render(vnode, div)

  // 设置定时器
  clearTimeout(time)
  time = setTimeout(() => {
    render(null, div)
  }, duration || 3000)
}
