import { defineComponent, onMounted, ref } from 'vue'
import { JSX } from 'vue/jsx-runtime'
import { Loading } from '../loading'

export interface FutureBuilderSnap {
  status: 'done' | 'error' | 'loading'
  error?: any
}
/**
 * 异步渲染组件
 */
export const FutureBuilder = defineComponent<{
  future: Promise<any>
  builder: (data: any, snap: FutureBuilderSnap) => JSX.Element
}>(
  (props) => {
    const loading = ref<boolean>(true)
    const data = ref<any>()
    const snap = ref<FutureBuilderSnap>({
      status: 'loading',
    })
    onMounted(() => {
      props.future
        .then((res) => {
          data.value = res
          loading.value = false
          snap.value.status = 'done'
        })
        .catch((e) => {
          loading.value = false
          snap.value.status = 'error'
          snap.value.error = e
        })
    })
    return () => {
      if (loading.value) {
        return <Loading />
      }
      return props.builder(data.value, snap.value)
    }
  },
  {
    props: ['future', 'builder'],
  }
)
